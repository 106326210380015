export default {
  auth0:{
    // isLoggedIn : false,
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true'?true:false,

    accessToken :'',
    api_url:'',
    portal_url:'',
    tenant_code:''
  },
  settings:{
    group_id:'',
    permissions:[],
    userList:[]
  },
  autoLoginLoader: false
}