<template>
  <v-dialog persistent v-model="isExportPopup" scrollable max-width="300px">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ exportPopup.title }}</v-toolbar-title>
      </v-toolbar>
      <v-container id="scroll-target" style="max-height: 800px" class="scroll-y">
        <div class="headcontainer">
          <div>
            <div class="main_header">
              <div>
              <v-checkbox color="primary" hide-details v-model="selectall" @change="toggleAll"></v-checkbox>
              </div>
              <div class="label_name">{{"Select All"}}</div>
            </div>
            <div v-for="(field, i) in items" :key="i">
          
              <div class="main_header">
                <div>
                  <v-checkbox color="primary" hide-details v-model="field.item"></v-checkbox>
                </div>
                <div class="label_name">{{ field.lable }}</div>
              </div>
          </div>
        </div>




        </div>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="light" flat @click="ClosePopup">Cancel</v-btn>
        <v-btn :disabled="!items.some(x => x.item === true)" color="primary" @click="SaveExport">Export</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "Export-fields-list",
  props: {
    exportPopup: {
      type: Object
    },
    items: {
      type: Array
    }
  },
  data() {
    return {
      // exportfield: [],
      selectall: false,
      isExportPopup: false,
      type: "",


      notifications: false,
        sound: false,
        video: false,
        invites: false
    }
  },
  methods: {
    OpenPopup(data) {
      let scope = this
      this.isExportPopup = true
      scope.type = data
      scope.isExportPopup = true
      console.log(data,this.items,"vveeeeeeeeeelllllllllllll",scope.isExportPopup);
    },
    ClosePopup() {
      let scope = this
      scope.isExportPopup = false
      this.toggleAll('reset');
    },
    SaveExport() {
      let scope = this
      scope.isExportPopup = false
      console.log("SaveExport")
      const exportfield = []
      console.log(this.items,"HHHHHHHHHHJJJJJJJJJJJJ");
      scope.items.forEach((elm) => {
        if (elm.item == true) {
          exportfield.push(elm.model)
          console.log(exportfield,"WWWWWWWWWWWWWWWWW");
        }
      })
      console.log(exportfield,"KKKKKKKKKKKKKKKKKKKKK");


      console.log("SaveExport")
      scope.$emit("export", { field: exportfield, type: scope.type });
      this.toggleAll('reset')
    },

    toggleAll(action) {
      let scope = this
      if(action === 'reset'){
        scope.selectall = false;
      }
      scope.items.forEach((elm) => {
        elm.item = scope.selectall
      })
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    }
  },
  computed: {


  },
  watch: {
    items(){
      console.log(this.items,"asdf")
    }
  },
};
</script>
<style>
.headcontainer {
  display: flex;
  flex-direction: row;
}

.main_header {
  display: flex;
  flex-direction: row;
 
}
.label_name{
  font-size: 16px;
  padding-top: 20px;
}
</style>