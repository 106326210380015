<template>
  <v-app :dark="colorScheme.dark" :class="colorScheme.scheme">
    <!-- <loader v-if="isAutoLoginLoading" /> -->
    <div v-if="isAutoLoginLoading" class="loader__">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        
    </div>
    <router-view @generateJSON="generateJSON" name="header" />
    <router-view @changeFlow="changeFlow" name="sidebar" />
    <v-content style="display: flex; width: 100%; height: 100vh;">
      <router-view :flows="flows" :flow="flow" :showJSON="showJSON" />
    </v-content>
    <app-settings
      :drawer="subDrawer"
      @toggleSubDrawer="handleSubdrawer"
    ></app-settings>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import AppSettings from "@/components/AppSettings";
// import loader from "@/components/Widgets/loader";
export default {
  data() {
    return {
      flows: [{ name: "Untitled 1", edit: false, id: 0 }],
      flow: "Untitled1",
      subDrawer: false,
      showJSON: false
    };
  },
  computed: {
    ...mapGetters({
      colorScheme: "colorScheme",
      isAutoLoginLoading: "isAutoLoginLoading"
    })
  },

  components: {
    AppSettings,
    // loader
  },
  methods: {
    changeFlow([flows, flow]) {
      this.flows = flows;
      this.flow = flow;
    },
    handleSubdrawer(value) {
      this.subDrawer = value;
    },
    generateJSON() {
      this.showJSON = !this.showJSON;
    }
  },

  mounted() {
    console.log('Is auto login loading:', this.isAutoLoginLoading);
  }
};
</script>
<style>

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
.loader__ {
    position: fixed;
    left: 45%;
    bottom: 60%;
    right: 40%;
    height: 80px;
    top: 45%;
}

.loader__ div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #2196f3;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader__ div:nth-child(1) {
    left: 8px;
    animation: loader__1 0.6s infinite;
}

.loader__ div:nth-child(2) {
    left: 8px;
    animation: loader__2 0.6s infinite;
}

.loader__ div:nth-child(3) {
    left: 32px;
    animation: loader__2 0.6s infinite;
}

.loader__ div:nth-child(4) {
    left: 56px;
    animation: loader__3 0.6s infinite;
}

@keyframes loader__1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes loader__3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes loader__2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
