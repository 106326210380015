<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs" :exportOptions="page.exportOptions" @openFilter="openMissedCallFilter"
      @refreshPage="refreshQueueSummaryPage" :isMaterialIconType="false" @exportData="exportMissedCallReport"
      :style="styleObject"></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table v-if="queueSummary.length > 0" class="elevation-1 " :items="queueSummary"
        
        :pagination.sync="pagination" :total-items="Summarytotal" :rows-per-page-items="[5, 10, 25]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:headers="props">
          <tr style="height: 12px">

            <th rowspan="2" @click="sorting()">Date <i id="sss" class='' style='font-size:12px'></i>
            </th>
            <th rowspan="2">Queue</th>
            <th rowspan="2">Total</th>
            <th rowspan="2">Answered</th>
            <th rowspan="2">Abandoned</th>
            <th rowspan="2">Talk Time</th>
            <th class="br-1" rowspan="2">Wait Time</th>
            <th colspan="2" style="text-align: center">Hangup</th>
          </tr>
          <tr style="height: 12px">
            <th class="bb-1">Caller</th>
            <th class="bb-1">Agent</th>
          </tr>
        </template>

        <!-- 
        <template v-slot:headers="props">{{header2[0].text}}
          <tr style="height:30px">
            <th> {{props.item.header2[0].text}}</th>
          </tr>
        </template> -->


        <template v-slot:items="props">
          <td class="checkEmpty">{{ props.item.calldate }}</td>
          <td class="checkEmpty">{{ props.item.queue }}</td>
          <td class="checkEmpty">{{ props.item.answered + props.item.abandoned }}</td>
          <td class="checkEmpty">{{ props.item.answered }}</td>
          <td class="checkEmpty">{{props.item.abandoned}}</td>
          <td class="checkEmpty">{{ props.item.talk_time}}</td>
          <td class="checkEmpty br-1 ">{{props.item.wait_time }}</td>
          <td class="checkEmpty">{{ props.item.caller_hangup}}</td>
          <td class="checkEmpty">{{ props.item.agent_hangup }}</td>
          <!-- <td class="text-xs-center checkEmpty"></td> -->
        </template>
        <!-- <template v-slot:items="props">
          <tr>
            <td>{{ props.item.calldate }}</td>
            <td>{{ props.item.queue }}</td>
            <td>{{ props.item.answered + props.item.abandoned }}</td>
            <td>{{ props.item.answered }}</td>
            <td>{{ props.item.abandoned }}</td>
            <td>{{ props.item.talk_time }}</td>
            <td class="br-1">{{ props.item.wait_time }}</td>
            <td>{{ props.item.caller_hangup }}</td>
            <td>{{ props.item.agent_hangup }}</td>
            <td class="text-xs-center checkEmpty"></td>
          </tr>
        </template> -->
      </v-data-table>
      <v-data-table v-else class="elevation-1 left-header" :items="queueSummary" :pagination.sync="pagination"
        :total-items="Summarytotal" :rows-per-page-items="[5, 10, 25]" :headers="headers">
        <!-- <template v-slot:headers="props">
          <tr style="height: 12px">
           
             <td class="checkEmpty p0">Date</td>
            <th rowspan="2">Date</th>
            <th rowspan="2">Queue</th>
            <th rowspan="2">Total</th>
            <th rowspan="2">Answered</th>
            <th rowspan="2">Abandoned</th>
            <th rowspan="2">Talk Time</th>
            <th rowspan="2">Wait Time</th>
            <th rowspan="2">Hangup</th>
           <th class="br-1" rowspan="2">Wait Time</th>
            <th colspan="2" style="text-align: center">Hangup</th> -->
        <!-- </tr>

        </template>  -->
      </v-data-table>
    </v-container>
    <div>
      <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="1000">
          <pdfexport v-if="dialog" :header="pdf.header" :pdfdata="pdf_data" @closeModel="closeModel"
            title="QueueSummary Report"></pdfexport>
        </v-dialog>
      </v-layout>
    </div>
    <v-navigation-drawer fixed disable-resize-watcher right  v-model="page.isFilterOpen" class="filterNavigation">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="row">
            <v-container class="pa-1">
              <v-menu v-model="page.menu.fromdate" :close-on-content-click="false" full-width max-width="290" small>
                <template v-slot:activator="{ on }">
                  <v-text-field :value="appliedfilter.fromdate" label="From Date" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker color="primary" v-model="appliedfilter.fromdate" @change="page.menu.fromdate = false">
                </v-date-picker>
              </v-menu>
            </v-container>
            <v-container class="pa-1">
              <v-menu v-model="page.menu.todate" :close-on-content-click="false" full-width max-width="290" small>
                <template v-slot:activator="{ on }">
                  <v-text-field :value="appliedfilter.todate" label="To Date" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker color="primary" v-model="appliedfilter.todate" @change="page.menu.todate = false">
                </v-date-picker>
              </v-menu>
            </v-container>
          </div>
          <v-autocomplete v-model="appliedfilter.queue" :items="filterqueue" label="Queue" persistent-hint small
            multiple single-line :allow-overflow="false"></v-autocomplete>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="resetvalue()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFrom()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import moment from "moment";
import { mapGetters } from "vuex";
import pdfexport from "./Components/pdfexport";
export default {
  components: {
    loader,
    pdfexport,
  },
  computed: {
    ...mapGetters(["queueSummary", "filters", "filter"]),
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Queue Summary",
        icon: "fa-users fa-2x",
        loading: true,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        viewing: "1",
        menu: {
          fromdate: false,
          todate: false,
        },
      },
      skip: 0,
      take: 0,
      dialog: false,
      payload: {
        from_date: "",
        to_date: "",
        skip: 0,
        take: 0,
        detail: "calldetails",
        descending: "",
        export: "exportcalldetails",
      },
      filterqueue: [],
      total: "",
      call_date: "",
      report: "",
      exports: [],
      excel_data: [],
      txt_data: [],
      pdf_data: [],
      from_date: "",
      to_date: "",
      pagination: {
        rowsPerPage: 10,
      },
      headers: [

        { text: "Date", align: "left", class: "p0", sortable: true, value: "call_datetime" },
        { text: "Phone", align: "left", class: "p0", sortable: true, value: "phone" },
        { text: "Queue", align: "left", class: "p0", sortable: true, value: "queue" },
        { text: "Last App", align: "left", class: "p0", sortable: true, value: "last_app" },
        { text: "DID", align: "left", class: "p0", sortable: true, value: "did_number" },
        { text: "Duration", align: "left", class: "p0", sortable: true, value: "duration" },
      ],
      appliedfilter: {
        phonenumber: "",
        type: "",
        station: "",
        agent: "",
        did: "",
        queue: "",
        dispo: "",
        status: "",
        fromdate: "",
        todate: "",
        flag: "",
      },
      pdf: {
        header: [
          { text: "Date", align: "center", sortable: true, value: "calldate" },
          { text: "Queue", align: "left", sortable: true, value: "queue" },
          {
            text: "Total",
            align: "left",
            sortable: true,
            value: "no_of_agents",
          },
          {
            text: "Answered",
            align: "left",
            sortable: false,
            value: "answered",
          },
          {
            text: "Abandoned",
            align: "left",
            sortable: true,
            value: "abandoned",
          },
          {
            text: "Talk Time",
            align: "left",
            sortable: true,
            value: "talk_time",
          },
          {
            text: "Wait Time",
            align: "left",
            sortable: false,
            value: "wait_time",
          },
          {
            text: "Caller Hangup",
            align: "left",
            sortable: false,
            value: "caller_hangup",
          },
          {
            text: "Agent Hangup",
            align: "left",
            sortable: false,
            value: "agent_hangup",
          },
        ],
      },
      header2: [
        { text: "Date", align: "left", sortable: true, class: "p0", value: "calldate" },
        { text: "Queue", align: "left", class: "p0", sortable: false, value: "queue" },
        {
          text: "Total",
          align: "left",
          sortable: false, class: "p0",
          value: "no_of_agents",
        },
        {
          text: "Answered",
          align: "left",
          sortable: false, class: "p0",
          value: "answered",
        },
        {
          text: "Abandoned",
          align: "left",
          sortable: false, class: "p0",
          value: "abandoned",
        },
        {
          text: "Talk Time",
          align: "left", class: "p0",
          sortable: false,
          value: "talk_time",
        },
        {
          text: "Wait Time",
          align: "left", class: "p0",
          sortable: false,
          value: "wait_time",
        },
        {
          text: "Caller Hangup",
          align: "left", class: "p0",
          sortable: false,
          value: "caller_hangup",
        },
        {
          text: "Agent Hangup",
          align: "left", class: "p0",
          sortable: false,
          value: "agent_hangup",
        },
      ],
      agentactivityreport: [],
      Summarytotal: 0,
    };
  },
  watch: {
    pagination: {
      handler() {
       
        this.getDataFromApi();
         
        // this.agentactivityreport= data.report;
        // this.agentactivitytotal= data.total;
        // });
      },
      deep: true,
    },
    "appliedfilter.fromdate"(newVal) {
      var from_date = moment(newVal).format("YYYY-MM-DD");
      this.$store.commit("ACTION_FROMDATE", from_date);
      this.$store.commit("FILTERFROMDATE", from_date);
      // this.getDataFrom();
    },
    "appliedfilter.todate"(newVal) {
      var to_date = moment(newVal).format("YYYY-MM-DD");
      this.$store.commit("ACTION_TODATE", to_date);
      this.$store.commit("FILTER_TODATE", to_date);
      // this.getDataFrom();
    },
    "appliedfilter.queue"(newVal) {
      this.$store.commit("FILTER_QUEUE", { queue: newVal });
      // this.getDataFrom();
    },
  },

  mounted() {
    let scope = this;
    this.page.loading = false;
    this.dateformate();
    // this.$store.dispatch("reportqueuelist");
    this.filterqueue = JSON.parse(localStorage.getItem("group_queue"));
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:queueperformance:export"
          ) !== -1
        ) {
          scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
        } else {
          scope.page.exportOptions = [];
        }
      }.bind(scope),
      1
    );
  },

  methods: {
    openMissedCallFilter: function () {
      this.page.isFilterOpen = true;
    },
    closeModel: function () {
      this.dialog = false;
    },
    // sorting() {
    //   this.queueSummary.reverse();

    // },

    refreshQueueSummaryPage: function () {
      var scope = this;
      scope.dateformate();
      scope.refreshvalue();
      scope.page.loading = true;
      scope.appliedfilter.queue = "";
      scope.getDataFromApi();
    },
    exportMissedCallReport: function (option) {
      if (option == "CSV" || option == "Txt(Tab-Delimited)") {
        this.export(option);
      } else {
        this.dialog = true;
        this.export(option);
        this.pdf_flag = true;
      }
    },
    dateformate() {
      this.page.loading = false;
      this.to_date = new Date();
      this.to_date = moment(this.to_date).format("YYYY-MM-DD");
      var from_date1 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date1.setTime(from_date1.getTime() - dateOffset);
      this.from_date = moment(from_date1).format("YYYY-MM-DD");
      this.appliedfilter.fromdate = this.from_date;
      this.appliedfilter.todate = this.to_date;
      this.$store.commit("FILTERFROMDATE", this.from_date);
      this.$store.commit("FILTER_TODATE", this.to_date);
    },
    getDataFrom() {
      this.skip = 0;
      this.getDataFromApi();
      this.page_flag = true;
      this.page.isFilterOpen=false
    },
    getDataFromApi() {
      //reject

      var scope = this;
      this.payload.to_date = this.to_date;
      this.payload.from_date = this.from_date;
      var { page, rowsPerPage } = this.pagination;
      if (rowsPerPage == -1) {
        this.payload.take = this.total;
      } else if (
        rowsPerPage != "" &&
        rowsPerPage != undefined &&
        rowsPerPage != null
      ) {
        this.payload.take = rowsPerPage;
      } else {
        this.payload.take = 5;
      }
      if (page != "" && page != undefined && page != null) {
        this.skip = page;
      } else {
        this.skip = 1;
      }
      this.payload.skip = (this.skip - 1) * this.payload.take;
      return new Promise((resolve) => {
        var { descending } = scope.pagination;
        this.payload.descending = descending;
        scope.$store
          .dispatch("queueSummaryReport", this.payload)
          .then((response) => {
            scope.page.loading = false;
            scope.total = response.count;
            if (scope.page_flag) {
              this.pagination.page = 1;
              scope.page_flag = false;
            }
            scope.Summarytotal = scope.total;
            resolve(scope.total);
            if (response.response.status === 401) {
              scope.$store.dispatch("checkingtoken");
            }
          });
      });
    },
    export(option) {
      this.excel_data = [];
      this.pdf_data = [];
      this.txt_data = [];
      var export_date = {
        from_date: this.payload.from_date,
        to_date: this.payload.to_date,
        misseddetail: "exportQueuSummaryReport",
      };
      this.$store.dispatch("exportdatafetch", export_date).then((response) => {
        this.exports = response;
        for (var i = 0; i < this.exports.length; i++) {
          if (response[i].calldate) {
            var date = new Date(response[i].calldate);
            var options = {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            };
            var timeString = date.toLocaleString("en-US", options);
            this.call_date = response[i].calldate.substr(0, 11) + timeString;
          }
          this.excel_data.push({
            Date: this.call_date,
            Queue: response[i].queue,
            Total: response[i].answered + response[i].abandoned,
            Answered: response[i].answered,
            Abandoned: response[i].abandoned,
            Talk_time: response[i].talk_time,
            Wait_time: response[i].wait_time,
            Caller_hangup: response[i].caller_hangup,
            Agent_hangup: response[i].agent_hangup,
          });
          this.txt_data.push({
            Date: this.call_date,
            Queue: response[i].queue,
            Total: response[i].answered + response[i].abandoned,
            Answered: response[i].answered,
            Abandoned: response[i].abandoned,
            Talk_time: response[i].talk_time,
            Wait_time: response[i].wait_time,
            Caller_hangup: response[i].caller_hangup,
            Agent_hangup: response[i].agent_hangup,
          });
          this.pdf_data.push([
            this.call_date,
            response[i].queue,
            response[i].answered + response[i].abandoned,
            response[i].answered,
            response[i].abandoned,
            response[i].talk_time,
            response[i].wait_time,
            response[i].caller_hangup,
            response[i].agent_hangup,
          ]);
        }

        if (option == "CSV") {
          let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
          csvContent +=
            "Date,Queue,Total,Answered,Abandoned,Talk Time,Wait Time,Hangup By Caller,Hangup By Agent\r\n";
          this.excel_data.forEach(function (rows) {
            let data = Object.values(rows);
            let data1 = [];
            data.forEach((elm) => {
              if (typeof elm === "string") {
                data1.push(elm.replace(/'|"|#|\n/g, " "));
              } else {
                data1.push(elm);
              }
            });
            for (let i = 0; i < data1.length; i++) {
              if (
                data1[i] != null &&
                data1[i] != undefined &&
                data1[i] != "" && typeof data1[i] == "string"
              ) {
                if (data1[i].includes(",")) {
                  data1[i] = data1[i].replaceAll(",", ";");
                }
                if(data1[0]){
                  data1[0] = data1[0].slice(0, 10)
                }
              }
            }
            let row = data1.join(",");
            csvContent += row + "\r\n";
          });
          var date1 = new Date();
          date1 = moment(date1).format("YYYY-MM-DD hh-mm");
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "QueueSummaryReport_" + date1 + ".csv");
          document.body.appendChild(link);
          link.click();
        }
        if (option == "Txt(Tab-Delimited)") {
          let txtContent = "";
          let txt_key = [
            "Date",
            "Queue",
            "Total",
            "Answered",
            "Abandoned",
            "Talk Time",
            "Wait Time",
            "Hangup By Caller",
            "Hangup By Agent",
          ];
          let txt_tab = txt_key.join("\t");
          txtContent += `${txt_tab} \r\n`;
          // txtContent +=
          //   "Date Time,DID Number,Phone Number,Queue,Application,Duration\r\n";
          //  csvContent += "Date,Uniqueid,DID,Queue,Phone Number,Call type ,Queue Enter Time ,Answer Time ,Application ,Agent ,Station,Dispo Status,Status ,Duration ,Talk Time,Comments \r\n";
          this.txt_data.forEach(function (rows) {
            let data = Object.values(rows);
            let row = data.join("\t");
            txtContent += row + "\r\n";
          });
          var date2 = new Date();
          date2 = moment(date2).format("YYYY-MM-DD hh-mm");
          var encodedUri1 =
            "data:text/plain;charset=utf-8,\uFEFF" +
            encodeURIComponent(txtContent);

          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute(
            "download",
            "QueueSummaryReport_" + date2 + ".txt"
          );
          document.body.appendChild(link1);
          link1.click();
        }
      });
    },
    resetvalue() {
      this.skip = 0;
      var to_date = new Date();
      to_date = moment(to_date).format("YYYY-MM-DD");
      var from_date = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 28;
      from_date.setTime(from_date.getTime() - dateOffset);
      from_date = moment(from_date).format("YYYY-MM-DD");
      this.$store.dispatch("fromdate", from_date);
      this.$store.dispatch("todate", to_date);
      this.appliedfilter.queue = "";
      this.appliedfilter.flag = true;
      this.appliedfilter.fromdate = "";
      this.appliedfilter.todate = "";
      if (this.appliedfilter.flag) {
        this.appliedfilter.fromdate = from_date;
        this.appliedfilter.todate = to_date;
        this.appliedfilter.flag = false;
      }
      this.$store.commit("FILTER_QUEUE", { queue: "" });
      this.getDataFromApi();
      this.page.isFilterOpen = true;
    },

    refreshvalue() {
      this.$store.commit("FILTER_QUEUE", { queue: "" });
      this.page_flag = true;
    },
  },
};
</script>
<style>
.smallPadding th,
.smallPadding td {
  padding: 0px 5px !important;
  width: 15px;
  text-align: center;
}

.br-1 {
  border-right: 1px solid #ccc;
}

.bb-1 {
  border-bottom: 1px solid #ccc;
}

.extra-width {
  width: 6em !important;
}
</style>