<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :exportOptions="page.exportOptions" :breadcrumbs="page.breadcrumbs" @openFilter="openRecordingReportFilter"
      @refreshPage="refreshRecordingReportPage" @exportData="exportRecordingReport"
      :style="styleObject"></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>

      <v-data-table :headers="headers" :items="recording_dates" class="elevation-1" :pagination.sync="pagination"
        :total-items="recordingtotal" :rows-per-page-items="[5, 10, 25]">
        <template v-slot:items="props">
          <td class="checkEmpty p0" v-for="(property, index) in Object.keys(props.item)" :key="index">
            {{ headers[index].value == property &&  headers[index].masking ? ChartoMaskingChar(props.item[property]) :props.item[property] }}
          </td>
          <td class="text-xs-center checkEmpty">
            <div class="row">
              <v-btn small fab flat color="info" @click="editItem(props.item)">
                <i class="fa fa-comments"  aria-hidden="true"></i>
              </v-btn>
              <v-btn small fab flat color="info" @click="togglePlayingRecordSheet('Listen', props.item)">
                <i class="fas fa-microphone"></i>
              </v-btn>
            </div>
          </td>
        </template>
        <template v-slot:item="props">

        </template>
      </v-data-table>
    </v-container>

    <v-navigation-drawer fixed disable-resize-watcher right  v-model="page.isFilterOpen" class="filterNavigation">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <RecordingReportFilter v-if="page.isFilterOpen" @getDataFrom="getDataFrom" :appliedfilter="appliedfilter" :teambool="teambool" :teamvalues="teamvalues">
          </RecordingReportFilter>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="resetvalue()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="FilterData">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-bottom-sheet v-model="page.playingRecord" hide-overlay persistent lazy inset max-width="500" class="align-left"
      id="barging-div" transparent>
      <v-card tile class="player-div">
        <v-toolbar color="light" small>
          <audio controls autoplay v-if="page.playingRecord">
            <source :src="recordingplay" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <v-spacer></v-spacer>
          <div class="row justify-fe">
            <a :href="recordingplay" download>
              <v-btn icon>
                <i class="fas fa-download"></i>
              </v-btn>
            </a>
            <v-btn icon>
              <v-icon @click="togglePlayingRecordSheet('close', {})">close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
      </v-card>
    </v-bottom-sheet>
    <template>
      <v-dialog persistent :value="isopen" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">Add Comments</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="RecordingreportMain" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>

                  <v-flex xs12>
                    <v-text-field v-model="Comments" required label="Comments" type="text"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closecomments()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="save()">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import RecordingReportFilter from "./Components/RecordingReportFilter";
import { MaskCharacter } from "@/utils/helpers";
// import pdfexport from "./Components/pdfexport";
import { mapGetters } from "vuex";
// import jwtDecode from "jwt-decode";
import moment from "moment";
export default {
  components: {
    loader,
    RecordingReportFilter,
    // pdfexport,
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    // this.$store.dispatch("reportuserlist").then(() => {
    // // this.getDataFromApi();
    // this.dateformate();
    // });
    let teams =  JSON.parse(localStorage.getItem("Team"))
    let team_agent_user =  JSON.parse(localStorage.getItem("Team_agent_user"))
    if (teams.length > 0 && team_agent_user.length > 0) {
      this.teambool = true
      this.teamvalues= team_agent_user
    }else{
      this.teambool = false
      this.teamvalues = []
    }
    this.dateformate();
    // this.token = this.auth0accessToken;
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:recording:export"
          ) !== -1
        ) {
          scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
        } else {
          scope.page.exportOptions = [];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:masking:invisible"
          ) === -1
        ) {
          scope.masking = true;
        }
      }.bind(scope),
      1
    );
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["recording_date", "auth0accessToken","MaskingModules"]),
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Call Recordings Report",
        icon: "fa-compact-disc fa-2x",
        loading: true,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
      },
      payload: {
        form_date: "",
        to_date: "",
        skip: 0,
        take: 0,
        agent: "",
        descending: "",
        export: "exportrecordigdetails",
      },
      apiinfo: JSON.parse(localStorage.apiInfo || "{}"),
      appliedfilter: {
        operator: "",
        value: "",
        phonenumber: "",
        type: "",
        station: "",
        agent: "",
        did: "",
        queue: "",
        dispo: "",
        status: "",
        flag: false,
        f_flag: 0,
        t_flag: 0,
        uniqueid: "",
        subdispo:""
      },

      url: "",
      dialog: false,
      recordingplay: "",
      pagination: {
        rowsPerPage: 10,
      },
      local_token: "",
      headers: [

        { text: "Play", align: "left", sortable: true, class: "p0", value: "" },
      ],

      recordingreport: [],
      recording_dates: [],
      recordingtotal: 0,
      txt_data: [],
      // pdf_data: [],
      excel_data: [],
      page_flag: false,
      pages: "",
      isopen: false,
      Comments: "",
      dispoList:[],
      subdispoList:[],
      Filename:"RecordingReport",
      masking:false,
      teambool:false,
      teamvalues:[]
    };
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    FilterData(){
      this.page.isFilterOpen = false
      this.getDataFromApi()
    },
    closeModel: function () {
      this.dialog = false;
    },
    openRecordingReportFilter: function () {
      this.page.isFilterOpen = true;
    },
    refreshRecordingReportPage: function () {
      var scope = this;
      scope.appliedfilter.operator = "";
      scope.appliedfilter.value = "";

      // this.pagination.page = 1
      scope.dateformate();
      scope.page.loading = true;
      this.refreshvalue();
      this.appliedfilter.phonenumber = "";
      this.appliedfilter.type = "";
      this.appliedfilter.station = "";
      this.appliedfilter.agent = "";
      this.appliedfilter.did = "";
      this.appliedfilter.queue = "";
      this.appliedfilter.dispo = "";
      this.appliedfilter.status = "";
      this.appliedfilter.uniqueid = "";
      this.appliedfilter.subdispo = "";
      scope.getDataFromApi();
    },
    exportRecordingReport: function (option) {
      if (option == "CSV" || option == "Txt(Tab-Delimited)") {
        this.export(option);
      } else {
        this.dialog = true;
        this.export(option);
        // this.pdf_flag = true;
      }
    },
    togglePlayingRecordSheet: function (event, item) {
      if (event == "close") {
        this.page.playingRecord = false;
      } else {
        var date = moment(item.CallDate).format("YYYY-MM-DD");
        // var decoded = jwtDecode(this.token);
        // var tid = decoded["http://api.doocti.com/claims/tenant_code"];
        var items = {
          id: item.Uniqueid,
          date: date,
          tid: this.apiinfo.tenant_code,
        };
        console.log(item,"itemitemitemitem",items,date)
        return new Promise(() => {
          this.$store.dispatch("audioplay", items).then((response) => {
            this.recordingplay = response.url;
            if (this.recordingplay != "") {
              this.page.playingRecord = true;
            }
          });
        });
      }
    },
    dateformate() {
      this.page.loading = false;
      this.to_date = new Date();
      this.to_date = moment(this.to_date).format("YYYY-MM-DD HH:mm:ss");
      var from_date1 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date1.setTime(from_date1.getTime() - dateOffset);
      this.from_date = moment(from_date1).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("FILTERFROMDATE", this.from_date);
      this.$store.commit("FILTER_TODATE", this.to_date);
      // this.getDataFrom();
    },
    getDataFromApi() {
      var scope = this;
      this.payload.value = this.appliedfilter.value
      this.payload.operator = this.appliedfilter.operator
      this.payload.dispo = this.appliedfilter.dispo
      this.payload.subdispo = this.appliedfilter.subdispo
      var to_date = new Date();
      this.payload.to_date = moment(to_date).format("YYYY-MM-DD HH:mm:ss");
      var from_date1 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date1.setTime(from_date1.getTime() - dateOffset);
      this.payload.from_date = moment(from_date1).format("YYYY-MM-DD HH:mm:ss");
      var { page, rowsPerPage } = this.pagination;
      this.payload.take = rowsPerPage;
      //scope.$store
      // .dispatch("reportuserlist")
      // .then((response) => {
      let dataa = []
      let team_name = ""
      let teams =  JSON.parse(localStorage.getItem("Team"))
      let team_agent_user =  JSON.parse(localStorage.getItem("Team_agent_user"))
      if (teams.length > 0 && team_agent_user.length > 0) {
        dataa =  team_agent_user
        team_name = teams.map(elm => elm.name).flat()
      }else{
        dataa = JSON.parse(localStorage.getItem("agent_emails"));
      }

      scope.payload.agent = dataa;
      console.log(rowsPerPage,"rowwwwwwwwwwwwwwww");
      
      if (rowsPerPage == undefined) {
        rowsPerPage = 5;
      }
      if (rowsPerPage == -1) {
        this.payload.take = this.total;
      } else {
        this.payload.take = rowsPerPage;
      }
      if (page != "" && page != undefined && page != null) {
        this.skip = page;
      } else {
        this.skip = 1;
      }
      this.payload.skip = (this.skip - 1) * this.payload.take;
      return new Promise((resolve) => {
        var { descending } = scope.pagination;
        this.payload.descending = descending;
        this.payload.team_name = team_name
        this.$store
          .dispatch("recordingdetails", this.payload)
          .then((response) => {
            var datas = response.data;
            scope.recording_dates = datas;
            let values = scope.recording_dates
            for (let i = 0; i < values.length; i++) {
              let element = values[i]
              delete element.record_location
              delete element.queue
              delete element.queue_enter_time
              delete element.status
              delete element.answer_time
              delete element.last_app
              delete element.comments
              delete element.dispo_status
              delete element.did_number
            }
           
            scope.page.loading = false;
            // if (scope.page_flag) {
            //   this.pagination.page = 1;
            //   scope.page_flag = false;
            // }
            if (response.data.length) {
              scope.headers = [];
              Object.keys(response.data[0]).forEach((element) => {
                scope.headers.push({
                  text: element,
                  align: "left",
                  sortable: false,
                  class: "p0",
                  value: element,
                  masking: scope.masking && element == this.MaskingModules.MaskingModules[this.Filename].find(elm => elm == element)
                  // text1:"play"
                });

              });
            }
            this.total = response.count;
            this.recordingtotal = this.total;
            resolve(this.total);
            if (response.response.status === 401) {
              scope.$store.dispatch("checkingtoken");
            }

            this.$store.dispatch("reportDispo").then(()=>{
              this.$store.dispatch("reportSubdispo").catch((err)=>{
                console.log(err)
              })
          }).catch((err)=>{
            console.log(err)
          })
          })
          .catch(function (err) {
            if (err.response.status === 401) {
              scope.$store.dispatch("checkingtoken");
            }
          });
      });
      //})
      // .catch(() => {

      // });
    },
    getDataFrom() {
      this.skip = 0;
      this.getDataFromApi();
      this.page_flag = true;
      this.page.isFilterOpen = false
    },

    resetvalue() {
      var to_date = new Date();
      to_date = moment(to_date).format();
      var from_date = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date.setTime(from_date.getTime() - dateOffset);
      from_date = moment(from_date).format();
      this.refreshvalue();
      this.$store.dispatch("fromdate", from_date);
      this.$store.dispatch("todate", to_date);
      this.appliedfilter.uniqueid = "";
      this.appliedfilter.phonenumber = "";
      this.appliedfilter.type = "";
      this.appliedfilter.station = "";
      this.appliedfilter.agent = "";
      this.appliedfilter.did = "";
      this.appliedfilter.queue = "";
      this.appliedfilter.dispo = "";
      this.appliedfilter.status = "";
      this.appliedfilter.flag = true;
      this.appliedfilter.fromdate = "";
      this.appliedfilter.todate = "";
      this.appliedfilter.campaign = "";
      if (this.appliedfilter.flag) {
        this.appliedfilter.fromdate = from_date;
        this.appliedfilter.todate = to_date;
        this.appliedfilter.flag = false;
      }
      this.appliedfilter.subdispo = "";
      this.getDataFromApi();
      this.page.isFilterOpen = true;
    },
    export(option) {
      this.excel_data = [];
      this.txt_data = [];
      // this.pdf_data = [];
      let teams =  JSON.parse(localStorage.getItem("Team"))
      // let team_agent_user =  JSON.parse(localStorage.getItem("Team_agent_user"))
      let team_name = ""
      if (teams.length > 0) {
         team_name = teams.map(elm => elm.name).flat()
      }
      var export_date = {
        from_date: this.payload.from_date,
        to_date: this.payload.to_date,
        calldetail: "exportrecordigdetails",
        team_name : team_name
      };
      this.$store.dispatch("exportdatafetch", export_date).then((response) => {
        this.exports = response;
        var phone;
        for (var i = 0; i < this.exports.length; i++) {
          if(this.masking){
            this.MaskingModules.MaskingModules[this.Filename].forEach((elm)=>{
              response[i][elm] =  this.ChartoMaskingChar(response[i][elm])
            })
          }
          if (response[i]['Phone Number']) {
            var mobile_number = response[i]['Phone Number'];
            var reg = new RegExp("[&/\\#,+()$~%.'\":*?<>{}]", "gi");
            var phone_number = mobile_number.replace(reg, "");
            phone = phone_number.replace(" ", "");
          }
          if (response[i].CallDate) {
            var date = new Date(response[i].CallDate);
            var options = {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            };
            var timeString = date.toLocaleString("en-US", options);
            this.call_date = response[i].CallDate.substr(0, 11) + timeString;
          }
          this.excel_data.push({
            Date: this.call_date,
            uniqueid: response[i].Uniqueid,
            Phone: phone,
            Campaign: response[i].Campaign,
            Call_Type: response[i]['Call Type'],
            Agent: response[i].Agent,
            agentName: response[i]['Agent Name'],
            team: response[i].Team,
            Station: response[i].Station,
            Duration: response[i].Duration,
            Talk_Time: response[i]['Talk Time'],
            Recording_Path: response[i].record_location,
          });
          this.txt_data.push([
            this.call_date,
            response[i]['Uniqueid'],
            phone,
            response[i]['Campaign'],
            response[i]['Call Type'],
            response[i]['Agent'],
            response[i]['Agent Nmae'],
            response[i].Team,
            response[i].Station,
            response[i].Duration,
            response[i]['Talk Time'],
            response[i].record_location,
          ]);
          // this.pdf_data.push([
          // this.call_date,
          // response[i].uniqueid,
          // phone,
          // response[i].campaign,
          // response[i].call_type,
          // response[i].agent,
          // response[i].station,
          // response[i].duration,
          // response[i].talk_times,
          // response[i].record_location,
          // ]);
        }

        if (option == "Txt(Tab-Delimited)") {
          let txtContent = "";
          let txt_key = [
            "Date Time",
            "Uniqueid",
            "Phone Number",
            "Campaign",
            "Call Type",
            "Agent", "Agent Name", "Team",
            "Station",
            "Duration",
            "Talk Time",
            "Recording_Path",
          ];

          let txt_tab = txt_key.join("\t");
          txtContent += `${txt_tab} \r\n`;
          // csvContent += "Date,Uniqueid,DID,Queue,Phone Number,Call type ,Queue Enter Time ,Answer Time ,Application ,Agent ,Station,Dispo Status,Status ,Duration ,Talk Time,Comments \r\n";
          this.txt_data.forEach(function (rows) {
            let data = Object.values(rows);
            let row = data.join("\t");
            txtContent += row + "\r\n";
          });
          var date1 = new Date();
          date1 = moment(date1).format("YYYY-MM-DD hh-mm");
          var encodedUri1 =
            "data:text/plain;charset=utf-8,\uFEFF" +
            encodeURIComponent(txtContent);
          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute("download", "RecordDetails" + date1 + ".txt");
          document.body.appendChild(link1);
          link1.click();
        }
        if (option == "CSV") {
          //var BOM = "\uFEFF";
          let csvContent = '';
          csvContent +=
            "Date Time,Uniqueid,Phone Number,Campaign,Call Type,Agent,Agent Name,Team,Station,Duration,Talk Time,Recording_Path\r\n";
          this.excel_data.forEach(function (rows) {
            var data = Object.values(rows);
            let data1 = [];
            data.forEach((elm) => {
              if (typeof elm === "string") {
                data1.push(elm.replace(/'|"|#|\n/g, " "));
              } else {
                data1.push(elm);
              }
            });
            for (let i = 0; i < data1.length; i++) {
              if (
                data1[i] != null &&
                data1[i] != undefined &&
                data1[i] != "" && typeof data1[i] == "string"
              ) {
                if (data1[i].includes(",")) {
                  data1[i] = `"${data1[i]}"`
                }
              }
            }
            let row = data1.join(",");
            csvContent += row + "\r\n";
          });
          var date11 = new Date();
          date11 = moment(date11).format("YYYY-MM-DD hh-mm");
          const encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "RecordDetails" + date11 + ".csv");
          document.body.appendChild(link); // Required for FF
          link.click();
        }
      });
    },

    refreshvalue() {
      this.$store.commit("FILTER_AGENT", { agent: "" });
      this.$store.commit("FILTER_TYPE", { type: "" });
      this.$store.commit("FILTER_STATION", { station: "" });
      this.$store.commit("FILTER_QUEUE", { queue: "" });
      this.$store.commit("FILTER_PHONE", { phone: "" });
      this.$store.commit("FILTER_CAMPAIGN", { campaign: "" });
      this.$store.commit("FILTER_UNIQUEID", { uniqueid: "" });
    },
    editItem: function (data) {
      this.Comments = data.Comments
      this.uniqueid = data.Uniqueid
      this.isopen = true
    },
    closecomments: function () {
      this.Comments = ""
      this.isopen = false
      this.getDataFromApi();
    },
    save: function () {
      console.log()
      if (this.Comments !== "") {
        this.$store
          .dispatch("recordingreportscomments", {
            comments: this.Comments,
            uniqueid: this.uniqueid

          })
          .then((response) => {
            console.log(response)
            this.closecomments()

          }).catch((err) => {
            console.log(err)
          })
      }
    },
    ChartoMaskingChar(data){
      let scope = this
      if(!scope.$store.getters.MaskType.includes(typeof(data))){
          return data
      }
      return MaskCharacter(data)
    }
  },

  beforeDestroy() {
    this.$store.commit("FILTER_AGENT", { agent: "" });
    this.$store.commit("FILTER_TYPE", { type: "" });
    this.$store.commit("FILTER_STATION", { station: "" });
    this.$store.commit("FILTER_QUEUE", { queue: "" });
    this.$store.commit("FILTER_PHONE", { phone: "" });
    this.$store.commit("FILTER_CAMPAIGN", { campaign: "" });
    this.$store.commit("FILTER_UNIQUEID", { uniqueid: "" });
  },
};
</script>
<style>
.icons {
  height: 80px;
}
</style>
