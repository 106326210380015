export function replaceInvalidCharacters(string) {
  let specialCharacters = [`â€“`, `â€™`];
  let normalCharacters = [`-`, `'`];
  let regEx;
  // Loop the array of special and normal characters
  for (let x = 0; x < specialCharacters.length; x++) {
    regEx = new RegExp(specialCharacters[x], "g");
    string = string.replace(regEx, normalCharacters[x]);
  }
  return string;
}

export function cleanHTML(input) {
  // let output = input

  // 1. remove line breaks / Mso classes
  // const stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g
  const stringStripper = /(class=(")?Mso[a-zA-Z]+(")?)/g;
  let output = input.replace(stringStripper, " ");
  // 2. strip Word generated HTML comments
  const commentSripper = new RegExp("<!--(.*?)-->", "g");
  output = output.replace(commentSripper, "");
  let tagStripper = new RegExp(
    "<(/)*(meta|link|span|b|\\?xml:|st1:|o:|font)(.*?)>",
    "gi"
  );
  // 3. remove tags leave content if any
  output = output.replace(tagStripper, "");
  // 4. Remove everything in between and including tags '<style(.)style(.)>'
  const badTags = [
    "style",
    "script",
    "applet",
    "embed",
    "noframes",
    "noscript"
  ];

  for (let i = 0; i < badTags.length; i++) {
    tagStripper = new RegExp(
      "<" + badTags[i] + ".*?" + badTags[i] + "(.*?)>",
      "gi"
    );
    output = output.replace(tagStripper, "");
  }
  // 5. remove attributes ' style="..."'
  const badAttributes = ["style", "start"];
  for (let i = 0; i < badAttributes.length; i++) {
    let attributeStripper = new RegExp(
      " " + badAttributes[i] + '="(.*?)"',
      "gi"
    );
    output = output.replace(attributeStripper, "");
  }
  return output;
}
export function DateTimeCurrent(type) {
  const now = new Date();
  let crt_datetime = {
      YYYY: now.getFullYear(),
      MM: now.getMonth() + 1,
      DD: now.getDate(),
      hh: now.getHours(),
      mm: now.getMinutes(),
      ss: now.getSeconds(),
      day: now.getDay()
  }

  let datetime = {
    YYYY: now.getFullYear(),
    MM: now.getMonth() + 1,
    DD: now.getDate().toString().padStart(2, '0'),
    hh: now.getHours().toString().padStart(2, '0'),
    mm: now.getMinutes().toString().padStart(2, '0'),
    ss: now.getSeconds().toString().padStart(2, '0'),
    day: now.getDay().toString().padStart(2, '0')
  }

  if(type === "datetime"){
    return `${datetime.YYYY}-${datetime.MM}-${datetime.DD} ${datetime.hh}:${datetime.mm}:${datetime.ss}`
  }else if(type === "date"){
    return `${datetime.YYYY}-${datetime.MM}-${datetime.DD}`
  }else if(type === "time"){
    return `${datetime.hh}:${datetime.mm}:${datetime.ss}`
  }
  return crt_datetime
}

export function DateTimeFormate(type,data) {
  const now = new Date(data);
  let crt_datetime = {
      YYYY: now.getFullYear(),
      MM: now.getMonth() + 1,
      DD: now.getDate(),
      hh: now.getHours(),
      mm: now.getMinutes(),
      ss: now.getSeconds(),
      day: now.getDay()
  }

  let datetime = {
    YYYY: now.getFullYear(),
    MM: now.getMonth() + 1,
    DD: now.getDate().toString().padStart(2, '0'),
    hh: now.getHours().toString().padStart(2, '0'),
    mm: now.getMinutes().toString().padStart(2, '0'),
    ss: now.getSeconds().toString().padStart(2, '0'),
    day: now.getDay().toString().padStart(2, '0')
  }

  if(type === "datetime"){
    return `${datetime.YYYY}-${datetime.MM}-${datetime.DD} ${datetime.hh}:${datetime.mm}:${datetime.ss}`
  }else if(type === "date"){
    return `${datetime.YYYY}-${datetime.MM}-${datetime.DD}`
  }else if(type === "time"){
    return `${datetime.hh}:${datetime.mm}:${datetime.ss}`
  }
  return crt_datetime
}


export function DateTimeIsoTimeFormate(isoString) {
  const date = new Date(isoString);

  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);

  const formattedDate = date.toISOString().slice(0, 19).replace("T", " ");
  return formattedDate
}