<template>
    <div v-if="!renderComponent" :class="!value_cls ? lds_ellipsis : edit_ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>

    <div v-else :class="value_cls ? preview_container : preview_container1">
        <v-form ref="form">
            <div v-for="(catagory, i) in catagory" :key="i" :class="value_cls ? sectionFlex1 : sectionFlex">
                <div class="catagory_title">
                    <i style="padding: 3px 6px 3px 3px" class="fa fa-bandcamp" aria-hidden="true"></i>
                    <div style="font-weight: 500">{{ catagory }}</div>
                </div>
                <div v-if="main_data[i] !== undefined">
                    <div class="component_flex">
                        <div v-if="value_cls" class="component_setup">
                            <div v-for="(field, eachFormIndex) in main_data[i].filter((res) => {
                                if (
                                    res.model != 'lead_id' &&
                                    res.model != 'hopper_status' && res.model != 'id' &&
                                    res.model != 'user'
                                ) {
                                    if (res.model === 'list_id') {
                                        res.input_type = 'Select';
                                        res.values = add_list;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'tags') {
                                        res.input_type = 'Select';
                                        res.values = tags;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'status') {
                                        res.input_type = 'Select';
                                        res.values = status;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'source') {
                                        res.input_type = 'Select';
                                        res.values = source;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'channel') {
                                        res.input_type = 'Select';
                                        res.values = channel;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'timezone') {
                                        res.input_type = 'Select';
                                        res.values = timezone;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    //res.disabled = false;
                                    // if (page_ == true) {
                                    // if (res.model === 'phone_number') {
                                    // res.input_type = 'Select';
                                    // // res.values = [{name:'1',value:'1'}];
                                    // res.fieldType = 'SelectList';
                                    // res.apifetch = true;
                                    // res.apidata = null;
                                    // }
                                    // }

                                }
                                return (
                                    ((res.is_link && res.link_type === 'Parent') ||
                                        (!res.is_link &&
                                            res.model != 'modified_date' &&
                                            res.model != 'lead_id' &&
                                            // res.model != 'list_id' &&
                                            res.model != 'hopper_status' && res.model != 'id' &&
                                            res.model != 'subdisposition' &&
                                            res.model != 'deleted' &&
                                            res.model != 'disposition' &&
                                            res.model != 'user' &&
                                            res.model != 'ticket_id' &&
                                            res.model != 'contact_id' &&
                                            res.model != 'created_at' &&
                                            res.model != 'modified_at' &&
                                            res.model !== 'assign' &&
                                            res.model !== 'modified_by' &&
                                            res.model !== 'created_by' &&
                                            res.model !== 'is_merge' &&
                                            res.model !== 'is_duplicate' &&
                                            res.model !== 'merged_to' &&
                                            res.model !== 'merged_as' &&
                                            res.model !== 'is_merge' &&
                                            res.model !== 'req_id' &&
                                            res.model !== 'req_module')) && res.show_on_view == true
                                );
                            })" :key="'top' + eachFormIndex">
                                <div :key="`row-${eachFormIndex}`">
                                    <div>
                                        <component :is="field.fieldType" :currentField="field" :Allfields="main_data[i]" :Filename="Filename" :ChartoMaskingChar="ChartoMaskingChar" :MaskingModules="MaskingModules.MaskingModules" :masking="masking">
                                        </component>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="component_setup">
                            <div v-for="(field, eachFormIndex) in main_data[i].filter((res) => {
                                if (
                                    res.model != 'lead_id' &&
                                    res.model != 'hopper_status' && res.model != 'id' &&
                                    res.model != 'user'
                                ) {
                                    if (res.model === 'list_id') {
                                        res.input_type = 'Select';
                                        res.values = add_list;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'assign') {
                                        res.input_type = 'Select';
                                        res.values = user_data;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'tags') {
                                        res.input_type = 'Select';
                                        res.values = tags;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'status') {
                                        res.input_type = 'Select';
                                        res.values = status;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'source') {
                                        res.input_type = 'Select';
                                        res.values = source;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'channel') {
                                        res.input_type = 'Select';
                                        res.values = channel;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    if (res.model === 'timezone') {
                                        res.input_type = 'Select';
                                        res.values = timezone;
                                        res.fieldType = 'SelectList';
                                        res.disabled = false;
                                    }
                                    //res.disabled = false;
                                    // if (page_ == true) {
                                    // if (res.model === 'phone_number') {
                                    // res.input_type = 'Select';
                                    // // res.values = [{name:'1',value:'1'}];
                                    // res.fieldType = 'SelectList';
                                    // res.apifetch = true;
                                    // res.apidata = null;
                                    // }
                                    // }
                                    // res.disabled = false;
                                }
                                return (
                                    ((res.is_link && res.link_type === 'Parent') ||
                                        (!res.is_link &&
                                            res.model != 'modified_date' &&
                                            res.model != 'lead_id' &&
                                            res.model != 'hopper_status' && res.model != 'id' &&
                                            res.model != 'subdisposition' &&
                                            res.model != 'disposition' &&
                                            res.model != 'deleted' &&
                                            res.model != 'user' &&
                                            res.model != 'ticket_id' &&
                                            res.model != 'contact_id' &&
                                            res.model != 'created_at' &&
                                            res.model != 'modified_at' &&
                                            res.model !== 'modified_by' &&
                                            res.model !== 'created_by' &&
                                            res.model !== 'is_merge' &&
                                            res.model !== 'is_duplicate' &&
                                            res.model !== 'merged_to' &&
                                            res.model !== 'merged_as' &&
                                            res.model !== 'is_merge' &&
                                            res.model !== 'req_id' &&
                                            res.model !== 'req_module')) && res.show_on_view == true
                                );
                            })" :key="eachFormIndex">
                                <div :key="`row-${eachFormIndex}`">
                                    <div>
                                        <component :is="field.fieldType" :currentField="field" :Allfields="main_data[i]">
                                        </component>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="component_setup">
                            <div v-for="(field, eachFormIndex) in main_data[i].filter(
                                (res) =>
                                    res.is_link &&
                                    res.link_type === 'Child' &&
                                    (res.fieldType === 'SelectList' ||
                                        res.fieldType === 'RadioButton')
                            )" :key="'down' + eachFormIndex">
                                <div :key="`row-${eachFormIndex}`">
                                    <div>
                                        <component :is="field.fieldType" :currentField="field" :Allfields="main_data[i]">
                                        </component>
                                    </div>
                                </div>
                            </div>

                            <div v-for="(field, eachFormIndex) in main_data[i].filter(
                                (res) =>
                                    res.is_link &&
                                    res.link_type === 'Child' &&
                                    res.fieldType != 'SelectList' &&
                                    res.fieldType != 'RadioButton' &&
                                    res.visible
                            )" :key="eachFormIndex">
                                <div :key="`row-${eachFormIndex}`">
                                    <div>
                                        <component :is="field.fieldType" :currentField="field" :Allfields="main_data[i]">
                                        </component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
    </div>
</template>
    
<script>
import { FormBuilder } from "@/components/form_elements/formbuilder";

import { mapGetters } from "vuex";
var _ = require("lodash");
export default {
    name: "Publish",
    components: FormBuilder.$options.components,
    props: {
        insertsavepopup: Boolean,
        apidata: {
            type: Array,

        },
        requiredfield: Array,
        value_cls: {
            type: Boolean,
            default: false,
        },
        contactalldata: Array,
        contactnumid: Array,
        page_: { type: Boolean, default: false },
        support: {
            type: Boolean,
            default: false,
        }, model: {
            default: "LEAD"
        },
        Filename:{type:String,default: ""},
        masking:{type: Boolean,default: false},
        ChartoMaskingChar:{type:Function}
    },
    data() {
        return {
            timezone: [],
            source: [],
            channel: [],
            lds_ellipsis: "lds-ellipsis",
            edit_ellipsis: "edit-ellipsis",
            renderComponent: false,
            main_data: [],
            user_data: [],
            add_list: [],
            defaultlist:[],
            preview_container: "preview_container",
            preview_container1: "preview_container1",
            sectionFlex: "sectionFlex",
            sectionFlex1: "sectionFlex1",
            editvalue_cls: false,
            count: 0,
            validationclone: [],
            tags: [],
            status: [],
            phone_numberdynamic: [],
            catagory: [],
            datetimevalidation_data: []
        };
    },
    watch: {
        main_data: {

            deep: true,
            handler() {
                if (this.count == 3) {

                    this.editvalue_cls = true;
                }
                this.count = this.count + 1;
                this.$emit("update", this.main_data);
            },
        },
        insertsavepopup() {
            this.savepopupstrigger();
        },
        apidata() {
            try {
                if (!this.editvalue_cls) {
                    for (let i = 0; i < this.main_data.length; i++) {
                        this.main_data[i].forEach((res) => {
                            this.apidata.forEach((val) => {
                                for (const key in val) {
                                    if (res.fieldType === "Checkbox") {
                                        res.value.value = val[res.value.name];
                                    } else {
                                        if ('list_id' === key&&res.model === key && this.apidata[0][res.model] !== null) {
                                            console.log(val[res.value.name],"uuuuuuuuuuuuuuuuuuuuuuuuuuuu")
                                            res.value.value = val[res.value.name];
                                        }
                                        if ('list_id' !== key&&res.model === key && this.apidata[0][res.model] !== null) {
                                            console.log(val[res.value.name],"uuuuuuuuuuuuuuuuuuuuuuuuuuuu")
                                            res.value.value = val[res.value.name];
                                        }
                                    }
                                }
                            });
                        });
                    }
                }
            }catch(err){
console.log(err)
            }
            
        },
    },
    methods: {
        savepopupstrigger() {
            if (this.insertsavepopup === true) {
                let scope = this;
                let field = [];
                this.datetimevalidation_data = []
                var today = new Date();
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1;
                let dd = today.getDate();
                let hh = today.getHours();
                let MM = today.getMinutes();
                let ss = today.getSeconds();
                if (dd < 10) dd = "0" + dd;
                if (mm < 10) mm = "0" + mm;
                if (hh < 10) hh = "0" + hh;
                if (MM < 10) MM = "0" + MM;
                if (ss < 10) ss = "0" + ss;

                today =
                    yyyy + "-" + mm + "-" + dd + " " + hh + ":" + MM + ":" + ss;

                this.main_data.forEach((res) => {
                    res.forEach((elm) => {
                        if (elm.model != "lead_id" && elm.model != "ticket_id") {
                            if (
                                elm.model == "modified_date" ||
                                elm.model == "modified_at" ||
                                elm.model == "created_at"
                            ) {
                                elm.value.value = today.toString();
                            }
                            if (elm.model == "hopper_status") {
                                elm.value.value = "0";
                            }
                            if (elm.model == "list_id" && elm.value.value=="" && this.defaultlist.length>0) {
                                
                                elm.value.value = this.defaultlist[0];
                            }
                            if (elm.model == "deleted") {
                                elm.value.value = "false";
                            }
                            if(elm.model == 'isclosed'){
                                elm.value.value = "false";
                            }
                            if (elm.model == "created_by" || elm.model == "modified_by") {
                                elm.value.value = JSON.parse(localStorage.apiInfo).user_email;
                            }
                            if (elm.model == "disposition") {
                                elm.value.value = "new";
                            }
                            if (elm.required == true && elm.fieldType === "DatetimePicker" && elm.value.value == "") {
                                let formdata = ""
                                if (elm.label != "") {
                                    formdata = elm.label
                                } else if (elm.model != "") {
                                    formdata = elm.model
                                }
                                this.datetimevalidation_data.push(formdata)
                            }

                            if (elm.model_type == "Small Number" || elm.model_type == "BigNumber" || elm.model_type == "Number") {
                                if (elm.value.value == "") {
                                    elm.value.value = null
                                }

                            }

                            if (elm.model == "sms_notify") {
                                elm.value.value = "0";
                            }
                            if (elm.model == "wa_notify") {
                                elm.value.value = "0";
                            }
                            if (elm.model == "api_push") {
                                elm.value.value = "0";
                            }
                            if (elm.model == "email_notify") {
                                elm.value.value = "0";
                            }

                            if (elm.value.value == null) {
                                elm.value.value = ""
                            }

                            if (elm.model !== "duration") {
                                field.push(elm.value);
                            }
                        }
                        if (elm.fieldType === "Checkbox") {
                            elm.value.value = elm.value.value.toString();
                        }
                    });
                });

                let payload = {
                    template_name: scope.forms[0].template_name,
                    fields: field,
                };
                let formdata = {}
                let formvalidate_status = this.$refs.form.validate()
                if (formvalidate_status == true && this.datetimevalidation_data.length == 0) {
                    if (this.page_ == false && this.model === `LEAD`) {
                        scope.$store
                            .dispatch("insertAsterData", payload)
                            .then((res) => {
                                if (res.data.statusCode === 201) {
                                    this.$emit("insertleads", true);
                                    this.insertsavepopup = false;
                                } else {
                                    this.insertsavepopup = false;
                                }
                            })
                            .catch((error) => {
                                this.insertsavepopup = false;
                                console.log(error);
                            });
                    }
                } else if (this.datetimevalidation_data.length > 0 && formvalidate_status == false) {
                    formdata.errorpopup_common = true
                    formdata.errorpopup_datetime = true
                    formdata.datetimevalidation_data = this.datetimevalidation_data
                    this.$emit("formpopup", formdata);
                }
                else if (this.datetimevalidation_data.length > 0) {
                    formdata.errorpopup_common = false
                    formdata.errorpopup_datetime = true
                    formdata.datetimevalidation_data = this.datetimevalidation_data
                    this.$emit("formpopup", formdata);
                }
                else if (this.$refs.form.validate() == false) {
                    formdata.errorpopup_common = true
                    formdata.errorpopup_datetime = false
                    formdata.datetimevalidation_data = this.datetimevalidation_data
                    this.$emit("formpopup", formdata);
                }

                if (this.page_ == true && this.model === `TICKET`) {
                    delete payload.template_name;

                    let phone_numberfetch = payload.fields.find(
                        (e) => e.name == "phone_number"
                    );
                    let query = {
                        phone_number: Number(phone_numberfetch.value),
                    };
                    console.log("phone_numberfetch", query);
                    this.$store
                        .dispatch("TicketPhoneSearch", query)
                        .then((res) => {
                            if ( res.statusCode === 204 ) {
                                this.$emit(
                                    "Supportmsg",
                                    `Do create Contact ${phone_numberfetch.value}`
                                );
                                this.insertsavepopup = false;
                            } else {
                                payload.fields = payload.fields.map((e) => {
                                    if (e.name == "tags") {
                                        e.value = e.value.toString();
                                    }
                                    if (e.name == "is_merge") {
                                        e.value = false;
                                    }
                                    if (e.name == "is_duplicate") {
                                        e.value = false;
                                    }
                                    if (e.name == "merged_as") {
                                        e.value = 'parent';
                                    }
                                    if (e.name == "req_module") {
                                        e.value = 'contact';
                                    } if (e.name == "req_id") {
                                        e.value = res.data[0].id
                                    }
                                    return e;
                                });
                                scope.$store
                                    .dispatch("CreateAsterTicket", payload)
                                    .then((res) => {
                                        if (res.statusCode === 201) {
                                            this.$emit("insertleads", true);
                                            this.insertsavepopup = false;
                                        } else {
                                            this.insertsavepopup = false;
                                        }
                                    })
                                    .catch((error) => {
                                        this.insertsavepopup = false;
                                        console.log(error);
                                    });
                            }
                        })
                        .catch((error) => {
                            this.insertsavepopup = false;
                            console.log(error);
                        });
                } else if (this.page_ == true && this.model === `CONTACT`) {
                    payload.islead = 0
                    payload.modified_by = JSON.parse(localStorage.apiInfo).user_email
                    if(this.validateFields(payload)){
                        this.insertsavepopup = false;
                        return;
                    }
                    scope.$store
                        .dispatch("insertAsterContact", payload)
                        .then((res) => {
                            if (res.statusCode === 201) {
                                this.$emit("insertleads", true);
                                this.insertsavepopup = false;
                            } else {
                                this.insertsavepopup = false;
                            }
                        })
                        .catch((error) => {
                            this.insertsavepopup = false;
                            console.log(error);
                        });
                }


            }
        },
    validateFields(payload) {
        let validationErrors = [];
         payload.fields.forEach(field => {
            if (field.name === 'name') {
                if (!field.value || field.value.trim() === '') {
                    validationErrors.push({ field: 'name', message: 'Name is required.' });
                }
            } else if (field.name === 'phone_number') {
                if (!field.value || !/^\d+$/.test(field.value.trim())) {
                  validationErrors.push({ field: 'phone_number', message: 'Phone number is not valid' });
                }
            }
        });
        if (validationErrors.length > 0) {
            return true;
        } else {
            return false;
        }
    },
        validatecheck() {
            this.datetimevalidation_data = []
            this.main_data.forEach((res) => {
                res.forEach((elm) => {
                    if (elm.model != "lead_id" && elm.model != "ticket_id") {
                        if (elm.required == true && elm.fieldType === "DatetimePicker" && elm.value.value == "") {
                            let formdata = ""
                            if (elm.label != "") {
                                formdata = elm.label
                            } else if (elm.model != "") {
                                formdata = elm.model
                            }
                            this.datetimevalidation_data.push(formdata)
                        }

                    }
                });
            });
            let formdata = {}
            let formvalidate_status = this.$refs.form.validate()
            if (this.datetimevalidation_data.length > 0 && formvalidate_status == false) {
                formdata.errorpopup_common = true
                formdata.errorpopup_datetime = true
                formdata.datetimevalidation_data = this.datetimevalidation_data
                return formdata
            }
            else if (this.datetimevalidation_data.length > 0) {
                formdata.errorpopup_common = false
                formdata.errorpopup_datetime = true
                formdata.datetimevalidation_data = this.datetimevalidation_data
                return formdata
            }
            else if (formvalidate_status == false) {
                formdata.errorpopup_common = true
                formdata.errorpopup_datetime = false
                formdata.datetimevalidation_data = this.datetimevalidation_data
                return formdata
            } else {
                formdata.errorpopup_common = false
                formdata.errorpopup_datetime = false
                formdata.datetimevalidation_data = this.datetimevalidation_data
                return formdata
            }
        }
    },
    created() {
        this.catagory = [];
        for (let i = 0; i < this.forms[0].fields.length; i++) {
            const element = this.forms[0].fields[i].catagory_value;

            if (
                this.forms[0].fields[i].model === "lead_id"
            ) {
                this.catagory.splice(0, 0, element);
            } else if (!this.catagory.includes(element)) {
                this.catagory.push(element);
            }
        }

        let catagory_arrs = [...new Set(this.catagory)];
        this.catagory = [...new Set(this.catagory)]
        this.forms[0].fields[0].catagory_arr = catagory_arrs;

        this.editfields = JSON.parse(localStorage.getItem("editfields"));

        this.main_data = [];
        let main_data = [];
        if (this.catagory !== undefined) {
            for (let j = 0; j < this.catagory.length; j++) {
                let seperate_arr = [];
                for (let i = 0; i < this.forms[0].fields.length; i++) {
                    const element = this.forms[0].fields[i];
                    if (
                        this.catagory[j] ===
                        this.forms[0].fields[i].catagory_value
                    ) {
                        element.value = {
                            name: element.model,
                            value: "",
                        };
                        seperate_arr.push(element);
                    }
                }
                main_data.push(seperate_arr);
            }
        }
        localStorage.setItem("linkfields", JSON.stringify(main_data));
    },
    beforeDestroy() {
        localStorage.setItem("editfields", JSON.stringify(false));
        this.renderComponent = false;

    },
    mounted() {
        this.validationclone = [];
        this.main_data = JSON.parse(localStorage.getItem("linkfields"));
        this.user_data = JSON.parse(localStorage.getItem("group_users"));
        var scope = this;

        let payload_ = { template: JSON.parse(localStorage.templetname).templetname, skip: 0, take: 0, campaign_status: 'Y', All: true };
        scope.$store
            .dispatch("GetAsterCampaigns", payload_)
            .then(async (response) => {
                let list = []
                let defaultlist_=[]
                if (response.statusCode == 200) {
                    let campaign = await response.data
                    
                    for (let i = 0; i < campaign.length; i++) {
                        const element = campaign[i];
                        let stats = {
                            list_name: "",
                            list_campaign: element.campaign_name,
                            list_status: "Y",
                            // template:JSON.parse(localStorage.templetname).templetname
                        };
                        
                        await scope.$store.dispatch("FilterAsterList", stats).then(async (response) => {
                            
                            let data = await response.data;
                            if (data !== undefined) {
                                for (let j = 0; j < data.length; j++) {
                                    
                                    const element_ = data[j];
                                    
                                    if(element.primary_list==element_.list_id){
                                        defaultlist_.push(element_.list_id)
                                    }
                                  
                                    list.push(element_.list_id)
                                }
                            }


                        })
                    }

                   
                }else{
                   list=[]
                }
                scope.defaultlist=defaultlist_
                localStorage.setItem('defaultlist_',JSON.stringify(defaultlist_))
                scope.add_list = list;
                    let validation_str = "";
                    for (let i = 0; i < this.main_data.length; i++) {
                        let validationclone = this.main_data[i].map((res) => {
                            if (res.is_link === true) {
                                if (res.link_type === "Child") {
                                    if (res.linked_to != "") {
                                        if (
                                            res.fieldType != "SelectList" &&
                                            res.fieldType != "RadioButton"
                                        ) {
                                            if (res.link_property === "") {
                                                return `${res.label} field select one option in Linked to property.`;
                                            } else if (res.link_property != "") {
                                                return true;
                                            }
                                        } else if (
                                            res.fieldType == "SelectList" ||
                                            res.fieldType == "RadioButton"
                                        ) {
                                            if (_.size(res.link_property) == 0) {
                                                return `${res.label} field give some option values in Linked to property.`;
                                            } else if (_.size(res.link_property) > 0) {
                                                if (
                                                    Object.values(res.link_property).every((res) => res != "")
                                                ) {
                                                    return true;
                                                } else {
                                                    return `${res.label} field give some option values in Linked to property.`;
                                                }
                                            }
                                        }
                                    } else if (res.linked_to === "") {
                                        return `${res.label} field Linked to property is Missing.`;
                                    }
                                } else if (res.link_type === "Parent") {
                                    return true;
                                }
                            } else if (res.is_link === false) {
                                return true;
                            }
                        });
                        validation_str += `${validationclone.toString()},`;
                    }

                    let validation = validation_str
                        .slice(0, validation_str.length - 1)
                        .split(",");
                    let validate = validation.filter((res) => res != "true");
                    setTimeout(() => {
                        this.renderComponent = validation.every((res) => res == "true");
                        if (this.renderComponent == false) {
                            this.$emit("closedpreview", validate);
                        }
                    }, 1500);
            })
            .catch(function (error) {
                scope.showSnackBar("error" + error);
            });



        // scope.$store.dispatch("FilterAsterList", stats).then(async (response) => {

        // });
        scope.$store
            .dispatch("TicketTags")
            .then((res) => {
                if (res.statusCode === 200) {
                    scope.tags = res.data.map((e) => {
                        return { name: e.name, value: e.name };
                    });
                }
            })
            .catch((error) => {
                this.insertsavepopup = false;
                console.log(error);
            });
            scope.$store
            .dispatch("TicketStatus")
            .then((res) => {
                if (res.statusCode === 200) {
                        res.data.map((e) => {
                        const capitalizedLabel = e.name.charAt(0).toUpperCase() + e.name.slice(1).toLowerCase()
                        const capitalizedValue =  e.name.slice(0).toLowerCase()
                        scope.status.push({ name: capitalizedLabel, value: capitalizedValue })
                    });
                }
            })
            .catch((error) => {
                this.insertsavepopup = false;
                console.log(error);
            });
        scope.$store
            .dispatch("GetSourceFilter", { active: 0 })
            .then((res) => {
                if (res.status === 200) {
                    scope.source = res.data.data.map((e) => {
                        return { name: e.name, value: e.name };
                    });
                }
            })
            .catch((error) => {
                this.insertsavepopup = false;
                console.log(error);
            });
        scope.$store
            .dispatch("GetChannelFilter", { active: 0 })
            .then((res) => {
                if (res.status === 200) {
                    scope.channel = res.data.data.map((e) => {
                        return { name: e.name, value: e.name };
                    });
                }
            })
            .catch((error) => {
                this.insertsavepopup = false;
                console.log(error);
            });

        let payload = {
            name: "",
            status: "Y",
            skip: "",
            take: ""
        }
        scope.$store
            .dispatch("GetTimezone", payload)
            .then((res) => {
                this.timezone = res.data.map(elm => {
                    return {
                        name: elm.zone_name,
                        value: elm.zone_name
                    }
                })

            })
            .catch((error) => {
                this.insertsavepopup = false;
                console.log(error);
            });

        const forms= JSON.parse(localStorage.getItem('forms'))
        for (let i = 0; i < forms[0].fields.length; i++) {
            const element = forms[0].fields[i].catagory_value;

            if (
               forms[0].fields[i].model === "lead_id"
            ) {
                this.catagory.splice(0, 0, element);
            } else if (!this.catagory.includes(element)) {
                this.catagory.push(element);
            }
        }

        let catagory_arrs = [...new Set(this.catagory)];
        this.catagory = [...new Set(this.catagory)]
        forms[0].fields[0].catagory_arr = catagory_arrs;
    },
    computed: {
        ...mapGetters(["forms", "themingVars","MaskingModules"]),
        cssProps() {
            // Return an object that will generate css properties key
            // to match with the themingVars
            //
            // Example output: { '--theme-primary-color': this.themingVars.primaryColor }
            var result = {
                height: "100%",
                margin: "10px",
            },
                themingVars = this.themingVars;

            for (var v in themingVars) {
                if (themingVars.hasOwnProperty(v)) {
                    var newV = "--theme-" + _.kebabCase(v),
                        suffix = "";

                    // Add px to the value if the default value contain 'px'
                    if (_.includes(newV, "size")) suffix = "px";
                    else if (_.includes(newV, "margin")) suffix = "px";
                    else if (_.includes(newV, "radius")) suffix = "px";

                    result[newV] = themingVars[v] + suffix;
                }
            }

            return result;
        },
    },
};
</script>
    
<style>
.titlecls:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #cacaca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.titlecls:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.preview_container {
    /* margin: 10px; */
    overflow: auto;
    min-height: 480px;
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
}

.preview_container1 {
    border: 1px solid #cacaca;
    display: flex;
    flex-direction: column;
    margin: 0px;
    overflow: auto;
    max-height: 490px;
    margin: 5px 10px 5px 18px;
    border-radius: 5px;
}

.component_flex {
    margin: 10px;
}

.component_setup {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    padding-right: 30px;
}

.sectionFlex {
    display: flex;
    /* background-color: #e9edf1; */
    /* margin: 10px; */
    border-radius: 10px;
    flex-direction: column;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
}

.sectionFlex1 {
    display: flex;
    flex-direction: column;
}

.catagory_title {
    display: flex;
    flex-direction: row;
    background-color: #e9edf1;
    padding: 10px 10px 10px 20px;
    font-size: 14px;
}

.edit-ellipsis {
    position: absolute;
    right: 42%;
    height: 80px;
    top: 55%;
}

.lds-ellipsis {
    position: fixed;
    left: 55%;
    bottom: 55%;
    right: 55%;
    height: 80px;
    top: 55%;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #2196f3;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

.edit-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #2196f3;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.edit-ellipsis div:nth-child(1) {
    left: 8px;
    animation: edit-ellipsis1 0.6s infinite;
}

.edit-ellipsis div:nth-child(2) {
    left: 8px;
    animation: edit-ellipsis2 0.6s infinite;
}

.edit-ellipsis div:nth-child(3) {
    left: 32px;
    animation: edit-ellipsis2 0.6s infinite;
}

.edit-ellipsis div:nth-child(4) {
    left: 56px;
    animation: edit-ellipsis3 0.6s infinite;
}

@keyframes edit-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes edit-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes edit-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}
</style>