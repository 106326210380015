<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs" :chatOptions="page.ChatOptions" :exportOptions="page.exportOptions"
      @openFilter="filterClick" @refreshPage="refreshThisPage" @ChatOptionData="chatSwitch"
      @exportData="exportCallDetailReport" :style="styleObject"></section-definition>

    <loader v-if="page.loading"> {{ "if" }}</loader>

    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="walogreport" class="elevation-1" :pagination.sync="pagination"
        :total-items="pagecount" :rows-per-page-items="[5, 10, 25]">
        <template v-slot:headers="props" id="table-scroll">
          <tr style="height: 30px">

            <th style="max-width: 150px; text-align: center" v-for="head in props.headers" :key="head.name">
              {{ head.text }}
            </th>
            <th style="text-align: center">Action </th>
          </tr>
        </template>


        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available for {{ page.default_title }}</div>
        </template>

        <template v-slot:items="props">
          <td class="checkEmpty p0" v-for="(header, index) in headers" :key="index">
            {{ header.masking ? ChartoMaskingChar(props.item[header.value]) : props.item[header.value] }}
          </td>
          <td class="checkEmpty p0">
            <v-icon v-show="page.canedit" @click="info(props.item.session_id, props.item.msg_from)" small
              class="mr-4">info</v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <label>{{ "Created At" }}</label>
            <div class="datetimefilter">
              <v-container class="pa-1">
                <v-menu v-model="page.menu.fromdate" :close-on-content-click="false" label="Created At" full-width
                  max-width="290" small>

                  <template v-slot:activator="{ on }">
                    <label>From</label>
                    <datetime class="theme-orange" color="primary" style="border-bottom: 1px solid rgb(145 145 145)"
                      type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="appliedfilter.fromdate"
                      :value="appliedfilter.fromdate" label="From Date" readonly v-on="on"
                      @change="page.menu.fromdate = false"></datetime>
                  </template>


                </v-menu>
              </v-container>

              <v-container class="pa-1">
                <v-menu v-model="page.menu.todate" :close-on-content-click="false" full-width max-width="290" small>

                  <template v-slot:activator="{ on }">
                    <label>To</label>
                    <datetime class="theme-orange" color="primary" style="border-bottom: 1px solid rgb(145 145 145)"
                      type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="appliedfilter.todate"
                      :value="appliedfilter.todate" label="To Date" readonly v-on="on"
                      @change="pagecontrol.menu.fromdate = false"></datetime>
                  </template>
                </v-menu>
              </v-container>
            </div>
          </div>

          <div class="autocompleteDiv">
            <v-text-field v-model="appliedfilter.didnumber" label="Didnumber"></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-text-field v-model="appliedfilter.session_id" label="Session ID"></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-text-field v-model="appliedfilter.callername" label="Callername"></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-text-field v-model="appliedfilter.phonenumber" label="Phonenumber"></v-text-field>
          </div>
          <v-autocomplete v-model="appliedfilter.campaign" :items="campaignfilterValues" label="campaign"
            persistent-hint small multiple single-line :allow-overflow="false">
          </v-autocomplete>
          <v-autocomplete v-model="appliedfilter.dispoStatus" :items="dispofilterValues" label="Dispo" persistent-hint
            small multiple single-line :allow-overflow="false">
          </v-autocomplete>
          <v-autocomplete v-model="appliedfilter.agent" :items="filteragents" label="Agent" persistent-hint small
            multiple single-line :allow-overflow="false">
          </v-autocomplete>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="fetchData()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>

  </div>
</template>

<script>
import moment from "moment";
import loader from "@/components/Widgets/loader";
import { MaskCharacter } from "@/utils/helpers";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },
  mounted() {
    let scope = this;
    scope.page.loading = false;
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:whatsappchatlog:export"
          ) !== -1
        ) {
          scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
        } else {
          scope.page.exportOptions = [];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:masking:invisible"
          ) === -1
        ) {
          scope.masking = true;
          scope.updateheader()
        }
        scope.page.ChatOptions = ["Whatsapp"];
        scope.filteragents = ['Unassign', ...JSON.parse(localStorage.getItem('agent_emails'))];
      }.bind(scope),
      1
    );
    this.fetchData();
    this.$store.dispatch("reportDispo").then(response => {
      let dispo = []
      if (response.status == 200) {
        if (response.data.data.length > 0) {
          response.data.data.forEach(element => {
            if (element.active == "Y") {
              dispo.push(element.dispo_id)

            }
          });
        }
      }
      this.dispofilterValues = dispo

    })
      .catch(err => {
        console.error("Error fetching dispo:", err);
      });

    this.$store.dispatch("GetActiveCampaigns")
      .then(res => {
        this.campaignfilterValues = res;
      })
      .catch(err => {
        console.error("Error fetching campaigns:", err);
      });
  },
  watch: {
    "appliedfilter.fromdate"(newVal) {
      var from_date = moment(newVal).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("ACTION_FROMDATE", from_date);
      this.$store.commit("FILTERFROMDATE", from_date);
      this.skip = 0;
    },
    "appliedfilter.todate"(newVal) {
      var to_date = moment(newVal).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("ACTION_TODATE", to_date);
      this.$store.commit("FILTER_TODATE", to_date);
      this.skip = 0;
    },
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      payload: {
        skip: 0,
        take: 0,
      },
      page: {
        title: "Whatsapp Chat Log",
        default_title: "whatsapp",
        icon: "fa fa-commenting fa-2x",
        loading: true,
        isFilterOpen: false,
        canedit: true,
        breadcrumbs: [],
        exportOptions: [],
        ChatOptions: [],
        options: { filter: true, refresh: true },
        menu: {
          fromdate: false,
          todate: false,
        },
      },
      pagination: {
        rowsPerPage: 10,
        // page: 1,
      },
      headers: [
        {
          text: "Session Id",
          align: "left",
          sortable: false,
          class: "p0",
          value: "session_id",
          masking: false
        },
        {
          text: "Session StartTime",
          align: "left",
          sortable: false,
          class: "p0",
          value: "created_at",
          masking: false
        },
        {
          text: "Did Number",
          align: "left",
          sortable: false,
          class: "p0",
          value: "did_number",
          masking: false
        },
        {
          text: "Campaign",
          align: "left",
          sortable: false,
          class: "p0",
          value: "campaign",
          masking: false
        },
        {
          text: "DispoStatus",
          align: "left",
          sortable: false,
          class: "p0",
          value: "dispoStatus",
          masking: false
        },
        {
          text: "Caller Name",
          align: "left",
          sortable: false,
          class: "p0",
          value: "name",
          masking: false
        },
        {
          text: "Phone Number",
          align: "left",
          sortable: false,
          class: "p0",
          value: "msg_from",
          masking: false
        },
        {
          text: "Agent",
          align: "left",
          sortable: false,
          class: "p0",
          value: "agent",
          masking: false
        },
        {
          text: "Session EndTime",
          align: "left",
          sortable: false,
          class: "p0",
          value: "modified_at",
          masking: false
        },
      ],
      skip: 0,
      take: 0,
      total: "",
      report: "",
      dispofilterValues: [],
      campaignfilterValues: [],
      exports: [],
      walogreport: [],
      response: [],
      pagecount: 0,
      currentcount: 0,
      excel_data: [],
      txt_data: [],
      from_date: "",
      to_date: "",
      dialog: false,
      filteragents: [],
      filterCampaigns: [],
      appliedfilter: {
        fromdate: "",
        todate: "",
        didnumber: "",
        campaign: "",
        dispoStatus: "",
        session_id: "",
        callername: "",
        phonenumber: "",
        agent: "",
      },
      Filename: "WaChatlogReport",
      masking: false
    };
  },

  methods: {
    datetimeformate(data) {
      let datetime = new Date(data);
      let dd =
        datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate();
      let mm =
        datetime.getMonth() + 1 < 10
          ? `0${datetime.getMonth() + 1}`
          : datetime.getMonth() + 1;
      let hr =
        datetime.getHours() < 10
          ? `0${datetime.getHours()}`
          : datetime.getHours();
      let min =
        datetime.getMinutes() < 10
          ? `0${datetime.getMinutes()}`
          : datetime.getMinutes();
      let sec =
        datetime.getSeconds() < 10
          ? `0${datetime.getSeconds()}`
          : datetime.getSeconds();
      return `${datetime.getFullYear()}-${mm}-${dd} ${hr}:${min}:${sec}`;
    },

    reset() {
      let scope = this;
      scope.appliedfilter.fromdate = "";
      scope.appliedfilter.todate = "";
      scope.appliedfilter.didnumber = "";
      scope.appliedfilter.campaign = "";
      scope.appliedfilter.dispoStatus = "";
      scope.appliedfilter.session_id = "";
      scope.appliedfilter.callername = "";
      scope.appliedfilter.phonenumber = "";
      scope.appliedfilter.agent = "";
      scope.fetchData();
      this.page.isFilterOpen = true;
    },

    filterClick() {
      this.page.isFilterOpen = true;
      this.filteragents = ['Unassign', ...JSON.parse(localStorage.getItem('agent_emails'))];
    },

    fetchData() {
      let take = this.pagination.rowsPerPage;
      this.page.isFilterOpen = false;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        console.log("else", this.pagination.page, skip);
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
      // filterQuery object
      const filterQuery = {
        skip,
        take,
        from: this.appliedfilter.fromdate !== "" ? this.datetimeformate(this.appliedfilter.fromdate) : "",
        to: this.appliedfilter.todate !== "" ? this.datetimeformate(this.appliedfilter.todate) : "",
        didnumber: this.appliedfilter.didnumber,
        campaign: this.appliedfilter.campaign,
        dispoStatus: this.appliedfilter.dispoStatus,
        callername: this.appliedfilter.callername,
        phonenumber: this.appliedfilter.phonenumber,
        agent: this.appliedfilter.agent,
        channel: this.page.default_title,
        session_id: this.appliedfilter.session_id,
      };
      this.skip = skip;
      console.log("filterQuery", filterQuery);
      this.$store
        .dispatch("GetsocialchatLog", filterQuery)
        .then((response) => {

          this.tableLoading = false;
          this.page.loading = false;
          var datas = response.data.data;
          this.walogreport = datas;
          this.pagecount = response.data.total_count;
          this.currentcount = response.data.current_count;

          if (datas.length === 0) {
            this.walogreport = [];
            this.pagecount = 0;
            this.currentcount = 0;
          }
        })
        .catch(function (error) {
          this.page.isFilterOpen = false;
          console.log("error", error);

        });
    },

    refreshThisPage() {
      var scope = this;
      // scope.pagination.page = 1;
      scope.page.loading = true;

      scope.appliedfilter.fromdate = "";
      scope.appliedfilter.todate = "";
      scope.appliedfilter.didnumber = "";
      scope.appliedfilter.campaign = "";
      scope.appliedfilter.dispoStatus = "";
      scope.appliedfilter.callername = "";
      scope.appliedfilter.phonenumber = "";
      scope.appliedfilter.agent = "";
      scope.appliedfilter.session_id = "";
      // scope.pagination.page = 1;
      scope.fetchData();
    },

    chatSwitch(option) {
      this.page.title = option + " Chat Log";
      this.page.default_title = option.toLowerCase();
      this.fetchData();
    },

    exportCallDetailReport: function (option) {
      console.log("optionoption", option)
      if (option == "CSV" || option == "Txt(Tab-Delimited)") {
        this.export(option);
      } else {
        this.dialog = true;
        this.export(option);
        // this.pdf_flag = true;
      }
    },
    export(option) {

      this.excel_data = [];
      this.txt_data = [];

      let export_date = {
        calldetail: "exportchatdetail",

      };
      let path = 'exportdatafetch'
      const hasEmptyValues = Object.keys(this.appliedfilter).some(key => this.appliedfilter[key] !== "");
      if (hasEmptyValues) {
        export_date = {
          from: this.appliedfilter.fromdate !== "" ? this.datetimeformate(this.appliedfilter.fromdate) : "",
          to: this.appliedfilter.todate !== "" ? this.datetimeformate(this.appliedfilter.todate) : "",
          didnumber: this.appliedfilter.didnumber,
          campaign: this.appliedfilter.campaign,
          dispoStatus: this.appliedfilter.dispoStatus,
          callername: this.appliedfilter.callername,
          phonenumber: this.appliedfilter.phonenumber,
          agent: this.appliedfilter.agent,
          channel: this.page.default_title,
          session_id: this.appliedfilter.session_id,
        };
        path = 'GetsocialchatLog'
      }
      this.$store.dispatch(path, export_date).then((response) => {
        console.log(response, "jjjjjjjjjjjjjjjjjjjjjj")
        if (response.data.data.length > 0) {
          this.exports = response.data.data;
        }else{
          this.key=["session_id",
            "created_at",
            "did_number",
            "campaign",
            "name",
            "msg_from",
            "agent",
            "modified_at",
            "dispoStatus"]
        }
          


          for (var i = 0; i < this.exports.length; i++) {
            if (this.masking) {
              this.MaskingModules.MaskingModules[this.Filename].forEach((elm) => {
                this.exports[i][elm] = this.ChartoMaskingChar(this.exports[i][elm])
              })
            }

            this.key = Object.keys(this.exports[i]);
            this.value = Object.values(this.exports[i]);
            var values = this.value;


            this.excel_data.push({
              header: values,
            });
            this.txt_data.push(Object.values(this.exports[i]));
          }
          console.log(this.key, "yyyyyyyyyyyyyyyyyyyyyyyyyyy")
          if (option == "Txt(Tab-Delimited)") {
            let txtContent = "";
            let txt_key = this.key;
            let txt_tab = txt_key.join("\t");
            console.log("rowrowrowrowrow", txt_tab)
            txtContent += `${txt_tab} \r\n`; this.txt_data.forEach(function (rows) {
              let data = Object.values(rows);
              let row = data.join("\t");

              txtContent += row + "\r\n";
            });
            var date1 = new Date();
            date1 = moment(date1).format("YYYY-MM-DD hh-mm");
            var encodedUri1 =
              "data:text/plain;charset=utf-8,\uFEFF" +
              encodeURIComponent(txtContent);

            var link1 = document.createElement("a");
            link1.setAttribute("href", encodedUri1);
            link1.setAttribute("download", "Wachatreport" + date1 + ".txt");
            document.body.appendChild(link1);
            link1.click();
          }
          if (option == "CSV") {
            let csvContent = '';
            csvContent += `${this.key} \r\n`;
            this.excel_data.forEach(function (rows) {
              let data = Object.values(rows);
              let data1 = [];
              data.forEach((elm) => {
                if (typeof elm === "string") {
                  data1.push(elm.replace(/'|"|#|\n/g, " "));
                } else {
                  data1.push(elm);
                }
              });
              for (let i = 0; i < data1[0].length; i++) {
                if (
                  data1[0][i] != null &&
                  data1[0][i] != undefined &&
                  data1[0][i] != "" && typeof data1[0][i] == "string"
                ) {
                  if (data1[0][i].includes(",")) {
                    data1[0][i] = data1[0][i].replaceAll(",", ";");
                  }
                }
              }
              let row = data1.join(",");
              csvContent += row + "\r\n";
            });
            var date = new Date();
            date = moment(date).format("YYYY-MM-DD hh-mm");
            const encodedUri =
              "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "Wachatreport" + date + ".csv");
            document.body.appendChild(link); // Required for FF
            link.click();
          }
        
        });
    },

    info(id, message_from) {
      let obj = {};
      obj.session_id = id;
      obj.message_from = message_from
      localStorage.setItem("chatdetailview", JSON.stringify(obj));
      this.$router.push({
        name: "report/WapReportDetail",
      });
    },
    ChartoMaskingChar(data) {
      let scope = this
      if (!scope.$store.getters.MaskType.includes(typeof (data))) {
        return data
      }
      return MaskCharacter(data)
    },
    updateheader() {
      let scope = this
      scope.headers.forEach((elm) => {
        elm.masking = scope.masking && elm.value == scope.MaskingModules.MaskingModules[scope.Filename].find(tlm => tlm == elm.value)
      })
    }
  },
  computed: {
    ...mapGetters(["MaskingModules"]),
  },
};
</script>