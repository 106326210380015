<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs" :exportOptions="page.exportOptions" @openFilter="filterClick"
      @refreshPage="refreshThisPage" @addNew="NewAudio" :addNewData="page.addnew" :style="styleObject">
    </section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="AudioData" class="elevation-1 fixed-layout" :pagination.sync="pagination"
        :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.id }}</td>
          <td class="checkEmpty p0">{{ props.item.file_name }}</td>
          <td class="checkEmpty p0">{{ props.item.description }}</td>
          <td class="checkEmpty p0">{{ props.item.status }}</td>
          <td v-if="props.item.url !== ''" class="checkEmpty p0">
            <audio id="myAudio">
              <source id="myAudioplay" src="" type="audio/mpeg">
            </audio>
            <v-icon v-if="props.item.music" color="green" small class="mr-4" @click="playAudio(props.item.id, 'play')">
              far fa-play-circle
            </v-icon>
            <v-icon v-else color="red" small class="mr-4" v-model="props.item.music"
              @click="playAudio(props.item.id, 'stop')">
              far fa-stop-circle
            </v-icon>
            <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">
              edit
            </v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item.id)">
              delete
            </v-icon>
          </td>
          <td v-else class="checkEmpty p0">
            <v-icon color="red" small class="mr-4">
              fas fa-circle-notch fa-spin
            </v-icon>
            <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">
              edit
            </v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item.id)">
              delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Audio</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are your sure to delete this Audio?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isEditAudio" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Update Status</span>
          </v-card-title>
        </v-toolbar>
        <v-form v-model="valid">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-select v-model="editAudio.status" required :items="options" label="Status"></v-select>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="editAudio.description" required label="Description" type="text"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closeEditAudio()">Close</v-btn>
          <v-btn color="primary" :disabled=!valid @click="saveEditAudio()">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isUploadAudio" max-width="500px">
      <v-card>
        <v-form ref="AudioUpdate">
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Upload Audio</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-flex xs12>
              <v-text-field v-model="payload.description" required label="Description" type="text"></v-text-field>
            </v-flex>
            <!-- <v-flex xs12>
              <v-select v-model="payload.status" :items="options" label="Status" item-text="status" item-value="active"
                return-string persistent-hint></v-select>
            </v-flex> -->
            <div class="file-dummy" @click="$refs.file.click()" :style="{
      background: uploadAudio.color + '66',
      'border-color': uploadAudio.color,
      color: uploadAudio.color,
    }">
              {{ uploadAudio.label }}
            </div>
            <input v-show="false" type="file" ref="file" accept=".mp3,audio/*"
              v-on:change="handleFileUpload(payload)" />
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="CloseUploadAudio(false)">Close</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-autocomplete v-model="payload.file_name" :items="filtervalue" :loading="filter.isLoading"
              :search-input.sync="filter.search" label="Name" hide-no-data hide-selected></v-autocomplete>
            <v-select v-model="payload.status" :items="options" label="Status" small></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import api from "@/utils/axios-util";
export default {
  components: {
    loader,
  },
  data() {
    return {
      count_val: 0,
      count_id: 0,
      valid: true,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      music: false,
      audioSrc: "",
      tenant_code: "",
      page: {
        title: "Audio Store",
        icon: "fa-file-audio fa-2x",
        loading: false,
        isFilterOpen: false,
        breadcrumbs: [],
        isUploadAudio: false,
        exportOptions: [],
        options: { filter: true, refresh: true, },
        isEditAudio: false,
        candelete: false,
        canedit: false,
      },
      uploadAudio: {
        label: "Please select Mp3 files  ",
        color: "#2196f3",
      },
      pagecount: 0,
      headers: [
        {
          text: "Id",
          align: "left",
          class: "p0",
          width: "10%",
          sortable: false,
          value: "id",
        },
        {
          text: "Name",
          align: "left",
          class: "p0",
          width: "35%",
          sortable: false,
          value: "file_name",
        },
        {
          text: "Description",
          align: "left",
          class: "p0",
          width: "25%",
          sortable: false,
          value: "description",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          width: "15%",
          sortable: false,
          value: "active",
        },
        {
          text: "Action",
          align: "center",
          class: "p0",
          width: "15%",
          sortable: false,
          value: "btn",
        },
      ],
      AudioData: [],
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      mainData: [],
      filter: {
        id: "",
        isLoading: false,
        search: "",
      },
      payload: {
        description: "",
        status: "",
        file_name: ""
      },
      options: ["Active", "Inactive"],
      isProcessing: false,
      call_status: [],
      editAudio: {
        id: "",
        status: "",
        description: "",
      },
    };
  },
  methods: {

    playAudio(val, event) {
      if (event === 'play') {
        if (this.count_val > 0) {
          this.pauseAudio(this.count_id);
          this.$store.state.AudioStore.Music.pause();
          this.$store.state.AudioStore.Music.currentTime = 0;
        }

        this.AudioData.forEach(res => {
          if (res.id == val) {
            this.musicUrl = res.url;
            this.$store.dispatch("setAudio", res.url)
            res.music = false;
          }
        })
      }

      if (event === 'play') {
        this.$store.state.AudioStore.Music.play();
        this.count_val = 1;
        this.count_id = val;
      } else if (event === 'stop') {
        this.pauseAudio(val)
        this.$store.state.AudioStore.Music.pause();
        this.$store.state.AudioStore.Music.currentTime = 0;
      }
    },
    pauseAudio(val) {
      this.AudioData.forEach(res => {
        if (res.id == val) {
          res.music = true;
        }
      })
    },
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.payload.status = "";
      scope.payload.file_name = "";
      scope.pagination.page = 1;
      scope.getDataFromApi();
    },
    filterClick: function () {
      let scope = this
      scope.page.isFilterOpen = true;

    },
    filterFieldOptions() {
      var scope = this;
      let data = { model: "audio_store", column: ["file_name"] }

      scope.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {

          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          var len = this.tenant_code.length;
          datas[0].file_name.map((element) => {
            scope.filtervalue.push(element.substring(len + 1))
          })


        })
        .catch(err => {
          console.log(err)
        })
    },

    reset() {
      var scope = this;
      scope.payload.status = "";
      scope.payload.file_name = "";
      this.getDataFromApi()
      this.page.isFilterOpen = true;
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;

      let take = this.pagination.rowsPerPage
      let skip
      if (this.pagination.page === 1) {
        skip = 0
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1)
      }
      let query = {
        skip: skip,
        take: take,
        name: this.payload.file_name ? `${this.tenant_code}_${this.payload.file_name}` : "",
        status: this.payload.status ? this.payload.status : ""
      }

      this.skip = skip

      this.$store
        .dispatch("GetAudio", query)
        .then((response) => {
          this.pagecount = response.count
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data.records;
          if (datas !== "" && datas !== undefined && datas !== null) {
            var len = this.tenant_code.length;
            datas.map((element) => {
              // scope.filtervalue.push(element["file_name"].substring(len + 1));
              element["file_name"] = element["file_name"].substring(len + 1);
              element['music'] = true;
              return element;
            });
          }
          scope.mainData = datas
          scope.AudioData = datas;
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
          this.page.isEditAudio = false;
        });
    },
    closeEditAudio: function () {
      this.page.isEditAudio = false;
    },
    saveEditAudio() {
      var scope = this;
      scope.$store
        .dispatch("updateAudio", scope.editAudio)
        .then(() => {
          scope.showSnackBar("Edited Successfully");
          scope.page.isEditAudio = false;
          scope.getDataFromApi();
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
          scope.page.isEditAudio = false;
        });
      scope.reset()
      scope.getDataFromApi();

    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    // playAudio(audio) {
    // // this.audioSrc = audio;
    // // this.music = true;
    // let audios = new Audio(audio);
    // audios.play();
    // },
    editItem(item) {
      let scope = this;
      scope.page.isEditAudio = true;
      scope.editAudio.id = item.id;
      scope.editAudio.status = item.status;
      scope.editAudio.description = item.description;
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteAudio", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("Audio Deleted");
          } else {
            scope.showSnackBar("Error! Unable Delete Audio");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete Audio");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
    NewAudio: function (aud) {
      if (aud.key === "upload") {
        this.page.isUploadAudio = true;
      }
    },
    CloseUploadAudio: function () {
      this.page.isUploadAudio = false;
      this.getDataFromApi();
      this.$refs.AudioUpdate.reset();
      this.uploadAudio = {
        label: "Please select mp3 files",
        color: "#2196f3",
      };
    },
    handleFileUpload: function (payload) {
      var scope = this;
      scope.file = scope.$refs.file.files[0];
      if (scope.file.size > 5 * 1024 * 1024) {
        scope.uploadAudio.color = "#F51B00";
        scope.uploadAudio.label = "File size exceeds the limit (5 MB)";
        scope.$refs.file.value = "";
        return;
      }
      else if(scope.file.name.length > 30){
        scope.uploadAudio.color = "#F51B00";
        scope.uploadAudio.label = "File name must be 30 characters or less.";
        scope.$refs.file.value = "";
        return;
      }
      scope.uploadAudio.color = "#A64452";
      scope.uploadAudio.label = "Uploading";
      let formData = new FormData();
      formData.append("file_name", scope.file);
      console.log(scope.file, "ssdsds")
      formData.append("description", payload.description || '');
      let status = payload.status ? payload.status : "Active"
      formData.append("status", status)
      api.defaults.headers["Content-Type"] = "multipart/form-data";

      api
        .post("/audio", formData)
        .then((response) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.uploadAudio.color = "#4BB543";
          scope.uploadAudio.label = "Uploaded Successfully";
          scope.$refs.file.value = "";
          return response;
        }).catch((error) => {
          const statusCode = error.response.data.statusCode
          if (statusCode == 409) {
            api.defaults.headers["Content-Type"] = "application/json";
            scope.uploadAudio.color = "#F51B00";
            scope.uploadAudio.label = "File Already Exists";
            scope.$refs.file.value = "";
            scope.$refs.file.clearAll();
          }
          else {
            api.defaults.headers["Content-Type"] = "application/json";
            scope.uploadAudio.color = "#F51B00";
            scope.uploadAudio.label = "Upload Error";
            scope.$refs.file.value = "";
            scope.$refs.file.clearAll();
          }
          return error;
        });


    },
  },
  mounted() {
    let scope = this;
    this.tenant_code = localStorage.getItem("TenantCode");
    scope.page.loading = false;
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:audiostore:upload"
      ) !== -1
    ) {
      scope.page.addnew = [{ key: "upload", label: "Upload" }];
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:audiostore:delete"
      ) !== -1
    ) {
      scope.page.candelete = true;
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:audiostore:edit"
      ) !== -1
    ) {
      scope.page.canedit = true;
    }
    const project_config = JSON.parse(localStorage.projectConfig);
    scope.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.AudioData
    this.filterFieldOptions();
    scope.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    'filter.search'(val) {
      if (this.page.isFilterOpen && val !== null) {
        let scope = this

        let data = { model: "audio_store", column: ["file_name"] }

        data["search"] = val

        scope.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            console.log(response, "fdfdfg")
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            var len = this.tenant_code.length;
            datas[0].file_name.map((element) => {
              scope.filtervalue.push(element.substring(len + 1))
            })
            // scope.filtervalue.push(element["file_name"].substring(len + 1));
            //  scope.filtervalue=datas[0].subdispo_id        

          })
          .catch(err => {
            console.log(err)
          })
      }
    },
  }
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>