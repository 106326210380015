<template>
    <div class="TextInputmain">
        <v-text-field :class="[currentField.required?required_class:currentField.readonly?read_only:others]" :readonly="currentField.clearable" :disabled='attributesBinding.disabled' :placeholder="`${currentField.isPlaceholderVisible ? attributesBinding.placeholder : '' }`" v-model="input_value"
            :label="`${currentField.label}`" 
            :rules="required_rule" 
            :type="input">
        </v-text-field>
    </div>
</template>

<script>
export default {
    name: 'TextInput',
    props: ['currentField','Allfields','MaskingModules','ChartoMaskingChar','Filename','masking'],
    data() {
        return {
            input_value:"",
            input: '',
             required_class:"required",
             others:"others",
             read_only:"readonly",
             required_rule:[],
             mask:false
        }
    },
    watch: {
        input_value: function(newVal) {
            let scope = this
            if(!scope.mask){
                scope.currentField.value.value = newVal;
            }
        }
    },
    destroyed() {
        this.currentField.value.value = '';
    },
    computed: {
        attributesBinding() {
            var attr = {};
            attr['id'] = this.currentField.model;
            if (this.currentField.showWordLimit) {
                attr.maxlength = this.currentField.maxlength;
                attr['show-word-limit'] = this.currentField.showWordLimit
            }
            if (this.currentField.clearable) {
                attr.clearable = this.currentField.clearable;
            }
            if (this.currentField.disabled) {
                attr.disabled = this.currentField.disabled;
            }
            if (this.currentField.showPassword) {
                attr['show-password'] = this.currentField.showPassword;
            }
            if (this.currentField.isPlaceholderVisible) {
                attr.placeholder = this.currentField.placeholder;
            }
            return attr;
        },
    },
    mounted() {
        let scope = this
        if(this.currentField.required == true){
            this.required_rule = [ v => !!v || `${this.currentField.label} is required`]
            if (this.currentField.model === 'phone_number') {
                let requiredvalue = [v => v.includes('X') || /^\d+$/.test(v) || `${this.currentField.label} must contain only numbers`]
                this.required_rule.push(requiredvalue);
            }
            if(this.currentField.model_type == "SmallNumber" ){
                this.required_rule.concat([v => String(v).length > 32767 || "The value must be an less then 32767"])
            }
            // this.required_rule.push(v => !!v || `${this.currentField.label} is required`)
        } 
        if(scope.masking && scope.currentField.model == scope.MaskingModules[scope.Filename].find(elm => elm == scope.currentField.model) && scope.currentField.value.value != ""){
            scope.mask = true
            scope.input="text" 
            scope.input_value = scope.ChartoMaskingChar(scope.currentField.value.value)
            scope.currentField.clearable = true
        }else{
            scope.mask = false
            scope.input_value = scope.currentField.value.value
            if(this.currentField.model_type == "BigNumber" ||  this.currentField.model_type == "SmallNumber" ||   this.currentField.model_type == "Number"){
                this.input="number"
                if(this.currentField.model_type == "SmallNumber" ){
                    this.required_rule=[v => Number(v) <= 32767 || "The value type is SmallNumber must be an less then 32767"]
                }
            }else{
                this.input="text" 
            }
        }

            // Check for phone_number model
            if (this.currentField.model === 'phone_number') {
                this.input = 'tel'; // Set input type to 'tel' for numeric input
                this.required_rule.push(v => /^\d+$/.test(v) || "Only numbers are allowed"); // Allow only digits
                this.input_value = this.currentField.value.value;
            } else {
                this.input = this.currentField.model_type === "BigNumber" || this.currentField.model_type === "SmallNumber" || this.currentField.model_type === "Number" ? "number" : "text";
                this.input_value = this.currentField.value.value;
            }
    },
}
</script>

<style>
.TextInput {
    margin: 20px 10px 10px 10px;
}

.TextInputmain {
    display: flex;
    padding: 10px;
    height: 85px;
}
.required:after {
    content: " *";
    color: rgb(255, 0, 0);
    font-weight: 600;
    padding-left: 2px;
}

.readonly:after {
    content: " *";
    color: rgb(4, 0, 255);
    font-weight: 600;
    padding-left: 2px;
}

.others:after {
    content: "";
    font-weight: 600;
    padding-left: 2px;
}
</style>
