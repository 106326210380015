<template>
    <div class="main-view-cls">
        <div class="main_container_grid">
            <div class="grid1">
                <div class="header_cls">
                    <div class="profil_pic">
                        <div class="profil_txt">
                            <v-icon class="comment-icon">fa fa-commenting fa-2x</v-icon>
                        </div>
                        <div class="message-from">
                            #{{ walogreport.length > 0 ?  ChartoMaskingChar(walogreport[0].msg_from)  :   ChartoMaskingChar(this.messageFrom) }}
                        </div>
                    </div>

                    <div class="icon_flex">
                        <div>
                            <v-btn class="toolbar-btn" icon @click="download_()">
                                <span>Download</span>
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                            <v-btn class="toolbar-btn" icon @click="goBack()">
                                <span style="margin-left: 3px;">Back</span>
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-btn>
                        </div>
                    </div>

                </div>
            </div>
            <div class="grid2">
                <div class="titlecls_timeline_div">
                    <div class="titlecls_timeline_history_bottom">
                        <div class="detail-view">
                            <div class="info-header">Info</div>
                            <div v-if="walogreport && walogreport.length > 0">
                                <div class="info-item">Session Id: {{ walogreport[0].session_id }}</div>
                                <div class="info-item">Did Number: {{ walogreport[0].msg_to }}</div>
                                <div class="info-item">Agent: {{ walogreport[0].agent }}</div>
                            </div>
                            <div v-else class="no-data-message">No Info Available</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid3">
                <div>
                    <v-card>
                        <v-toolbar dark color="primary">
                            <v-card-title>

                                <span class="headline">{{ walogreport.length > 0 ? `${walogreport[0].channel}
                                    Conversation` : 'Chat Conversation' }}</span>
                            </v-card-title>
                        </v-toolbar>
                        <v-container grid-list-md>
                            <div v-if="walogreport.length > 0">
                                <div v-for="(data, i) in walogreport" :key="i" class="chatdiv">
                                    <div v-if="data.msg_direction === `in`">
                                        <div class="attachment">
                                            <div v-if="data.attachment === `image`">
                                                <img :src="data.msg_text" class="small-image" alt="Image">
                                            </div>
                                            <div v-else-if="data.attachment === `audio`">
                                                <audio controls>
                                                    <source :src="data.msg_text" type="audio/mp3">
                                                </audio>
                                            </div>
                                            <div v-else-if="data.attachment === `video`">
                                                <video controls>
                                                    <source src="attachment" type="video/mp4">
                                                </video>
                                            </div>
                                            <div v-else>
                                                <div class="message-container">
                                                    <div class="message-content">
                                                        {{ data.msg_text }}
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <span v-on="on" class="agent-icon">
                                                                    <v-icon small>mdi-account-circle</v-icon>
                                                                </span>
                                                            </template>
                                                            <span>{{ data.agent }}</span>
                                                        </v-tooltip>
                                                    </div>
                                                    <div class="message-time">{{ data.date_time }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="chat-request">
                                        <div v-if="data.attachment === `image`">
                                            <img :src="data.msg_text" class="small-image" alt="Image">
                                        </div>
                                        <div v-else-if="data.attachment === `audio`">
                                            <audio controls>
                                                <source :src="data.msg_text" type="audio/mp3">
                                            </audio>
                                        </div>
                                        <div v-else-if="data.attachment === `video`">
                                            <video controls>
                                                <source src="attachment" type="video/mp4">
                                            </video>
                                        </div>
                                        <div v-else>
                                            <div class="message-container">
                                                <div class="message-content">
                                                    {{ data.msg_text }}

                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <span v-on="on" class="agent-icon">
                                                                <v-icon small>mdi-account-circle</v-icon>
                                                            </span>
                                                        </template>
                                                        <span>{{ data.agent }}</span>
                                                    </v-tooltip>
                                                </div>
                                                <div class="message-time">{{ data.date_time }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="no-data-message">
                                No Data Available
                            </div>
                        </v-container>
                    </v-card>
                </div>
            </div>
            <v-dialog persistent v-model="DownloadFile" scrollable max-width="470px">
                <v-card>
                    <v-toolbar dark color="primary">
                        <div class="pop_title">
                            <div>
                                <div style="font-size:large;font-weight: 600;">Download </div>
                            </div>
                            <div style="padding-top: 3px;">
                                <v-icon color="light" flat style="font-size:20px"
                                    @click="closedownload">fa-times</v-icon>
                            </div>
                        </div>
                    </v-toolbar>
                    <v-card-text>
                        <v-container grid-list-md>
                            <div>

                                <div class="exportflex">
                                    <div class="export" @click="filedownload('csv')">

                                        <v-icon class="hovericon" size="60">fa fa-file-code-o
                                        </v-icon>
                                        <div style="font-size:20px;color:#2C3E50;font-weight:800;padding:5px">CSV
                                        </div>
                                    </div>
                                    <div class="export" @click="filedownload('txt')">
                                        <v-icon class="hovericon" size="60">fa-file-text
                                        </v-icon>
                                        <div style="font-size:20px;color:#2C3E50;font-weight:800;padding:5px">TXT
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="model-footer--sticky">
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>


    </div>
</template>

<script>
import moment from "moment";
import { MaskCharacter } from "@/utils/helpers";
import { mapGetters } from "vuex";
export default {
    data: () => ({
        walogreport: [],
        messageFrom: "",
        isProcessing: true,
        attachment: "",
        DownloadFile: false,
        pagecontrol: {
            export: false,
        },
        Filename:"WaChatlogDetailsReport",
        masking:false
    }),
    mounted() {
        this.getDataFromApi();
        let scope = this;

        setTimeout(
            function () {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "reports:whatsappdetail:export"
                    ) !== -1
                ) {
                    scope.pagecontrol.export = true;
                }
                if (
                scope.$store.getters.UserPermissions.indexOf(
                    "configurations:masking:invisible"
                ) === -1
                ) {
                scope.masking = true;
                }

            }.bind(scope),
            1
        );

    },

    methods: {
        download_() {
            this.DownloadFile = true;
        },
        closedownload() {
            this.DownloadFile = false;
        },
        filedownload(value) {
            let scope = this
            let skipclumn = ['question', 'value', 'deleted', 'sender_type', 'channel', 'custom_value', 'attachment']
            let download_data = JSON.parse(JSON.stringify(this.walogreport))
            if (value == 'csv') {
                let csv = ''

                let wachtlog = download_data.map((elm) => {
                    skipclumn.forEach((e) => {
                        delete elm[e]
                    })

                    return elm
                })
                for (let row = 0; row < wachtlog.length; row++) {
                    if(scope.masking){
                        scope.MaskingModules.MaskingModules[this.Filename].forEach((elem)=>{
                            if(typeof elem === 'object' && elem.condition.bool && scope.conditoncheck(elem.condition,wachtlog[row])){
                                wachtlog[row][elem.name] =  scope.ChartoMaskingChar(wachtlog[row][elem.name])
                            }else if(typeof elem === 'string'){
                                wachtlog[row][elem] =  scope.ChartoMaskingChar(wachtlog[row][elem])
                            }
                        })
                    }
                    let keysAmount = Object.keys(wachtlog[row]).length
                    let keysCounter = 0
                    if (row === 0) {
                        for (let key in wachtlog[row]) {
                            csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                            keysCounter++
                        } keysCounter = 0;
                        for (let key in wachtlog[row]) {
                            if(typeof(wachtlog[row][key]) === "string" && wachtlog[row][key].includes(",")){
                                wachtlog[row][key] = `"${wachtlog[row][key]}"`
                            }
                            csv += wachtlog[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                            keysCounter++
                        }
                    } else {

                        for (let key in wachtlog[row]) {
                            if(typeof(wachtlog[row][key]) === "string" && wachtlog[row][key].includes(",")){
                                wachtlog[row][key] = `"${wachtlog[row][key]}"`
                            }
                            // if(!skipclumn.includes(key)){
                            csv += wachtlog[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                            keysCounter++
                            // }
                        }
                    }

                    keysCounter = 0
                }


                let link = document.createElement('a')
                link.id = 'download-csv'
                link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
                link.setAttribute("download", download_data[0].session_id + ".csv");

                document.body.appendChild(link)
                link.click();
                this.DownloadFile = false;

            }
            else if (value == "txt") {
                let txtContent = "";
                for (const key in download_data[0]) {
                    if (!skipclumn.includes(key)) {
                        let txt_tab = `${key} \t`;
                        txtContent += `${txt_tab}`;
                    }
                }
                download_data.forEach(function (rows) {
                    if(scope.masking){
                        scope.MaskingModules.MaskingModules[scope.Filename].forEach((elem)=>{
                            rows[elem] =  scope.ChartoMaskingChar(rows[elem])
                        })
                    }
                    skipclumn.forEach((elm) => {
                        delete rows[elm]
                    })
                    let data = Object.values(rows);
                    let row = data.join("\t");
                    txtContent += `\n ${row}` + "\r"; 
                });
                var encodedUri1 =
                    "data:text/plain;charset=utf-8,\uFEFF" +
                    encodeURIComponent(txtContent);
                var link1 = document.createElement("a");
                link1.setAttribute("href", encodedUri1);
                link1.setAttribute("download", `s.txt`);                link1.setAttribute("download", this.walogreport[0].session_id + ".txt");
                document.body.appendChild(link1);
                link1.click();
                this.DownloadFile = false;
            }

        },
        goBack() {
            this.$router.go(-1);
        },

        getDataFromApi() {
            let obj = JSON.parse(localStorage.chatdetailview);
             this.messageFrom=obj.message_from
            let payload = {
                session_id: obj.session_id,

            }

            var scope = this;
            this.$store
                .dispatch("Getchatdetail", payload)
                .then((response) => {
                    var datas = response.data.data;
                    scope.walogreport = datas;
                    for (let j = 0; j < this.walogreport.length; j++) {
                        let element1 = this.walogreport[j];
                        let dateformat = element1.date_time
                        var from_date = moment(dateformat).format('YYYY-MM-DD HH:mm:ss');
                        this.walogreport[j].date_time = from_date
                        this.attachment = this.walogreport[j].msg_text
                    }
                    return this.walogreport
                })
                .catch(function (error) {
                    console.log(error);
                    scope.page.isAddPausecode = false;
                    this.page.isEditPausecode = false;
                });
        },
        ChartoMaskingChar(data){
            if(this.masking){
                let scope = this
                if(!scope.$store.getters.MaskType.includes(typeof(data))){
                    return data
                }
                return MaskCharacter(data)
            }
            return data
        },
        conditoncheck(data,obj){
            let { props_name , props_operate ,  props_value} = data
            switch (props_operate) {
                case '===':
                    return obj[props_name] === props_value;
                case '!==':
                    return obj[props_name] !== props_value;
                case '>':
                    return obj[props_name] > props_value;
                case '<':
                    return obj[props_name] < props_value;
                case '>=':
                    return obj[props_name] >= props_value;
                case '<=':
                    return obj[props_name] <= props_value;
                default:
                    console.error('Unsupported operation');
                    return false;
            }
        }
    },
    computed: {
        ...mapGetters(["MaskingModules"])
    }

}

</script>


<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&display=swap");

.main-view-cls {
    position: relative;
}

.no-data-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-size: 20px;
    color: #999;
}

/*********************************************** Download ************************************** */
.pop_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.exportflex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 20px;

}

.export {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 40%;
    height: 130px;
    text-align: center;
    padding: 20px;
    background-color: #cacaca;
    color: #ffffff;
    border-radius: 10px;
}

/********************************************** chatconversation******************************* */
.chat-response {
    background-color: rgb(11, 153, 241);
    padding: 10px;
    padding: 10px;
    max-width: 300px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: rgba(213, 215, 222, 0.27) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
    word-wrap: break-word;
    margin: 7px;
    font-size: 12px;
    margin-right: 500px;

}

.attachment {
    background-color: #d7ebea;
    padding: 10px;
    padding: 10px;
    max-width: 315px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: rgba(213, 215, 222, 0.27) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
    word-wrap: break-word;
    margin: 7px;
    font-size: 12px;
    margin-right: 500px;

}

.chat-request {
    background-color: #d7ebea;
    padding: 10px;
    padding: 10px;
    max-width: 315px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
    word-wrap: break-word;
    margin: 7px;
    color: black;
    font-size: 12px;
    margin-left: 500px;


}

.chatdiv {
    display: flex;
    flex-direction: column;

}

.cqCDVm {
    text-align: right;
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(17, 17, 17, 0.5);
    margin-right: -8px;
    margin-bottom: -4px;
}

.small-image {
    width: 200px;
    height: auto;
}

/**********************************************detaill views  ***********************************/
.detail-view {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/*********************************************************************************************** */

.grid1 {
    grid-area: header;
}

.grid2 {
    grid-area: menu;
    height: 500px;

}

.grid3 {
    grid-area: main;
    height: 500px;
    overflow: auto;
    /* width: 920px;  */
}

.timeline {
    min-width: 240px;
    height: 500px;
    /* padding: 5px; */
    overflow: auto;
}

.header_cls {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.profil_pic {
    display: flex;
    align-items: center;
}

.profil_txt {
    margin-right: 10px; 
}

.comment-icon {
    font-size: 24px;
    margin-bottom:6px ;
    color:#fff

}

.message-from {
    font-size: 16px;
    color: #424242; 
}
.main_container_grid {
    display: grid;
    grid-template-areas:
        "header header header header header header"
        "menu main main main main main";
    gap: 10px;
    padding: 10px;
}

.profil_txt {
    width: 48px;
    height: 48px;
    background-color: rgb(33, 150, 243);
    font-family: "Roboto Slab", serif;
    font-size: 30px;
    color: #ffffff !important;
    text-align: center;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%),
        0 13px 24px -11px rgb(33 150 243 / 60%);
}
.icon_flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 5px 5px 5px;
}

.toolbar-btn {
  height: 36px;
  background-color: #2196f3; 
  color: #fff; 
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 95px;
}


/***************************************grid*************************************************************/

.info-container {
    display: flex;
    padding: 10px;
    align-items: center;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.info-header {
    color: #1976D2;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.info-item {
    color: #333;
    font-size: 14px;
    margin-bottom: 5px;
}

.titlecls_timeline_div {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.titlecls_timeline_history_bottom {
    height: calc(100% - 50px);
    overflow: auto;
}

/***************************************message container*************************************************************/
.message-container {
    position: relative;
}

.message-content {
    display: inline-block;
    padding: 10px;
    background-color: #F0F0F0;
    border-radius: 10px;
    max-width: 70%;
}

.message-time {
    font-size: 12px;
    color: #999999;
    margin-top: 5px;
}

.agent-icon {
    position: absolute;
    bottom: -5px;
    right: -5px;
}

.chat-right .message-container {
    text-align: right;
}

.chat-right .message-content {
    background-color: #DCF8C6;
}

.chat-left .message-content {
    background-color: #E8E8E8;
}
</style>
