<template>
  <div>
    <section-definition :title="page.title" :style="styleObject" :icon="page.icon"
      :dashboard_section="dashboard_section" :activezone="activezone" @views="viewsfunction">
    </section-definition>
    <iframe v-if="iframeUrl" :src="iframeUrl" width="100%" height="600px" style="margin-top: 10px"></iframe>
    <div v-else class="no-data-container">
      <div>Dashboard Not Found</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-10px",
      },
      dashboard_section: [],
      activezone: {
        required: false,
        default: "",

      },
      page: {
        title: "Dashboard",
        icon: "fa-bullhorn fa-2x",
      },
      showIframe: false,
      iframeUrl: '',
      toggleButtonReslivecall: null,
    };
  },

  mounted() {


    this.fetchDashboardData();
  },

  methods: {
    fetchDashboardData() {
      const query = { role: "admin" };
      this.$store.dispatch("rect/zohodashboard", query)
        .then((response) => {
          if (response.data.length > 0) {
            this.dashboard_section = response.data.map(view => ({
              viewId: view.viewId,
              viewName: view.viewName,

            }));


            this.activezone.default = response.data[0].viewId;


            this.viewsfunction(this.activezone.default);
          }
        })
        .catch((err) => {
          console.error("Error fetching data:", err);
        });

    },


    viewsfunction(viewId) {
      this.activezone = viewId;
      this.$store.dispatch('rect/zohodashboardurl', viewId)
        .then(response => {
          this.iframeUrl = response.data.embedUrl;
          this.showIframe = true;

          console.log(response.data, "view functoionsssssssssss");

        })
        .catch(error => {

          console.error('Failed to fetch view URL:', error);
        });
    }
  },

  isActiveZone(viewId) {
    return this.activezone.includes(viewId);
  }
}

</script>

<style>
.no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
  font-size: 20px;
  color: #4f4d4d;
}
</style>
