<template>
    <div>
        <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :breadcrumbs="page.breadcrumbs" :exportOptions="page.exportOptions" @openFilter="openCallLogFilter" @refreshPage="refreshCallLogPage" @exportData="exportCallDetailReport"
            :style="styleObject"></section-definition>
        <loader v-if="page.loading"></loader>
        <v-container v-else fluid grid-list-xl page id="table">
            <v-data-table :headers="headers" :items="effectiveabandon_data" class="elevation-1" :pagination.sync="pagination" :total-items="calldetailtotal" :rows-per-page-items="[5, 10, 25]">
                <template v-slot:items="props">
              <td class="checkEmpty p0" v-for="(header, index) in headers" :key="index">
                {{ header.masking ? ChartoMaskingChar(props.item[header.value]) : props.item[header.value] }}
              </td>
</template>
      </v-data-table>
    </v-container>
    <!-- <div>
      <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="1000">
          <pdfexport
            v-if="dialog"
            size="a3"
            :header="pdf.header"
            :pdfdata="pdf_data"
            @closeModel="closeModel"
            title="Call Detail Report"
          ></pdfexport>
        </v-dialog>
      </v-layout>
    </div> -->
    <v-navigation-drawer
      fixed disable-resize-watcher
      right
      v-model="page.isFilterOpen"
      class="filterNavigation"
    >
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
      <v-container class="sidenavContainer">
        <CallDetailFilter
          v-if="page.isFilterOpen"
          @getDataFrom="getDataFrom"
          :appliedfilter="appliedfilter"
        ></CallDetailFilter>
      </v-container>
      <v-container row class="sidenavFooter">
        <v-btn flat @click="resetvalue()">Reset</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
      </v-container>
      </div>
    </v-navigation-drawer>
    <v-bottom-sheet
      v-model="page.playingRecord"
      hide-overlay
      persistent
      lazy
      inset
      max-width="500"
      class="align-left"
      id="barging-div"
      transparent
    >
      <v-card tile class="player-div">
        <v-toolbar color="light" small>
          <audio controls autoplay v-if="page.playingRecord">
            <source
              src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
          <v-spacer></v-spacer>
          <div class="row justify-fe">
            <v-btn icon>
              <i class="fas fa-download"></i>
            </v-btn>
            <v-btn icon>
              <v-icon @click="togglePlayingRecordSheet('close', {})"
                >close</v-icon
              >
            </v-btn>
          </div>
        </v-toolbar>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import loader from "@/components/Widgets/loader";
import CallDetailFilter from "./Components/EffectiveAbandonFilter.vue";
// import pdfexport from "./Components/pdfexport";
import { mapGetters } from "vuex";
import moment from "moment";
import { MaskCharacter } from "@/utils/helpers";
export default {
    components: {
        loader,
        CallDetailFilter,
        // pdfexport,
    },
    mounted() {
        let scope = this;
        this.page.loading = false;
        // this.$store.dispatch("reportuserlist").then(() => {
        //   // this.getDataFromApi();
        //   this.dateformate();
        // });
        this.dateformate();
        setTimeout(
            function() {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "reports:effectiveabandon:export"
                    ) !== -1
                ) {
                    scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
                } else {
                    scope.page.exportOptions = [];
                }
                if (
                  scope.$store.getters.UserPermissions.indexOf(
                    "configurations:masking:invisible"
                  ) === -1
                ) {
                  scope.masking = true;
                  scope.updateheader()
                }
            }.bind(scope),
            1
        );
    },
    computed: {
        ...mapGetters(["effectiveabandon_data", "queue","MaskingModules"]),
    },
    data() {
        return {
            styleObject: {
                height: "80px",
                "margin-top": "-8px",
            },

            page: {
                title: "Effective Abandon Report",
                icon: "fa-file fa-2x",
                loading: true,
                isFilterOpen: false,
                playingRecord: false,
                breadcrumbs: [],
                exportOptions: [],
                options: { filter: true, refresh: true },
            },
            pagination: {
                rowsPerPage: 10,
            },
            headers: [{
                    text: "Date time",
                    align: "left",
                    sortable: true,
                    class: "p0",
                    value: "calldate",
                    masking: false
                },
                {
                    text: "Phone Number",
                    align: "left",
                    sortable: false,
                    class: "p0",
                    value: "phone_number",
                    masking: false
                },
                {
                    text: "Queue",
                    align: "left",
                    sortable: false,
                    class: "p0",
                    value: "queue",
                    masking: false
                },
                {
                    text: "Call Type",
                    align: "left",
                    sortable: false,
                    class: "p0",
                    value: "call_type",
                    masking: false
                },
                {
                    text: "Status",
                    align: "left",
                    sortable: false,
                    class: "p0",
                    value: "status",
                    masking: false
                },
                {
                    text: "Duration",
                    align: "left",
                    sortable: false,
                    class: "p0",
                    value: "duration",
                    masking: false
                },
                {
                    text: "Count",
                    align: "left",
                    sortable: false,
                    class: "p0",
                    value: "count",
                    masking: false
                }
            ],
            calldetailreport: [],
            calldetailtotal: 0,
            skip: 0,
            take: 0,
            payload: {
                from_date: "",
                to_date: "",
                skip: 0,
                take: 0,
                agent: "",
                detail: "calldetails",
                descending: "",
                export: "exportcalldetails",
            },
            total: "",
            call_date: "",
            report: "",
            exports: [],
            excel_data: [],
            txt_data: [],
            // pdf_data: [],
            from_date: "",
            to_date: "",
            // pdf: {
            //     header: [
            //         { text: "Date time", align: "left", sortable: true, value: "call_date" },
            //         { text: "Uniqueid", align: "left", sortable: true, value: "uniqueid" },
            //         { text: "DID", align: "left", sortable: true, value: "did_number" },
            //         { text: "Queue", align: "left", sortable: true, value: "queue" },
            //         { text: "phone Number", align: "left", sortable: false, value: "phone_number" },
            //         { text: "Call Type", align: "left", sortable: true, value: "call_type" },
            //         { text: "Queue Enter time", align: "left", sortable: true, value: "queue_enter_time" },
            //         { text: "Answer time", align: "left", sortable: false, value: "answer_time" },
            //         { text: "Application", align: "left", sortable: false, value: "last_app" },
            //         { text: "Agent", align: "left", sortable: false, value: "agent" },
            //         { text: "Station", align: "left", sortable: false, value: "station" },
            //         { text: "Dispo Status", align: "left", sortable: false, value: "dispo_status" },
            //         { text: "Status", align: "left", sortable: false, value: "status" },
            //         { text: "Duration", align: "left", sortable: false, value: "duration" },
            //         { text: "Talk time", align: "left", sortable: false, value: "talk_stime" },
            //         { text: "Comments", align: "lest", sortable: false, value: "comments"}
            //     ],
            //     item: [],
            // },

            // pdf: {
            //     header: [],
            //     item: [],
            // },
            dialog: false,
            // pdf_flag: false,
            page_flag: false,
            appliedfilter: {
                phonenumber: "",
                queue: "",
                fromdate: "",
                todate: "",
            },
            Filename:"EffectiveAbandonReport",
            masking:false
        };
    },
    watch: {
        pagination: {
            handler() {
                this.getDataFromApi();
            },
            deep: true,
        },
    },
    methods: {
        openCallLogFilter: function() {
            this.page.isFilterOpen = true;
        },
        closeModel: function() {
            this.dialog = false;
            // this.pdf.header = [];
        },
        refreshCallLogPage: function() {
            var scope = this;
            scope.dateformate();
            scope.getDataFromApi();
            scope.page.loading = true;
            scope.refreshvalue();
            scope.appliedfilter.phonenumber = "";
            scope.appliedfilter.queue = "";
            scope.appliedfilter.status = "";
            scope.appliedfilter.type = "";
      
        },
        exportCallDetailReport: function(option) {
            if (option == "CSV" || option == "Txt(Tab-Delimited)") {
                this.export(option);
            } else {
                this.dialog = true;
                this.export(option);
                // this.pdf_flag = true;
            }
        },
        togglePlayingRecordSheet: function(event, item) {
            if (event == "close") {
                this.page.playingRecord = false;
                this.playingItem = item;
            } else {
                this.page.playingRecord = true;
                this.playingItem = item;
            }
        },
        dateformate() {
            this.page.loading = false;
            this.to_date = new Date();
            this.to_date = moment(this.to_date).format("YYYY-MM-DD HH:mm:ss");
            var from_date1 = new Date();
            var dateOffset = 24 * 60 * 60 * 1000 * 5;
            from_date1.setTime(from_date1.getTime() - dateOffset);
            this.from_date = moment(from_date1).format("YYYY-MM-DD HH:mm:ss");
            this.$store.commit("FILTERFROMDATE", this.from_date);
            this.$store.commit("FILTER_TODATE", this.to_date);
        },
        getDataFromApi() {
            var scope = this;
            this.payload.to_date = this.to_date;
            this.payload.from_date = this.from_date;
            var { page, rowsPerPage } = this.pagination;
            if (rowsPerPage == -1) {
                this.payload.take = this.total;
            } else {
                this.payload.take = rowsPerPage;
            }
            if (page != "" && page != undefined && page != null) {
                this.skip = page;
            } else {
                this.skip = 1;
            }
            this.payload.skip = (this.skip - 1) * this.payload.take;
            this.page.isFilterOpen = false;
            return new Promise((resolve) => {
                var { descending } = scope.pagination;
                this.payload.descending = descending;
                scope.$store
                    .dispatch("effectiveabandon", this.payload)
                    .then((response) => {
                        scope.page.loading = false;
                        scope.total = response.total;
                        if (scope.page_flag) {
                            this.pagination.page = 1;
                            scope.page_flag = false;
                        }
                        scope.calldetailtotal = scope.total;
                        resolve(scope.total);
                        if (response.response.status === 401) {
                            scope.$store.dispatch("checkingtoken");
                        }
                    });
            });
            //  })
            //  .catch(() => {

            //  });
        },
        getDataFrom() {
            this.skip = 0;
            this.getDataFromApi();
            this.page_flag = true;
        },
        resetvalue() {
            this.skip = 0;
            var to_date = new Date();
            to_date = moment(to_date).format();
            var from_date = new Date();
            var dateOffset = 24 * 60 * 60 * 1000 * 5;
            from_date.setTime(from_date.getTime() - dateOffset);
            from_date = moment(from_date).format();
            this.refreshvalue();
            this.$store.dispatch("fromdate", from_date);
            this.$store.dispatch("todate", to_date);
            this.appliedfilter.phonenumber = "";
            this.appliedfilter.queue = "";
            this.appliedfilter.status = "";
            this.appliedfilter.type = "";
            this.appliedfilter.flag = true;
            this.appliedfilter.fromdate = "";
            this.appliedfilter.todate = "";
            if (this.appliedfilter.flag) {
                this.appliedfilter.fromdate = from_date;
                this.appliedfilter.todate = to_date;
                this.appliedfilter.flag = false;
            }
            this.getDataFromApi();
            this.page.isFilterOpen = true;
        },
        export(option) {
      this.excel_data = [];
      this.txt_data = [];
      // this.pdf_data = [];
      // let dataa = JSON.parse(localStorage.getItem("group_users"));
      var export_date = {
        from_date: this.payload.from_date,
        to_date: this.payload.to_date,
        abandoncalldetail: "exporteffectiveabandoncalldetails"
      };
      
      this.$store.dispatch("exportdatafetch", export_date).then((response) => {
        this.exports = response;
        for (var i = 0; i < this.exports.length; i++) {
          if(this.masking){
            this.MaskingModules.MaskingModules[this.Filename].forEach((elm)=>{
             response[i][elm] =  this.ChartoMaskingChar(response[i][elm])
            })
          }
          this.key = Object.keys(response[i]);
          this.value = Object.values(response[i]);
          var values = this.value;
          // this.key.map((res) => {
          //     this.pdf.header.push({
          //         text: res,
          //         align: "left",
          //         sortable: true,
          //         value: res,
          //     });
          // });

          this.excel_data.push({
            header: values,
          });
          this.txt_data.push(Object.values(response[i]));
          // this.pdf_data.push(Object.values(response[i]));
        }
        if (option == "Txt(Tab-Delimited)") {
          let txtContent = "";
          let txt_key = this.key;
          let txt_tab = txt_key.join("\t");
          txtContent += `${txt_tab} \r\n`;
          //  csvContent += "Date,Uniqueid,DID,Queue,Phone Number,Call type ,Queue Enter Time ,Answer Time ,Application ,Agent ,Station,Dispo Status,Status ,Duration ,Talk Time,Comments \r\n";
          this.txt_data.forEach(function (rows) {
            let data = Object.values(rows);
            let row = data.join("\t");
            txtContent += row + "\r\n";
          });
          var date1 = new Date();
          date1 = moment(date1).format("YYYY-MM-DD hh-mm");
          var encodedUri1 =
            "data:text/plain;charset=utf-8,\uFEFF" +
            encodeURIComponent(txtContent);

          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute("download", "EffectiveAbandon" + date1 + ".txt");
          document.body.appendChild(link1);
          link1.click();
        }
        if (option == "CSV") {
          let csvContent = '';
          csvContent += `${this.key} \r\n`;
          //  csvContent += "Date,Uniqueid,DID,Queue,Phone Number,Call type ,Queue Enter Time ,Answer Time ,Application ,Agent ,Station,Dispo Status,Status ,Duration ,Talk Time,Comments \r\n";
          this.excel_data.forEach(function (rows) {
            let data = Object.values(rows);
            let data1 = [];
            data.forEach((elm) => {
              if (typeof elm === "string") {
                data1.push(elm.replace(/'|"|#|\n/g, " "));
              } else {
                data1.push(elm);
              }
            });
            for (let i = 0; i < data1.length; i++) {
              if (
                data1[i] != null &&
                data1[i] != undefined &&
                data1[i] != "" && typeof data1[i] == "string"
              ) {
                if (data1[i].includes(",")) {
                  data1[i] = data1[i].replaceAll(",", ";");
                }
              }
            }
            let row = data1.join(",");
            csvContent += row + "\r\n";
          });
         var date = new Date();
          date = moment(date).format("YYYY-MM-DD hh-mm");
          const encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "EffectiveAbandon" + date + ".csv");
          document.body.appendChild(link); // Required for FF
          link.click();
        }
      });
    },
        refreshvalue() {
            this.$store.commit("FILTER_PHONE", { phone: "" });
            this.$store.commit("FILTER_QUEUE", { queue: "" });
            this.$store.commit("FILTER_STATUS", { status: "" });
            this.$store.commit("FILTER_TYPE", { type: "" });
            this.page_flag = true;
        },
    ChartoMaskingChar(data){
      let scope = this
      if(!scope.$store.getters.MaskType.includes(typeof(data))){
          return data
      }
      return MaskCharacter(data)
    },
    updateheader(){
      let scope = this
      scope.headers.forEach((elm)=>{
        elm.masking = scope.masking && elm.value == scope.MaskingModules.MaskingModules[scope.Filename].find(tlm => tlm == elm.value)
      })
    }  
  },

    beforeDestroy() {
        // this.$store.commit('FILTER_FLAG');
        this.$store.commit("FILTER_TYPE", { type: "" });
        this.$store.commit("FILTER_PHONE", { phone: "" });
        this.$store.commit("FILTER_QUEUE", { queue: "" });
        this.$store.commit("FILTER_STATUS", { status: "" });
    },
};
</script>