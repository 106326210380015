import config from "@/config/index";
import auth0Variables from '../../utils/auth0-variables';
import axios from '../../utils/axios-util';
import VueJwtDecode from 'vue-jwt-decode';
import {decoding} from '../../utils/EncodeDecode'
import * as mutation_type from "../mutation-types";
import {
    AUTH0LOGINSUCCESS,
    AUTH0LOGOUTSUCCESS,
    RESETTOKEN
} from "../mutation-types";
const state = config.auth0;
var variables = auth0Variables();
var token = '';
var permissionInfo = '';

const getters = {
    UserPermissions: state => state.settings.permissions,
    isAutoLoginLoading: state => state.autoLoginLoader
}
const mutations = {
    [AUTH0LOGINSUCCESS]: (state, payload) => {
        state.auth0.isLoggedIn = true;
        state.auth0.accessToken = payload.token;
        state.auth0.api_url = payload.api_url;
        state.auth0.portal_url = payload.portal_url;
        state.auth0.tenant_code = payload.tenant_code;
    },
    [AUTH0LOGOUTSUCCESS]: (state) => {
        state.auth0.isLoggedIn = false;
        state.auth0.accessToken = '';
    },
    [RESETTOKEN]: (state, payload) => {
        state.auth0.accessToken = payload;
    },
    [mutation_type.SET_AUTO_LOGIN_LOADER]: (state, isLoading) => {
        state.autoLoginLoader = isLoading;
      }
};
const actions = {
    setAutoLoginLoader: ({ commit }, isLoading) => {
        commit(mutation_type.SET_AUTO_LOGIN_LOADER, isLoading);
      },
    initiateAutoLogin({ commit, dispatch }, encryptedData) {
        return new Promise((resolve, reject) => {
          try {
            const loginData = JSON.parse(decoding(encryptedData));
            console.log('0000 Parsed loginData, dispatching auth0Login');
            commit(mutation_type.SET_AUTO_LOGIN_LOADER, true);
            dispatch('auth0Login', loginData)
              .then(res => {
                console.log('0000 auth0Login successful, dispatching auth0Parse');
                return dispatch('auth0Parse', res);
              })
              .then(() => {
                console.log('0000 Auto-login successful');
                commit(mutation_type.SET_AUTO_LOGIN_LOADER, false);
                resolve({ success: true, message: 'Auto-login successful' });
              })
              .catch(error => {
                console.error('0000 Auto-login failed:', error);
                commit(mutation_type.SET_AUTO_LOGIN_LOADER, false);
                reject(error);
              });
          } catch (error) {
            console.error('0000 Error parsing loginData:', error);
            commit(mutation_type.SET_AUTO_LOGIN_LOADER, false);
            reject(error);
          }
        });
      },
      domaininfo: (_, data) => {
        
        return new Promise((resolve, reject) => {
            try {
                let apiurl =  variables.common_api_url
                // let apiurl =  'https://dialer-smibrahim.doocti.com/'
                axios.get(`${apiurl}/${data}`)
                .then(response => {
                  resolve(response.data);
                })
                .catch(error => {
                    if (error.response.status === 500) {
                      reject(error);
                    } else {
                        reject(error);
                    }
                })

            } catch (err) {
                reject(err)
            }
        })
    },
    auth0Login: (_, data) => {
        let payload = {
            password: data.password,
            username: data.email.toLowerCase()
        }
        return new Promise((resolve, reject) => {
            try {
                const regex = /doocti/g;
                const matches = window.location.hostname.match(regex);
                let apiurl = ''
                if (matches !== null && matches[0] === "doocti") {
                    apiurl = variables.common_api_url
                }
                else {
                    apiurl = variables.common_api_url
                    // apiurl='https://api-v2.'+window.location.hostname
                }
                payload.role=1
                axios.post(`${apiurl}/v2/admin/login`, payload)
                .then(response => {
                  resolve(response.data.access_token);
                })
                .catch(error => {
                    if (error.response.status === 500) {
                      reject(error);
                    } else {
                        reject(error);
                    }
                })

            } catch (err) {
                reject(err)
            }
        })
    },
    auth0Parse: ({ commit, dispatch }, data) => {
        return new Promise((resolve, reject) => {
            console.log("auth0Parse",data)
            var decoded_token = VueJwtDecode.decode(data);
            var tenant_code = decoded_token["http://api.doocti.com/claims/tenant_code"];
            localStorage.setItem("TenantCode", tenant_code);
            localStorage.setItem("editfields", false);
            // var api_url = decoded_token["http://api.doocti.com/claims/api_domain"];
            // var portal_url = decoded_token["http://api.doocti.com/claims/portal_domain"];
            var user_email = decoded_token["http://api.doocti.com/claims/email"];
            var config1 = {
                headers: {
                    Authorization: `Bearer ${data}`,
                },
            };
            const regex = /doocti/g;
            const matches = window.location.hostname.match(regex);
            let apiurl = ''
            if (matches !== null && matches[0] === "doocti") {
                apiurl = variables.common_api_url
            }
            else {
                apiurl = variables.common_api_url
                // apiurl='https://api-v2.'+window.location.hostname
            }
            axios
                .get(`${apiurl}/common/project/config?tenant_code=${tenant_code}&user_email=${user_email}`, config1)
                .then(response => {
                    var apiInfo = {
                        token: data,
                        portal_url: "https://" + response.data.projects[0].config[0].portal_domain,
                        api_url: "https://" + response.data.projects[0].config[0].api_domain,
                        tenant_code: tenant_code,
                        user_email: user_email,
                        sub_version: response.data.projects[0].config[0].sub_version ?  response.data.projects[0].config[0].sub_version : ""
                    }
                    localStorage.apiInfo = JSON.stringify(apiInfo);
                    localStorage.projectConfig = JSON.stringify(response.data);
                    localStorage.projectId = response.data.projects[0].id;
                    axios.defaults.baseURL = apiInfo.portal_url;
                    axios.defaults.headers.Authorization = `Bearer ${apiInfo.token}`;
                    commit(AUTH0LOGINSUCCESS, apiInfo);
                    dispatch('getUserPermissions').then(() => {  
                        dispatch('FilterTeam',{user:user_email}).then((res)=>{
                            if(res.status ==  200){
                                let payload=res.data.data.map((elm)=>{
                                    let {id, name, type, type_data, campaign, user } =elm
                                    return {
                                        id,
                                        name,
                                        type,
                                        type_data,
                                        user,
                                        campaign:campaign.campaign
                                    }
                                })
                                localStorage.setItem('Team',JSON.stringify(payload))
                                let team_users = payload.map(elm => elm.user).flat()
                                const team_group_users = [...new Set(team_users)];
                                localStorage.setItem('Team_group_user',JSON.stringify(team_group_users))
                                commit("filter_EmailId", { user_email: team_group_users });
                                let user_payload = {
                                  skip: 0,
                                  take: team_group_users.length
                                }
                                dispatch("userManagement", user_payload).then((response) => {
                                    const agentRoles = response.data.data.filter(item => item.roles.some(role => role.name === "Agent"));
                                    const AdminRoles = response.data.data.filter(item => item.roles.some(role => role.name === "Administrator"));
                                    let agentEmails = agentRoles.map(item => item.user_email);
                                    let AdminEmails = AdminRoles.map(item => item.user_email);
                                    localStorage.setItem("Team_agent_user", JSON.stringify(agentEmails));
                                    localStorage.setItem("Team_admin_user", JSON.stringify(AdminEmails));
                                    commit("filter_EmailId", { user_email: [] });
                                    dispatch("GetSystemSettings").then((res_systemconfig)=>{
                                        let config_value = JSON.parse(localStorage.getItem("projectConfig"))
                                        config_value.masking_visible_char = res_systemconfig.data[0].masking_visible_char
                                        config_value.meeting_interval = res_systemconfig.data[0].meeting_interval
                                        localStorage.setItem("projectConfig",JSON.stringify(config_value))
                                        resolve();
                                    }).catch((err)=>{
                                        console.log("GetSystemSettings Err:",err)
                                    })

                                })
                            }else if(res.status ==  204){
                                localStorage.setItem('Team',JSON.stringify([]))
                                localStorage.setItem('Team_group_user',JSON.stringify([]))
                                localStorage.setItem('Team_admin_user',JSON.stringify([]))
                                localStorage.setItem('Team_agent_user',JSON.stringify([]))
                                dispatch("GetSystemSettings").then((res_systemconfig)=>{
                                    let config_value = JSON.parse(localStorage.getItem("projectConfig"))
                                    config_value.masking_visible_char = res_systemconfig.data[0].masking_visible_char
                                    config_value.meeting_interval = res_systemconfig.data[0].meeting_interval
                                    localStorage.setItem("projectConfig",JSON.stringify(config_value))
                                    resolve();
                                }).catch((err)=>{
                                    console.log("GetSystemSettings Err:",err)
                                })
                            }
                        }).catch((err)=>{
                            console.log(err)
                        })
                    });
                })
                .catch((err) => {
                    reject(err)
                });


        })
    },
    tokencheck: ({ commit }) => {
        token = localStorage.getItem('userToken');
        permissionInfo = JSON.parse(localStorage.getItem('permissionInfo'));
        if (state.auth0.accessToken == '') {
            commit(RESETTOKEN, token);
        }
        if (permissionInfo) {
            commit('ModifyUserPermissions', permissionInfo);
        }
    },
   
    auth0Logout: ({ commit }) => {
        return new Promise((resolve, reject) => {
          try {
            // const apiInfo = JSON.parse(localStorage.getItem("apiInfo")) || {};
            // const subVersion = apiInfo.sub_version;
            // const baseVersion = process.env.VUE_APP_BASE_URL || null;
            // const mainVersion = process.env.VUE_APP_MAIN_URL;
      
            // Clear few local storage keys
            // const keysToRemove = [
            //   "apiInfo", "projectConfig", "projectId", "group_template",
            //   "group_users", "group_queue", "group_disposition", "editfields", "Team"
            // ];
            //keysToRemove.forEach(key => localStorage.removeItem(key));
      
            localStorage.clear();
            localStorage.removeItem('loginInitiated'); 
            commit(AUTH0LOGOUTSUCCESS);
            localStorage.setItem("logoutFlag", "true");
      
            // if (subVersion) {
            //   const redirectPath = baseVersion ? `/${mainVersion}/` : '/';
            //   console.log("subversion while logout", redirectPath);
            //   window.location.href = redirectPath;
             
            // }
      
            resolve();
          } catch (error) {
            reject(error);
          }
        });
      },
      
      

};
export default {
    state,
    getters,
    mutations,
    actions
};