import AppSidebar from "@/layouts/App/NavDrawer";
import AppToolbar from "@/layouts/App/Toolbar";
import AppFooter from "@/layouts/App/Footer";
// NOT REMOVE COMMENTED CODE .
// import SLA from "@/views/Configurations/Sla";
import CallflowToolbar from "@/layouts/CallFlow/Toolbar";
import CallflowFooter from "@/layouts/CallFlow/Footer";
import CallFlow from "@/views/CallFlow/CallFlow";
import CreateCallFlow from "@/views/CallFlow/create/CreatePage";
import RenderCallFlow from "@/views/CallFlow/render/RenderPage";
import WaChatLogReport from "@/views/Reports/WaChatlogReport"
import WapReportDetail from "@/views/Reports/WapReportDetail"
import Workflow from "@/views/Configurations/Workflow";
import WorkflowView from "@/views/Configurations/Workflow-view";
import TransferCallReport from "@/views/Reports/TransferCallReport";
import LoginPage from "@/views/LoginPage";
import ErrorPage from "@/views/ErrorPage";
import BlankPage from "@/views/BlankPage";
import LiveAgents from "@/views/RealTime/LiveAgents";
import Timezone from "@/views/Configurations/Timezone";
import LiveCalls from "@/views/RealTime/LiveCalls";
import Wallboard from "@/views/RealTime/Wallboard";
import SystemCallSummaryReport from "@/views/Reports/SystemCallSummaryReport";
import RecordingReport from "@/views/Reports/RecordingReport";
import CallDetailReport from "@/views/Reports/CallDetailReport";
import ShiftUser from "@/views/Appointment/ShiftUser";
import Calender from "@/views/Appointment/Calender";
import Shift from "@/views/Appointment/Shift";
import Category from "@/views/Appointment/Categorys";
import PerformanceReport from "@/views/Reports/PerformanceReport";
import EffectiveAbandonReport from "@/views/Reports/EffectiveAbandonReport";
import AgentActivityReport from "@/views/Reports/AgentActivityReport";
import AgentPauseReport from "@/views/Reports/AgentPauseReport";
import AgentDispoReport from "@/views/Reports/AgentDispoReport";
import QueueSummary from "@/views/Reports/QueueSummary";
import MissedCallReport from "@/views/Reports/MissedCallReport";
import DropedCallReport from "@/views/Reports/DropedCallReport";
import AbandonedSummary from "@/views/Reports/AbandonedSummary";
import TicketReport from "@/views/Reports/TicketReport";
import TicketReportbyAgent from "@/views/Reports/TicketReportbyagent";
import SystemSettings from "@/views/Configurations/systemSettings";
import Auth0CallBack from "@/views/Auth0Callback/Auth0Callback";
import UserManagement from "@/views/UserManagement/UserManagement";
import QueueManagement from "@/views/QueueManagement/QueueManagement";
import AudioStore from "@/views/Configurations/AudioStore";
// import WebHookManagement from "@/views/Connections/WebHookManagement";
// import Scheduler from "@/views/Configurations/TimeCondition";
import DoNotCall from "@/views/Configurations/DoNotCall";
import blockList from "@/views/Configurations/blockList";
// import reminder from "@/views/Configurations/reminder";
import GroupManagement from "@/views/Configurations/GroupManagement";
import Disposition from "@/views/Configurations/Disposition";
import subDisposition from "@/views/Configurations/SubDisposition";
import PauseCode from "@/views/Configurations/PauseCode";
import MeetingTitle from "@/views/Configurations/MeetingTitle";
import Script from "@/views/Configurations/Script";
// import Contact from "@/views/Configurations/Contact";
import Rules from "@/views/Configurations/Rules";
import AsterCampaign from "@/views/CRM/AsterCampaigns";
import AsterMeetings from "@/views/CRM/AsterMeetings";
import AsterContact from "@/views/CRM/AsterContacts";
import Announcement from "@/views/Configurations/Announcement";
import AsterList from "@/views/CRM/AsterList";
import AsterLead from "@/views/CRM/AsterLeads";
import Leads from "@/views/CRM/LeadDetail";
import SupportsList from '@/views/CRM/SupportList';
import Tickets from "@/views/CRM/SupportDetail";
import TagManagement from "@/views/TagManagement/TagManagement";
// import AsterSms from "@/views/Template/AsterSms";
// import AsterMail from "@/views/Template/AsterMail";
// import AsterWhatsapp from "@/views/Template/AsterWhatsapp";
// import Notification  from "@/views/Configurations/notification/Notification";
import CustomForm from '@/views/CRM/custom-form/index';
import CustomFormModule from "@/views/CRM/Custom_module"
import Formbuilder from '@/views/CRM/custom-form/index1'
import Home from '@/views/CRM/custom-form/Home';
import Preview from '@/views/CRM/custom-form/Preview'
import CustomFormList from '@/views/CRM/CustomForm';
import CustomFormList1 from '@/views/CRM/Customform1';
import InsertAsterLead from '@/views/CRM/InsertAsterLead';
import Table from '@/views/CRM/Table';
import DashBoard from '@/views/DashBoard/dashboard';
import Widgets from '@/views/DashBoard/widgets';
import addWidgets from '@/views/DashBoard/addwidgets';
import editWidgets from '@/views/DashBoard/editwidgets';
import Contact from "@/views/CRM/ContactDetail";
import leadStatus from "@/views/Configurations/leadStatus";
import ticketStatus from "@/views/Configurations/ticketStatus";
import didNumber from "@/views/Configurations/didNumber";
import WatsAppReport from "@/views/Reports/WatsAppReport";
import WatsAppReportbyAgent from "@/views/Reports/WatsAppReportByAgeny";
import inboundRoute from "@/views/Configurations/InboundRoute";
import TeamManagement from "@/views/Configurations/Team";
import Channel from "@/views/Configurations/Channel"
import Source from "@/views/Configurations/Source"
import omnichannelRoute from "@/views/Configurations/omnichannelRoute";

import Upload from "../components/upload/upload.vue"
export const routes = [
    { path: "/", redirect: "/auth/login" },
    {
        path: "/auth/login",
        name: "doocti/Auth/Login",
        components: {
            default: LoginPage,
        },
        auth: true,
        meta: {
            permission: {
                access: true,
            },
        },
    },
    {
        path: "/callback",
        name: "callback",
        components: {
            default: Auth0CallBack,
        },
        meta: {
            auth: true,
            permission: {
                access: true,
            },
        },
    },
    {
        path: "/dashboard",
        name: "DashBoard",
        components: {
            default: DashBoard,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter,
        },
        meta: {
            auth: true,
            permission: {
                access: ['dashboard:reportbuilder:view'],
            },
        },
    },

    {
        path: "/builder/widgets",
        name: "builder/Widgets",
        components: {
            default: Widgets,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['dashboard:widget:view']
            }
        }
    },
    {
        path: "/addwidgets",
        name: "addwidgets",
        components: {
            default: addWidgets,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: false,
            permission: {
                access: []
            }
        }
    },
    {
        path: "/crm/contactdetail",
        name: "crm/contactDetail",
        components: {
            default: Contact,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: false
        }
    },
    {
        path: "/editwidgets",
        name: "editwidgets",
        components: {
            default: editWidgets,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: false,
            permission: {
                access: []
            }
        }
    },
    {
        path: "/error-page",
        name: "errorpage",
        components: {
            default: ErrorPage,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: false,
            permission: {
                access: []
            }
        }
    },
    {
        path: "/blank-page",
        name: "blankPage",
        components: {
            default: BlankPage,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: false,
            permission: {
                access: []
            }
        }
    },
    {
        path: "/appointment/shift",
        name: "appointment/Shift",
        components: {
            default: Shift,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:pausecode:view']
            }
        }
    },
    {
        path: "/appointment/category",
        name: "appointment/Category",
        components: {
            default: Category,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:pausecode:view']
            }
        }
    },
    {
        path: "/appointment/shiftuser",
        name: "appointment/ShiftUser",
        components: {
            default: ShiftUser,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:pausecode:view']
            }
        }
    },
    {
        path: "/appointment/calender",
        name: "appointment/Calender",
        components: {
            default: Calender,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:pausecode:view']
            }
        }
    },
    {
        path: "/realtime/live-agents",
        name: "realtime/LiveAgents",
        components: {
            default: LiveAgents,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['realtime:liveagents:view']
            }
        }
    },
    {
        path: "/realtime/wallboard",
        name: "realtime/Wallboard",
        components: {
            default: Wallboard,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['realtime:livestatus:campaignview', 'realtime:livestatus:queueview',]
            }
        }
    },
    {
        path: "/realtime/live-calls",
        name: "realtime/LiveCalls",
        components: {
            default: LiveCalls,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['realtime:livecalls:view']
            }
        }
    },
    {
        path: "/report/ticket",
        name: "report/TicketReport",
        components: {
            default: TicketReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:abandonedsummary:view']
            }
        }
    },
    {
        path: "/report/ticketbyagent",
        name: "report/TicketByAgent",
        components: {
            default: TicketReportbyAgent,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:abandonedsummary:view']
            }
        }
    },
    {
        path: "/report/system-call-summary-report",
        name: "report/SystemCallSummaryReport",
        components: {
            default: SystemCallSummaryReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:callsummary:view']
            }
        }
    },
    {
        path: "/report/recording-report",
        name: "report/RecordingReport",
        components: {
            default: RecordingReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:recording:view']
            }
        }
    },
    /*---------------------******WhatsAppChat Report------------------------------------*/
    {
        path: "/report/wachat-listview",
        name: "report/WaChatLogReport",
        components: {
            default: WaChatLogReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:whatsappchatlog:view']
            }
        }
    },

    {
        path: "/report/wachat-detailview",
        name: "report/WapReportDetail",
        components: {
            default: WapReportDetail,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:whatsappdetail:view']
            }
        }
    },

    /*-----------------------------------------*******************************-------------------------*/

    {
        path: "/report/call-detail-report",
        name: "report/CallDetailReport",
        components: {
            default: CallDetailReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:calldetail:view']
            }
        }
    },
    {
        path: "/report/performance-report",
        name: "report/PerformanceReport",
        components: {
            default: PerformanceReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:agentperformance:view']
            }
        }
    },
    {
        path: "/report/effective-abandon-report",
        name: "report/EffectiveAbandonReport",
        components: {
            default: EffectiveAbandonReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:effectiveabandon:view']
            }
        }
    },
    {
        path: "/report/agent-activity-report",
        name: "report/AgentActivityReport",
        components: {
            default: AgentActivityReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:agentactivity:view']
            }
        }
    },
    {
        path: "/report/call-transefer-report",
        name: "report/CallTransferReport",
        components: {
            default: TransferCallReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:calltransfer:view']
            }
        }
    },
    {
        path: "/report/agent-pause-report",
        name: "report/AgentPauseReport",
        components: {
            default: AgentPauseReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:agentpause:view']
            }
        }
    },
    {
        path: "/report/agent-dispo-report",
        name: "report/AgentDispoReport",
        components: {
            default: AgentDispoReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:agentdispo:view']
            }
        }
    },
    {
        path: "/report/queue-summary",
        name: "report/QueueSummary",
        components: {
            default: QueueSummary,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:queueperformance:view']
            }
        }
    },
    {
        path: "/report/missed-call-report",
        name: "report/MissedCallReport",
        components: {
            default: MissedCallReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:missedcall:view']
            }
        }
    },
    {
        path: "/report/droped-call-report",
        name: "report/DropedCallReport",
        components: {
            default: DropedCallReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:dropedcall:view']
            }
        }
    },
    {
        path: "/report/abandoned-summary",
        name: "report/AbandonedSummary",
        components: {
            default: AbandonedSummary,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:abandonedsummary:view']
            }
        }
    },
    {
        path: "/usergroup/user-management",
        name: "usergroup/UserManagement",
        components: {
            default: UserManagement,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['management:user:view']
            }
        }
    },
    {
        path: "/usergroup/team",
        name: "usergroup/TeamManagement",
        components: {
            default: TeamManagement,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['management:team:view']
            }
        }
    },
    {
        path: "/usergroup/channel",
        name: "usergroup/Channel",
        components: {
            default: Channel,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['management:channel:view']
            }
        }
    },
    {
        path: "/usergroup/source",
        name: "usergroup/Source",
        components: {
            default: Source,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['management:source:view']
            }
        }
    },
    // {
    //     path: "/configurations/scheduler",
    //     name: "configurations/Scheduler",
    //     components: {
    //         default: Scheduler,
    //         sidebar: AppSidebar,
    //         header: AppToolbar,
    //         footer: AppFooter
    //     }, meta: {
    //         auth: false,
    //         permission: {
    //             access: ['configurations:scheduler:view']
    //         }
    //     }
    // },
    {
        path: "/configurations/queue-management",
        name: "configurations/QueueManagement",
        components: {
            default: QueueManagement,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['management:queue:view']
            }
        }
    },
    {
        path: "/configurations/timezone",
        name: "configurations/Timezone",
        components: {
            default: Timezone,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:dialrule:view']
            }
        }
    },
    // {
    //     path: "/configurations/notification",
    //     name: "configurations/Notification",
    //     components: {
    //         default: Notification,
    //         sidebar: AppSidebar,
    //         header: AppToolbar,
    //         footer: AppFooter
    //     },
    //     meta: {
    //         auth: true,
    //         permission: {
    //             access: ['configurations:notification:view']
    //         }
    //     }
    // },
    {
        path: "/configurations/systemsettings",
        name: "configurations/SystemSettings",
        components: {
            default: SystemSettings,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:announcement:view']
            }
        }
    },
    // {
    //     path: "/connections/web-hooks",
    //     name: "connections/WebHooks",
    //     components: {
    //         default: WebHookManagement,
    //         sidebar: AppSidebar,
    //         header: AppToolbar,
    //         footer: AppFooter
    //     },
    //     meta: {
    //         auth: true,
    //         permission: {
    //             access: ['connection:webhooks:view']
    //         }
    //     }
    // },
    {
        path: "/configurations/dnc",
        name: "configurations/DoNoCall",
        components: {
            default: DoNotCall,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:dnc:view']
            }
        }
    },
    {
        path: "/configurations/blocklist",
        name: "configurations/BlockList",
        components: {
            default: blockList,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:blocklist:view']
            }
        }
    },

    // {
    //     path: "/configurations/reminder",
    //     name: "configurations/Reminder",
    //     components: {
    //         default: reminder,
    //         sidebar: AppSidebar,
    //         header: AppToolbar,
    //         footer: AppFooter
    //     },
    //     meta: {
    //         auth: true,
    //         permission: {
    //             access: ['configurations:reminder:view']
    //         }
    //     }
    // },
    {
        path: "/usergroup/groupmanagement",
        name: "usergroup/GroupManagement",
        components: {
            default: GroupManagement,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:usergroup:view']
            }
        }
    },
    {
        path: "/configurations/audiostore",
        name: "configurations/AudioStore",
        components: {
            default: AudioStore,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:audiostore:view']
            }
        }
    },
    {
        path: "/configurations/disposition",
        name: "configurations/Disposition",
        components: {
            default: Disposition,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:dispositions:view']
            }
        }
    },
    {
        path: "/configurations/leadstatus",
        name: "configurations/LeadStatus",
        components: {
            default: leadStatus,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:leadstatus:view']
            }
        }
    },
    {
        path: "/configurations/ticketstatus",
        name: "configurations/TicketStatus",
        components: {
            default: ticketStatus,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:ticketstatus:view']
            }
        }
    },
    {
        path: "/configurations/didnumber",
        name: "configurations/DidNumber",
        components: {
            default: didNumber,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:didnumber:view']
            }
        }
    },

    {
        path: "/configurations/pausecode",
        name: "configurations/PauseCode",
        components: {
            default: PauseCode,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:pausecode:view']
            }
        }
    },
    {
        path: "/configurations/MeetingTitle",
        name: "configurations/MeetingTitle",
        components: {
            default: MeetingTitle,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:pausecode:view']
            }
        }
    },
    {
        path: "/configurations/subDisposition",
        name: "configurations/SubDisposition",
        components: {
            default: subDisposition,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:subdisposition:view']
            }
        }
    },
    {

        path: "/configurations/Script",
        name: "configurations/Script",
        components: {
            default: Script,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:script:view']
            }
        }
    },
    // {

    //     path: "/configurations/Sla",
    //     name: "configurations/Sla",
    //     components: {
    //         default: SLA,
    //         sidebar: AppSidebar,
    //         header: AppToolbar,
    //         footer: AppFooter
    //     },
    //     meta: {
    //         auth: true,
    //         permission: {
    //             access: ['configurations:sla:view']
    //         }
    //     }
    // },
    {
        path: "/configurations/Workflow/view",
        name: "Workflow/view",
        components: {
            default: WorkflowView,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:sla:view']
            }
        }
    },
    {

        path: "/configurations/Workflow",
        name: "configurations/Workflow",
        components: {
            default: Workflow,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:sla:view']
            }
        }
    },
    {
        path: "/crm/contacts",
        name: "crm/AsterContact",
        components: {
            default: AsterContact,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter,
        },
        meta: {
            auth: true,
            permission: {
                access: ["leadmanagement:contacts:view"],
            },
        },
    },
    {
        path: "/configurations/rules",
        name: "configurations/Rules",
        components: {
            default: Rules,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:rules:view']
            }
        }
    },

    {
        path: "/configurations/announcement",
        name: "configurations/Announcement",
        components: {
            default: Announcement,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:announcement:view']
            }
        }
    },
    {
        path: "/crm/campaigns",
        name: "crm/AsterCampaign",
        components: {
            default: AsterCampaign,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:campaigns:view']
            }
        }
    },
    {
        path: "/crm/meetings",
        name: "crm/AsterMeetings",
        components: {
            default: AsterMeetings,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:meetings:view']
            }
        }
    },
    {
        path: "/crm/leads",
        name: "crm/AsterLead",
        components: {
            default: AsterLead,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:lead:view']
            }
        }
    },
    {
        path: "/crm/leadsdetail",
        name: "crm/leadDetail",
        components: {
            default: Leads,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:lead:view']
            }
        }
    },
    {
        path: "/crm/ticketdetail",
        name: "crm/ticketDetail",
        components: {
            default: Tickets,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: false
        }
    },
    {
        path: "/crm/ticketlist",
        name: "crm/SupportsList",
        components: {
            default: SupportsList,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: false,
            permission: {
                access: ['leadmanagement:tickets:view']
            }
        }
    },
    // {
    //     path: "/connections/sms",
    //     name: "connections/AsterSms",
    //     components: {
    //         default: AsterSms,
    //         sidebar: AppSidebar,
    //         header: AppToolbar,
    //         footer: AppFooter
    //     },
    //     meta: {
    //         auth: true,
    //         permission: {
    //             access: ['template:sms:view']
    //         }
    //     }
    // },
    // {
    //     path: "/connections/mail",
    //     name: "connections/AsterMail",
    //     components: {
    //         default: AsterMail,
    //         sidebar: AppSidebar,
    //         header: AppToolbar,
    //         footer: AppFooter
    //     },
    //     meta: {
    //         auth: true,
    //         permission: {
    //             access: ['template:mail:view']
    //         }
    //     }
    // },
    // {
    //     path: "/connections/whatsapp",
    //     name: "connections/AsterWhatsapp",
    //     components: {
    //         default: AsterWhatsapp,
    //         sidebar: AppSidebar,
    //         header: AppToolbar,
    //         footer: AppFooter
    //     },
    //     meta: {
    //         auth: true,
    //         permission: {
    //             access: ['template:whatsapp:view']
    //         }
    //     }
    // },
    {
        path: "/crm/leads/create",
        name: "crm/InsertAsterLead",
        components: {
            default: InsertAsterLead,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:lead:create']
            }
        }
    },
    {
        path: "/upload",
        name: "Upload",
        components: {
            default: Upload,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:lead:create']
            }
        }
    },

    {
        path: "/crm/table",
        name: "crm/Table",
        components: {
            default: Table,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:lead:view']
            }
        }
    },
    {
        path: "/crm/lists",
        name: "crm/AsterList",
        components: {
            default: AsterList,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:list:view']
            }
        }
    },
    {
        path: "/builder/customform",
        name: "crm/CustomForm",
        components: {
            default: CustomForm,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:custom-form:view']
            }
        }
    },
    {
        path: "/formbuilder",
        name: "builder/Formbuilder",
        components: {
            default: CustomFormList1,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:custom-form:view']
            }
        }
    },
    {
        path: "/formbuilder/view",
        name: "Formbuilderview",
        components: {
            default: Formbuilder,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:custom-form:view']
            }
        }
    },
    {
        path: "/builder/customform/home",
        name: "crm/home",
        components: {
            default: Home,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:custom-form:view']
            }
        }
    },
    {
        path: "/builder/customform/list",
        name: "builder/CustomFormList",
        components: {
            default: CustomFormList,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:custom-form:view']
            }
        }
    },
    {
        path: "/builder/customform/preview",
        name: "crm/preview",
        components: {
            default: Preview,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['leadmanagement:custom-form:view']
            }
        }
    },
    {
        path: "/configurations/inboundroute",
        name: "configurations/InboundRoute",
        components: {
            default: inboundRoute,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:inboundroute:view']
            }
        }
    },
    {
        path: "/configurations/omnichannelroute",
        name: "configurations/OmnichannelRoute",
        components: {
            default: omnichannelRoute,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['configurations:omnichannelroute:view']
            }
        }
    },
    {
        path: "/configurations/tag-management",
        name: "configurations/TagManagement",
        components: {
            default: TagManagement,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['management:tags:view']
            }
        }
    },

    {
        path: "/report/whatsapp",
        name: "report/WatsAppReport",
        components: {
            default: WatsAppReport,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:abandonedsummary:view']
            }
        }
    },
    {
        path: "/report/watsappbyagent",
        name: "report/WatsAppByAgent",
        components: {
            default: WatsAppReportbyAgent,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter
        },
        meta: {
            auth: true,
            permission: {
                access: ['reports:abandonedsummary:view']
            }
        }
    },
    {
        path: "/CallFlow",
        name: "CallFlow",
        components: {
            default: CallFlow,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter,
        },
    },
    {
        path: "/CallFlow/Create",
        name: "CallFlow/Create",
        components: {
            default: CreateCallFlow,
            sidebar: AppSidebar,
            header: CallflowToolbar,
            footer: CallflowFooter,
        },
    },
    {
        path: "/CallFlow/Render",
        name: "CallFlow/Render",
        components: {
            default: RenderCallFlow,
            sidebar: AppSidebar,
            header: CallflowToolbar,
            footer: CallflowFooter,
        },
    }, {
        path: "/customModule/formDetails",
        name: "CustomFormModule",
        components: {
            default: CustomFormModule,
            sidebar: AppSidebar,
            header: AppToolbar,
            footer: AppFooter,
        },
    },
];