<template>
    <div>
        <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
            :breadcrumbs="page.breadcrumbs" :addNewData="page.addnew" :exportOptions="page.exportOptions"
            @openFilter="filterClick" @refreshPage="refreshThisPage" @addNew="AddNewCampaign" :style="styleObject">
        </section-definition>
        <loader v-if="page.loading"></loader>
        <v-container v-else fluid grid-list-xl page>
            <v-data-table :headers="headers" :items="campaignData" class="elevation-1 fixed-layout"
                :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading"
                :rows-per-page-items="[10]">
                <template v-slot:no-data style="text-align: center">
                    <div style="text-align: center">No Data Available</div>
                </template>
                <template v-slot:items="props">
                    <td class="checkEmpty p0">{{ props.item.campaign_name }}</td>
                    <td class="checkEmpty p0">{{ props.item.industry }}</td>
                    <td class="checkEmpty p0">{{ props.item.Domain }}</td>
                    <td class="checkEmpty p0">{{ props.item.template_name }}</td>
                    <td class="checkEmpty p0 text-uppercase">
                        {{ props.item.call_type }}
                    </td>
                    <td class="checkEmpty p0">
                        {{ props.item.active == "Y" ? "Yes" : "No" }}
                    </td>
                    <td class="checkEmpty p0">
                        <!-- /////// Quck edit option code by abdul //////////////// -->

                        <v-flex xs4 v-if="props.item.call_type == 'AUTO' || props.item.call_type == 'VB'
                        ">
                            <v-select v-model="props.item.hooper_level" :items="addCampaign.options.buffer_level"
                                v-bind:disabled="disabled[props.index].disable" item-text="name" class="edit-input"
                                item-value="value" v-on:change="onbufferChange($event, props.item)" return-string
                                persistent-hint></v-select>
                        </v-flex>
                    </td>
                    <td class="checkEmpty p0">
                        <v-flex xs4 v-if="props.item.call_type == 'AUTO'">
                            <v-select v-model="props.item.dial_ratio" class="edit-input"
                                :items="addCampaign.options.dial_ratio" v-bind:disabled="disabled[props.index].disable"
                                item-text="name" item-value="value" v-on:change="onratioChange($event, props.item)"
                                required return-string persistent-hint></v-select>
                        </v-flex>
                    </td>
                    <td class="checkEmpty p0">
                        <!-- <div style="display: flex; position: relative; top: 24px"> -->
                        <v-icon color="primary" small class="mr-4" :disabled="props.item.call_type !== 'VB'"
                            @click="addVbDetail(props.item.campaign_name)"> phone
                        </v-icon>
                        <v-icon style="width: 20px" v-if="page.canedit && disabled[props.index].disable" @click="disabled[props.index].disable = !disabled[props.index].disable
                            " color="green" small class="mr-4 fa fa-pencil-square-o"></v-icon>

                        <v-icon v-else @click="disabled[props.index].disable = !disabled[props.index].disable
                            " color="red" small class="mr-4 fa fa-pencil-square-o"></v-icon>

                        <v-icon color="blue" small class="mr-4" v-if="page.canedit"
                            @click="editItem(props.item)">edit</v-icon>
                        <v-icon color="red" small class="mr-2" v-if="page.candelete"
                            @click="deleteItem(props.item.id)">delete</v-icon>

                        <!-- </div> -->
                    </td>
                </template>
            </v-data-table>
        </v-container>
        <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Delete Campaign Info</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Are you sure to delete this Campaign? data related to this campaign will also be
                            deleted.</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
                    <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent :value="page.isVoiceBlast" scrollable max-width="400px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Add Phone No</span>
                    </v-card-title>
                </v-toolbar>
                <v-card-text>
                    <v-flex xs12>
                        <v-text-field v-model="voiceblast.phone_no" required label="Phone No" type="text"
                            :rules="[(v) => !!v || 'Phone No is required']"></v-text-field>
                    </v-flex>

                </v-card-text>
                <v-card-actions class="modal-footer--sticky">
                    <v-spacer></v-spacer>
                    <v-btn color="light" type="reset" flat @click="closeVB()">Close</v-btn>
                    <v-btn :disabled="voiceblast.phone_no == ''" color="primary" @click="saveVB()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <template>
            <v-dialog persistent :value="page.isAddCampaign" scrollable max-width="800px">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-card-title>
                            <span class="headline">Create Campaign</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text style="height: 500px">
                        <v-form ref="Createform" v-model="valid">
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    
                                    <v-flex xs6>
                                        <v-autocomplete v-model="addCampaign.payload.did_rotate_strategy"
                                            :items="addCampaign.options.did_strategy" label="Did Rotate Strategy"
                                            item-text="name" item-value="value" required
                                            @change="typechange($event, 'Did_Rot_St')"></v-autocomplete>

                                    </v-flex>
                                    <v-flex
                                        v-if="addCampaign.payload.did_rotate_strategy === 'random' || addCampaign.payload.did_rotate_strategy === 'manual'"
                                        xs6>
                                        <v-autocomplete v-model="addCampaign.payload.did_routing_numbers"
                                            :items="addCampaign.options.didnumber" label="Did Rotate Numbers"
                                            item-text="name" item-value="value" required
                                            :rules="Fieldrules.did_routing_numbers" multiple></v-autocomplete>

                                    </v-flex>
                                    <v-flex xs6>
                                        <v-autocomplete v-model="addCampaign.payload.campaign_process"
                                            :items="addCampaign.options.process_values" label="Process" item-text="name"
                                            item-value="value" required multiple
                                            :rules="Fieldrules.campaign_process"></v-autocomplete>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select v-model="addCampaign.payload.call_type"
                                            :items="addCampaign.options.type" label="Type" item-text="name"
                                            item-value="value" return-string required persistent-hint
                                            :rules="Fieldrules.call_type"
                                            @change="typechange($event, 'Type')"></v-select>
                                    </v-flex>
                                    <v-flex xs6>
                                        <div class="autocompleteDiv">
                                            <v-autocomplete v-model="addCampaign.payload.caller_id"
                                                :items="addCampaign.options.didnumber" label="Outbound Caller ID"
                                                persistent-hint :disabled="addCampaign.payload.call_type === ''" small
                                                required :multiple="multipleSelect.caller_id"
                                                :rules="Fieldrules.caller_id">
                                            </v-autocomplete>

                                        </div>
                                    </v-flex>
                                    <v-flex xs6>
                                        <div class="autocompleteDiv">
                                            <v-select v-model="addCampaign.payload.Industryvalue" :items="Industrylist"
                                                label="Industry" required persistent-hint return-string
                                                :rules="Fieldrules.Industryvalue" @change="Industryvalues(addCampaign.payload.Industryvalue)
                                                    ">
                                            </v-select>
                                        </div>
                                    </v-flex>
                                    <v-flex xs6>
                                        <div class="autocompleteDiv">
                                            <v-select v-model="addCampaign.payload.template_name"
                                                :items="addCampaign.options.templates" label="Template Name" required
                                                persistent-hint return-string :rules="Fieldrules.template_name"
                                                @change="domainmap(addCampaign.payload.template_name)"></v-select>
                                        </div>
                                    </v-flex>

                                    <v-flex xs6>
                                        <div class="autocompleteDiv">
                                            <v-select v-model="addCampaign.payload.workflowvalues" :items="domainlist"
                                                label="Domain" persistent-hint small :rules="Fieldrules.workflowvalues"
                                                @change="workFlowvalues(addCampaign.payload.workflowvalues)
                                                    ">
                                            </v-select>
                                        </div>
                                    </v-flex>

                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type !== 'WAB'">
                                        <div class="autocompleteDiv">
                                            <v-autocomplete v-model="addCampaign.payload.default_queue"
                                                :items="addCampaign.options.add_queue" label="Add Queue" persistent-hint
                                                small required multiple :rules="Fieldrules.default_queue">
                                            </v-autocomplete>
                                        </div>
                                    </v-flex>

                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type !== 'WAB'">
                                        <div class="autocompleteDiv">
                                            <v-autocomplete v-model="addCampaign.payload.pause_code"
                                                :items="addCampaign.options.pause_code" label="Pause Code"
                                                persistent-hint small required multiple
                                                :rules="Fieldrules.pause_code"></v-autocomplete>
                                        </div>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type !== 'WAB'">
                                        <div class="autocompleteDiv">
                                            <v-autocomplete v-model="addCampaign.payload.dial_statuses"
                                                :items="addCampaign.options.filterdispo" label="Dial Status" required
                                                persistent-hint small multiple
                                                :rules="Fieldrules.dial_statuses"></v-autocomplete>
                                        </div>
                                    </v-flex>

                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type !== 'WAB'">
                                        <div class="autocompleteDiv">
                                            <v-autocomplete v-model="addCampaign.payload.dispo_statuses"
                                                :items="addCampaign.options.filterdispo" label="Dispo Status"
                                                persistent-hint small required multiple
                                                :rules="Fieldrules.dispo_statuses"></v-autocomplete>
                                        </div>
                                    </v-flex>
                                    <!-- <v-flex xs6>
    <v-select v-model="addCampaign.payload.active" :items="addCampaign.options.status" label="Status"
    item-text="name" item-value="value" return-string required persistent-hint
    :rules="[(v) => !!v || 'Status is required']"></v-select>
    </v-flex> -->
                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type == 'AUTO' ||
                                        this.addCampaign.payload.call_type == 'VB' || this.addCampaign.payload.call_type == 'POWER'
                                    ">
                                        <v-select v-model="addCampaign.payload.hooper_level"
                                            :items="addCampaign.options.buffer_level" label="Buffer Level"
                                            item-text="name" item-value="value" return-string required persistent-hint
                                            :rules="Fieldrules.hooper_level"></v-select>
                                    </v-flex>

                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type !== 'WAB'">
                                        <v-checkbox v-model="addCampaign.payload.call_recording" color="primary"
                                            :label="`On Demand Recording`">
                                        </v-checkbox>
                                    </v-flex>
                                    <v-flex xs6
                                        v-if="this.addCampaign.payload.call_type == 'AUTO' || this.addCampaign.payload.call_type == 'POWER'">
                                        <v-select v-model="addCampaign.payload.dial_ratio"
                                            :items="addCampaign.options.dial_ratio" label="Dial Ratio" item-text="name"
                                            item-value="value" required return-string persistent-hint
                                            :rules="Fieldrules.dial_ratio"></v-select>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type != 'WAB'">
                                        <div class="autocompleteDiv">
                                            <v-autocomplete v-model="addCampaign.payload.ivr_name"
                                                :items="addCampaign.options.inbound" label="Inbound" persistent-hint
                                                :rules="Fieldrules.ivr_name" small></v-autocomplete>
                                        </div>
                                    </v-flex>

                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type == 'VB'">
                                        <v-select v-model="addCampaign.payload.channel_count"
                                            :items="addCampaign.options.channels" label="Channels" item-text="name"
                                            item-value="value" return-string required persistent-hint
                                            :rules="Fieldrules.channel_count"></v-select>
                                    </v-flex>
                                    <v-flex xs6
                                        v-if="this.addCampaign.payload.call_type == 'VB' || this.addCampaign.payload.call_type == 'WAB'">
                                        <div>
                                            <v-menu ref="menuAddStart" v-model="menuAdd2" :close-on-content-click="false"
                                                :nudge-right="40" :return-value.sync="addCampaign.payload.start_time"
                                                transition="scale-transition" offset-y max-width="290px"
                                                min-width="290px">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="addCampaign.payload.start_time"
                                                        label="Start Time" v-bind="attrs" v-on="on"
                                                        :rules="Fieldrules.start_time" :readonly="!menu2">
                                                    </v-text-field>
                                                </template>
                                                <v-time-picker color="primary" format="24hr" v-if="menuAdd2"
                                                    v-model="addCampaign.start_time" full-width @click:minute="$refs.menuAddStart.save(addCampaign.start_time)
                                                        ">
                                                </v-time-picker>
                                            </v-menu>
                                        </div>
                                        <div>
                                            <v-menu ref="menuAddEnd" v-model="menuAdd3" :close-on-content-click="false"
                                                :nudge-right="40" :return-value.sync="addCampaign.payload.end_time"
                                                transition="scale-transition" offset-y max-width="290px"
                                                min-width="290px">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="addCampaign.payload.end_time"
                                                        label="End Time" v-bind="attrs" v-on="on"
                                                        :rules="Fieldrules.end_time" :readonly="!menu3">
                                                    </v-text-field>
                                                </template>
                                                <v-time-picker color="primary" format="24hr" v-if="menuAdd3"
                                                    v-model="addCampaign.end_time" full-width @click:minute="$refs.menuAddEnd.save(addCampaign.end_time)
                                                        ">
                                                </v-time-picker>
                                            </v-menu>
                                        </div>


                                    </v-flex>
                                    <v-flex xs6
                                        v-if="this.addCampaign.payload.call_type == 'VB' || this.addCampaign.payload.call_type == 'WAB'">
                                        <v-autocomplete v-model="addCampaign.payload.days"
                                            :items="addCampaign.options.days" label="Days" item-text="name"
                                            item-value="value" persistent-hint small required multiple></v-autocomplete>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-text-field v-model="addCampaign.payload.dial_prefix" label="Dial Prefix"
                                            type="number">
                                        </v-text-field>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-text-field v-model="addCampaign.payload.wrap_time" 
                                            label="Wrap Time (in sec)" type="number" :rules="Fieldrules.wrap_time">
                                        </v-text-field>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type !== 'WAB'">
                                        <v-select v-model="addCampaign.payload.script_name" :items="script_name"
                                            label="Script_name" item-text="name" item-value="value" persistent-hint
                                            small required :rules="Fieldrules.script_name" return-string></v-select>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type !== 'WAB'">
                                        <v-checkbox v-model="addCampaign.payload.dnc_check" color="primary"
                                            :label="`DNC check`">
                                        </v-checkbox>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type !== 'WAB'">
                                        <v-checkbox v-model="addCampaign.payload.auto_answer" color="primary"
                                            :label="`Auto Answer`">
                                        </v-checkbox>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type !== 'WAB'">
                                        <v-checkbox v-model="addCampaign.payload.auto_dispo" color="primary"
                                            :label="`Auto Dispo`">
                                        </v-checkbox>
                                    </v-flex>

                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type !== 'WAB'">
                                        <v-checkbox v-model="addCampaign.payload.masking" color="primary"
                                            :label="`Call Masking`">
                                        </v-checkbox>
                                    </v-flex>

                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type == 'WAB'">
                                        <v-select v-model="addCampaign.payload.wa_type" :items="wa_types"
                                            label="WhatApp Type" item-text="name" item-value="value" return-string
                                            @change="Watype" :rules="Fieldrules.wa_type"></v-select>
                                    </v-flex>
                                    <v-flex xs6
                                        v-if="this.addCampaign.payload.call_type == 'WAB' && this.addCampaign.payload.wa_type !== ''">
                                        <v-select v-model="addCampaign.payload.wa_template" :items="Watemplatedata"
                                            label="WhatApp Template" item-text="name" item-value="name" return-string
                                            @change="tempdata" :rules="Fieldrules.wa_template"></v-select>
                                    </v-flex>
                                    <v-flex xs6
                                        v-if="this.addCampaign.payload.call_type == 'WAB' && this.addCampaign.payload.wa_type">
                                        <v-select v-model="addCampaign.payload.wa_lang" :items="walang"
                                            label="WhatApp Language" item-text="name" item-value="value" return-string
                                            @change="Walink($event)" :rules="Fieldrules.wa_lang"></v-select>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.addCampaign.payload.call_type !== 'WAB'">
                                        <v-checkbox v-model="addCampaign.payload.timezone" label="Timezone"
                                            color="primary"></v-checkbox>
                                    </v-flex>
                                    <v-flex>
                                    </v-flex>
                                   
                                </v-layout>
                                <v-flex xs6>
                                        <v-text-field ref="campaignNameField"
                                            v-model="addCampaign.payload.campaign_name" required label="Name"
                                            type="text" :rules="Fieldrules.campaign_name"></v-text-field>
                                    </v-flex>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="modal-footer--sticky">
                        <v-spacer></v-spacer>
                        <v-btn color="light" type="reset" flat @click="closeAddPausecode()">Close</v-btn>
                        <v-btn :disabled="!valid" color="primary" @click="saveAddAstercampaign()">Create</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <template>
            <v-dialog persistent :value="page.isEditCampaign" scrollable max-width="800px">
                <v-card>
                    <v-toolbar dark color="primary" class="modal-header--sticky">
                        <v-card-title>
                            <span class="headline">Update Campaign</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text style="height: 500px">
                        <v-form ref="Updateform" v-model="valid1">
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    
                                    <v-flex xs6>
                                        <v-autocomplete v-model="editCampaign.did_rotate_strategy"
                                            :items="addCampaign.options.did_strategy" label="Did Rotate Strategy"
                                            item-text="name" item-value="value" required
                                            @change="typechange($event, 'Did_Rot_St')"></v-autocomplete>

                                    </v-flex>
                                    <v-flex
                                        v-if="editCampaign.did_rotate_strategy === 'random' || editCampaign.did_rotate_strategy === 'manual'"
                                        xs6>
                                        <v-autocomplete v-model="editCampaign.did_routing_numbers"
                                            :items="addCampaign.options.didnumber" label="Did Rotate Numbers"
                                            item-text="name" item-value="value" required
                                            :rules="Fieldrules.did_routing_numbers" multiple></v-autocomplete>

                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select multiple v-model="editCampaign.campaign_process"
                                            :items="addCampaign.options.process_values" label="Process" item-text="name"
                                            item-value="value" return-string required persistent-hint
                                            :rules="Fieldrules.campaign_process"></v-select>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select v-model="editCampaign.primary_list"
                                            :items="addCampaign.options.primary_list_value" label="Primary list"
                                            item-text="name" item-value="value" return-string required
                                            persistent-hint></v-select>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select v-model="editCampaign.call_type" :items="addCampaign.options.type"
                                            label="Type" item-text="name" item-value="value" return-string
                                            persistent-hint required :rules="Fieldrules.call_type"
                                            @change="typechange($event, 'Type')"></v-select>
                                    </v-flex>
                                    <v-flex xs6>
                                        <div class="autocompleteDiv">
                                            <v-autocomplete v-model="editCampaign.caller_id"
                                                :items="addCampaign.options.didnumber" label="Outbound Caller ID"
                                                persistent-hint :disabled="editCampaign.call_type === ''" small required
                                                :multiple="multipleSelect.caller_id" :rules="Fieldrules.caller_id">
                                            </v-autocomplete>

                                        </div>
                                    </v-flex>


                                    <v-flex xs6>
                                        <div class="autocompleteDiv">
                                            <v-select v-model="editCampaign.Industryvalue" :items="Industrylist"
                                                label="Industry" persistent-hint small :rules="Fieldrules.Industryvalue"
                                                @change="editIndustryvalues(editCampaign.Industryvalue)">
                                            </v-select>
                                        </div>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select v-model="editCampaign.template_name" :items="edittemplateoptions"
                                            label="Template Name" return-string persistent-hint required
                                            @change="editdomainmap(editCampaign.template_name)"
                                            :rules="Fieldrules.template_name">
                                        </v-select>
                                    </v-flex>
                                    <v-flex xs6>
                                        <div class="autocompleteDiv">
                                            <v-select v-model="editCampaign.workflowvalues" :items="editdomainlist"
                                                label="Domain" persistent-hint small :rules="Fieldrules.workflowvalues"
                                                @change="editworkFlowvalues(editCampaign.workflowvalues)
                                                    ">
                                            </v-select>
                                        </div>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type !== 'WAB'">
                                        <div class="autocompleteDiv">
                                            <v-autocomplete v-model="editCampaign.default_queue"
                                                :items="addCampaign.options.add_queue" label="Add Queue" persistent-hint
                                                small multiple required
                                                :rules="Fieldrules.default_queue"></v-autocomplete>
                                        </div>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type !== 'WAB'">
                                        <div class="autocompleteDiv">
                                            <v-autocomplete v-model="editCampaign.pause_code"
                                                :items="addCampaign.options.pause_code" label="Pause Code"
                                                persistent-hint small multiple required
                                                :rules="Fieldrules.pause_code"></v-autocomplete>
                                        </div>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type !== 'WAB'">
                                        <div class="autocompleteDiv">
                                            <v-autocomplete v-model="editCampaign.dial_statuses"
                                                :items="addCampaign.options.filterdispo" label="Dial Status"
                                                persistent-hint small multiple required
                                                :rules="Fieldrules.dial_statuses"></v-autocomplete>
                                        </div>
                                    </v-flex>

                                    <v-flex xs6 v-if="this.editCampaign.call_type !== 'WAB'">
                                        <div class="autocompleteDiv">
                                            <v-autocomplete v-model="editCampaign.dispo_statuses"
                                                :items="addCampaign.options.filterdispo" label="Dispo Status"
                                                persistent-hint small multiple required
                                                :rules="Fieldrules.dispo_statuses">
                                            </v-autocomplete>
                                        </div>
                                    </v-flex>

                                    <v-flex xs6 v-if="this.editCampaign.call_type == 'AUTO' ||
                                        this.editCampaign.call_type == 'VB'
                                    ">
                                        <v-select v-model="editCampaign.hooper_level"
                                            :items="addCampaign.options.buffer_level" label="Buffer Level"
                                            item-text="name" item-value="value" return-string persistent-hint required
                                            :rules="Fieldrules.hooper_level"></v-select>

                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type !== 'WAB'
                                    ">
                                        <div class="autocompleteDiv">
                                            <v-autocomplete v-model="editCampaign.ivr_name"
                                                :items="addCampaign.options.inbound" label="Inbound" persistent-hint
                                                small :rules="Fieldrules.ivr_name" required></v-autocomplete>
                                        </div>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type == 'AUTO'">
                                        <v-select v-model="editCampaign.dial_ratio"
                                            :items="addCampaign.options.dial_ratio" label="Dial Ratio" item-text="name"
                                            item-value="value" return-string persistent-hint required
                                            :rules="Fieldrules.dial_ratio"></v-select>
                                    </v-flex>

                                    <!-- <v-flex xs6 v-if="
                                        this.editCampaign.call_type !== 'WAB'
                                    ">
                                            <div class="autocompleteDiv">
                                                <v-autocomplete v-model="editCampaign.ivr_name"
                                                    :items="addCampaign.options.inbound" label="Inbound" persistent-hint
                                                    small></v-autocomplete>
                                            </div>
                                    </v-flex> -->
                                    <v-flex xs6 v-if="this.editCampaign.call_type == 'VB'">
                                        <v-select v-model="editCampaign.channel_count"
                                            :items="addCampaign.options.channels" label="Channels" item-text="name"
                                            item-value="value" return-string persistent-hint required
                                            :rules="Fieldrules.channel_count"></v-select>
                                    </v-flex>
                                    <v-flex xs6
                                        v-if="this.editCampaign.call_type == 'VB' || this.editCampaign.call_type == 'WAB'">
                                        <div>
                                            <v-menu ref="menu" v-model="menu2" :close-on-content-click="false"
                                                :nudge-right="40" :return-value.sync="editCampaign.start_time"
                                                transition="scale-transition" offset-y max-width="290px"
                                                min-width="290px">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="editCampaign.start_time" label="Start Time"
                                                        v-bind="attrs" v-on="on" :rules="Fieldrules.start_time"
                                                        :readonly="!menu2">
                                                    </v-text-field>
                                                </template>
                                                <v-time-picker color="primary" format="24hr" v-if="menu2"
                                                    v-model="editCampaign.start_time" full-width @click:minute="$refs.menu.save(editCampaign.start_time)
                                                        ">
                                                </v-time-picker>
                                            </v-menu>
                                        </div>
                                        <div>
                                            <v-menu ref="menu1" v-model="menu3" :close-on-content-click="false"
                                                :nudge-right="40" :return-value.sync="editCampaign.end_time"
                                                transition="scale-transition" offset-y max-width="290px"
                                                min-width="290px">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="editCampaign.end_time" label="End Time"
                                                        v-bind="attrs" v-on="on" :rules="Fieldrules.end_time"
                                                        :readonly="!menu3">
                                                    </v-text-field>
                                                </template>
                                                <v-time-picker color="primary" format="24hr" v-if="menu3"
                                                    v-model="editCampaign.end_time" full-width @click:minute="$refs.menu1.save(editCampaign.end_time)
                                                        ">
                                                </v-time-picker>
                                            </v-menu>
                                        </div>
                                    </v-flex>
                                    <v-flex xs6
                                        v-if="this.editCampaign.call_type == 'VB' || this.editCampaign.call_type == 'WAB'">
                                        <v-autocomplete v-model="editCampaign.days" :items="addCampaign.options.days"
                                            label="Days" item-text="name" item-value="value" persistent-hint small
                                            required multiple></v-autocomplete>
                                    </v-flex>

                                    <v-flex xs6>
                                        <v-text-field v-model="editCampaign.dial_prefix" label="Dial Prefix"
                                            type="number"></v-text-field>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-text-field v-model="editCampaign.wrap_time" label="Wrap Time (in sec)"
                                            type="number" min="0" :rules="Fieldrules.wrap_time">
                                        </v-text-field>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type !== 'WAB'">
                                        <v-select v-model="editCampaign.script_name" :items="script_name"
                                            label="Script_name" item-text="name" item-value="value"
                                            :rules="Fieldrules.script_name" return-string></v-select>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type !== 'WAB'">
                                        <v-checkbox v-model="editCampaign.call_recording" color="primary"
                                            :label="`On Demand Recording`"></v-checkbox>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type !== 'WAB'">
                                        <v-checkbox v-model="editCampaign.masking" color="primary"
                                            :label="`Call Masking`"></v-checkbox>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type !== 'WAB'">
                                        <v-checkbox v-model="editCampaign.dnc_check" color="primary"
                                            :label="`DNC check`"></v-checkbox>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type !== 'WAB'">
                                        <v-checkbox v-model="editCampaign.auto_answer" color="primary"
                                            :label="`Auto Answer`">
                                        </v-checkbox>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type !== 'WAB'">
                                        <v-checkbox v-model="editCampaign.auto_dispo" color="primary"
                                            :label="`Auto Dispo`">
                                        </v-checkbox>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type == 'WAB'">
                                        <v-select v-model="editCampaign.wa_type" :items="wa_types" label="WhatApp Type"
                                            item-text="name" item-value="value" return-string @change="Watype"
                                            :rules="Fieldrules.wa_type"></v-select>
                                    </v-flex>
                                    <v-flex xs6
                                        v-if="this.editCampaign.call_type == 'WAB' && this.editCampaign.wa_type !== ''">
                                        <v-select v-model="editCampaign.wa_template" :items="Watemplatedata"
                                            label="WhatApp Template" item-text="name" item-value="name" return-string
                                            @change="tempdata" :rules="Fieldrules.wa_template"></v-select>
                                    </v-flex>

                                    <v-flex xs6
                                        v-if="this.editCampaign.call_type == 'WAB' && this.editCampaign.wa_type !== ''">
                                        <v-select v-model="editCampaign.wa_lang" :items="walang"
                                            label="WhatApp Language" item-text="name" item-value="value"
                                            @change="UpdateWalink($event)" return-string
                                            :rules="Fieldrules.wa_lang"></v-select>
                                    </v-flex>
                                    <v-flex xs6 v-if="this.editCampaign.call_type !== 'WAB'">
                                        <v-checkbox v-model="editCampaign.timezone" label="Timezone"
                                            color="primary"></v-checkbox>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select v-model="editCampaign.active" :items="addCampaign.options.status"
                                            label="Status" item-text="name" item-value="value" return-string
                                            persistent-hint required :rules="Fieldrules.active"></v-select>
                                    </v-flex>
                                   
                                </v-layout>
                                <v-flex xs6>
                                        <v-text-field v-model="editCampaign.campaign_name" required label="Name"
                                            type="text" readonly :rules="Fieldrules.campaign_name"></v-text-field>
                                    </v-flex>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="modal-footer--sticky">
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
                        <v-btn :disabled="!valid1" color="primary" flat @click="saveEditRules()">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
            <v-toolbar small color="secondary" style="color: white">
                <v-toolbar-title>Filter</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon flat color="white" @click="page.isFilterOpen = false">
                    <i class="fas fa-close"></i>
                </v-btn>
            </v-toolbar>
            <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
                <v-container class="sidenavContainer">
                    <div class="autocompleteDiv">
                        <v-autocomplete v-model="filter.payload.campaign_name" :items="filtervalue"
                            :loading="filter.isLoading" :search-input.sync="filter.search" label="Name" hide-no-data
                            hide-selected></v-autocomplete>
                    </div>

                    <!-- /////////two more filter is added by abdul//////// -->
                    <div class="autocompleteDiv">
                        <v-select v-model="filter.payload.campaign_type" :items="filter.options.type" label="Type"
                            item-text="name" item-value="value" return-string required persistent-hint></v-select>
                    </div>
                    <div class="autocompleteDiv">
                        <v-select v-model="filter.payload.campaign_status" :items="filter.options.status" label="Status"
                            item-text="name" item-value="value" return-string persistent-hint></v-select>
                    </div>
                </v-container>
                <v-container row class="sidenavFooter">
                    <v-btn flat @click="reset()">Reset</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
                </v-container>
            </div>
        </v-navigation-drawer>
        <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
        <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import loader from "@/components/Widgets/loader";
//import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
    components: {
        loader,
    },

    data() {
        return {
            time: null,
            time3: null,
            menu2: false,
            menu3: false,
            menuAdd2: false,
            menuAdd3: false,

            modal2: false,
            model3: false,
            valid: true,
            valid1: true,
            disabled: [],
            styleObject: {
                height: "80px",
                "margin-top": "-8px",
            },
            pause_code: [],
            // ivr_name:[],
            edittemplateoptions: [],
            script_name: [],
            selected: { name: "ACTIVE", value: "Y" },
            page: {
                title: "Campaign",
                icon: "fa-bullhorn fa-2x",
                loading: false,
                isAddCampaign: false,
                isFilterOpen: false,
                playingRecord: false,
                breadcrumbs: [],
                exportOptions: [],
                options: { filter: true, refresh: true },
                addnew: [],
                isEditCampaign: false,
                temp: { key: "addastercampaign", label: "Add AsterCampaign" },
                candelete: false,
                canedit: false,
                isVoiceBlast: false
            },
            pagecount: 0,
            Industrylist: [],
            domainlist: [],
            editdomainlist: [],
            headers: [
                {
                    text: "Name",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "campaign_name",
                },
                {
                    text: "Industry",
                    align: "left",
                    sortable: false,
                    class: "p0",
                    value: "btn",
                },
                {
                    text: "Domain",
                    align: "left",
                    sortable: false,
                    class: "p0",
                    value: "btn",
                },
                {
                    text: "Template_Name",
                    align: "left",
                    sortable: false,
                    class: "p0",
                    value: "btn",
                },
                {
                    text: "Type",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "call_type",
                },
                {
                    text: "Active",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "active",
                },
                {
                    text: "Buffer level",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "hooper_level",
                },
                {
                    text: "Dial Ratio",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "dial_ratio",
                },
                {
                    text: "Action",
                    align: "center",
                    sortable: false,
                    class: "p0",
                    value: "btn",
                },
            ],
            campaignData: [],
            dispo_status: ["new", "Answer", "No Answer"],
            //pagecount: 0,
            pagination: {
                rowsPerPage: 10,
            },
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            DeleteRowdata: false,
            showRowData: false,
            DataRow: false,
            tableLoading: false,
            crmdomain: "",
            filterdispo: [],
            inbound: [],
            didnumber: [],
            filtervalue: [],
            voiceblast: {
                phone_no: "",
                campaign_name: "",
                user: ""
            },
            filter: {
                payload: {
                    campaign_name: "",
                    campaign_type: "",
                    campaign_status: "",
                },
                options: {
                    type: [
                        { name: "POWER", value: "POWER" },
                        { name: "PREDICTIVE", value: "AUTO" },
                        { name: "VOICE BLAST", value: "VB" },
                        // { name: "WHATSAPP BLAST", value: "WAB" },
                        { name: "PREVIEW", value: "PREVIEW" },
                        { name: "INBOUND", value: "INBOUND" },
                    ],
                    days: [{ name: "Sunday", value: "sunday" },
                    { name: "Monday", value: "monday" },
                    { name: "Tuesday", value: "tuesday" },
                    { name: "Wednesday", value: "wednesday" },
                    { name: "Thursday", value: "thursday" },
                    { name: "Friday", value: "friday" },
                    { name: "Saturday", value: "saturday" },
                    ],
                    status: ["Active", "Inactive"],
                },
                isLoading: false,
                search: "",
            },
            // pagination: {
            // page: 0,
            // rowsPerPage: 5
            // },
            isProcessing: false,
            call_status: [],
            editCampaign: {
                Industryvalue: "",
                workflowvalues: "",
                primary_list: "",
                did_rotate_strategy: '',
                did_routing_numbers: [],
                campaign_process: [],
                campaign_name: "",
                dial_statuses: "",
                caller_id: "",
                ivr_name: "",
                default_queue: "",
                call_type: "",
                pause_code: "",
                dispo_statuses: "",
                active: "",
                hooper_level: "",
                dnc_check: false,
                auto_answer: false,
                auto_dispo: false,
                masking: false,
                call_recording: false,
                dial_ratio: "",
                channel_count: "",
                template_name: "",
                dial_prefix: "",
                wrap_time: 5,
                script_name: "",
                start_time: null,
                end_time: null,
                wa_type: "",
                wa_template: "",
                wa_lang: "",
                wa_link: "",
                timezone: false,
                days: []
            },
            addCampaign: {
                valid: false,
                payload: {
                    campaign_name: "",
                    primary_list: "",
                    campaign_process: [],
                    did_rotate_strategy: "",
                    did_routing_numbers: [],
                    dial_statuses: [],
                    ivr_name: "",
                    caller_id: "",
                    default_queue: [],
                    workflowvalues: "",
                    Industryvalue: "",
                    call_type: "",
                    pause_code: [],
                    dispo_statuses: [],
                    active: { name: "ACTIVE", value: "Y" },
                    hooper_level: "",
                    dnc_check: false,
                    auto_answer: false,
                    auto_dispo: false,
                    masking: false,
                    call_recording: false,
                    dial_ratio: "",
                    channel_count: "",
                    template_name: "",
                    dial_prefix: "",
                    wrap_time: 5,
                    script_name: "",
                    start_time: null,
                    end_time: null,
                    wa_type: "",
                    wa_template: "",
                    wa_lang: "",
                    wa_link: "",
                    timezone: false,
                    days: [],
                },
                apivalue: [],
                options: {
                    // dial_status: filters.dispo_status,
                    primary_list_value: [],
                    add_queue: [],
                    pause_code: [],
                    filterdispo: [],
                    inbound: [],
                    didnumber: [],
                    type: [
                        { name: "POWER", value: "POWER" },
                        { name: "PREDICTIVE", value: "AUTO" },
                        { name: "VOICE BLAST", value: "VB" },
                        // { name: "WHATSAPP BLAST", value: "WAB" },
                        { name: "PREVIEW", value: "PREVIEW" },
                        { name: "INBOUND", value: "INBOUND" },
                    ], process_values: [
                        // { name: "POWER", value: "power" },
                        { name: "Leads", value: "leads" },
                        { name: "Tickets", value: "tickets" },
                        { name: "Meetings", value: "meetings" }
                    ],
                    did_strategy: [
                        // { name: "POWER", value: "power" },
                        { name: "Direct", value: "direct" },
                        { name: "Random", value: "random" },
                        { name: "Agent", value: "agent" },
                        { name: "Manual", value: "manual" },
                    ],
                    days: [{ name: "Sunday", value: "0" },
                    { name: "Monday", value: "1" },
                    { name: "Tuesday", value: "2" },
                    { name: "Wednesday", value: "3" },
                    { name: "Thursday", value: "4" },
                    { name: "Friday", value: "5" },
                    { name: "Saturday", value: "6" },
                    ],
                    // pause_code: status,
                    // dispo_status: filters.dispo_status,
                    status: [
                        { status_id: 1, name: "ACTIVE", value: "Y" },
                        { status_id: 2, name: "INACTIVE", value: "N" },
                    ],
                    buffer_level: [
                        { name: "5", value: "5" },
                        { name: "10", value: "10" },
                        { name: "50", value: "50" },
                        { name: "100", value: "100" },
                        { name: "500", value: "500" },
                        { name: "1000", value: "1000" },
                        { name: "2000", value: "2000" },
                    ],
                    //////// dail ratio value changed by abdul//////
                    dial_ratio: [
                        { name: "0", value: "0" },
                        { name: "1", value: "1" },
                        { name: "1.2", value: "1.2" },
                        { name: "1.4", value: "1.4" },
                        { name: "1.6", value: "1.6" },
                        { name: "1.8", value: "1.8" },
                        { name: "2", value: "2" },
                        { name: "2.2", value: "2.2" },
                        { name: "2.4", value: "2.4" },
                        { name: "2.6", value: "2.6" },
                        { name: "2.8", value: "2.8" },
                        { name: "3", value: "3" },
                        { name: "3.2", value: "3.2" },
                        { name: "3.4", value: "3.4" },
                        { name: "3.6", value: "3.6" },
                        { name: "3.8", value: "3.8" },
                        { name: "4", value: "4" },
                        { name: "4.2", value: "4.2" },
                        { name: "4.4", value: "4.4" },
                        { name: "4.6", value: "4.6" },
                        { name: "4.8", value: "4.8" },
                        { name: "5", value: "5" },
                        { name: "6", value: "6" }, { name: "7", value: "7" }, { name: "8", value: "8" }, { name: "9", value: "9" }, { name: "10", value: "10" }, { name: "11", value: "11" }, { name: "12", value: "12" }, { name: "13", value: "13" }, { name: "14", value: "14" }, { name: "15", value: "15" }, { name: "16", value: "16" }, { name: "17", value: "17" }, { name: "18", value: "18" }, { name: "19", value: "19" }, { name: "20", value: "20" }
                    ],
                    channels: [
                        { name: "5", value: "5" },
                        { name: "10", value: "10" },
                        { name: "15", value: "15" },
                        { name: "20", value: "20" },
                        { name: "25", value: "25" },
                        { name: "30", value: "30" },
                        { name: "35", value: "35" },
                        { name: "40", value: "40" },
                        { name: "45", value: "45" },
                        { name: "50", value: "50" },
                    ],
                    templates: [],
                },
            },
            wa_types: [{
                name: "Text", value: "text"
            }, {
                name: "Video", value: "video"
            }, {
                name: "Image", value: "image"
            }, {
                name: "Document", value: "document"
            }],
            walang: [],
            Watemplatedata: [],
            wafulldata: [],
            Fieldrules: {
                campaign_name: [(v) => /^\S+$/.test(v) || 'Campaign Name should not contain spaces', (v) => /^[a-zA-Z0-9_]+$/.test(v) || 'Campaign Name should not contain special characters',(v) => !!v || 'Campaign Name is required',(v) =>  ( v && v.length <= 60 ) || 'Campaign Name must be 60 characters or less'],
                wrap_time:[
                    v => !!v || 'Wrap time is required and Only be a positive number',
                    v => /^[0-9]\d*$/.test(v) || 'Wrap time is required and Only be a positive number'
                ],
                campaign_process: [(v) => (Array.isArray(v) && v.length > 0) || 'Campaign Process is required'],
                did_routing_numbers: [(v) => (Array.isArray(v) && v.length > 0) || 'Did routing numbers is required'],
                primary_list: [],
                call_type: [(v) => !!v || 'Type is required'],
                caller_id: [],
                Industryvalue: [(v) => !!v || 'Industry is required'],
                template_name: [(v) => !!v || 'Template Name is required'],
                workflowvalues: [(v) => !!v || 'Domain is required'],
                default_queue: [],
                pause_code: [],
                dial_statuses: [(v) => (Array.isArray(v) && v.length > 0) || 'Dial Status is required'],
                dispo_statuses: [],
                hooper_level: [],
                call_recording: [],
                dial_ratio: [],
                ivr_name: [],
                channel_count: [],
                start_time: [],
                end_time: [],
                days: [],
                dial_prefix: [],
                script_name: [(v) => !!v || 'Script is required'],
                dnc_check: [],
                masking: [],
                wa_type: [],
                wa_template: [],
                wa_lang: [],
                timezone: [],
                active: [(v) => !!v || 'Status is required']
            },
            FieldIsShow: {
                campaign_name: false,
                campaign_process: false,
                primary_list: false,
                call_type: false,
                caller_id: false,
                Industryvalue: false,
                template_name: false,
                workflowvalues: false,
                default_queue: false,
                pause_code: false,
                dial_statuses: false,
                dispo_statuses: false,
                hooper_level: false,
                call_recording: false,
                dial_ratio: false,
                ivr_name: false,
                channel_count: false,
                start_time: false,
                end_time: false,
                days: false,
                dial_prefix: false,
                script_name: false,
                dnc_check: false,
                masking: false,
                wa_type: false,
                wa_template: false,
                wa_lang: false,
                timezone: false,
                active: false
            },
            multipleSelect: {
                caller_id: false
            },
            teams: JSON.parse(localStorage.getItem("Team"))
        };
    },
    methods: {
        FormValidation(newVal) {
            let scope = this
            if (newVal === 'AUTO' || newVal === 'INBOUND') {
                scope.Fieldrules.default_queue = [(v) => (Array.isArray(v) && v.length > 0) || 'Queue is required'];
            } else {
                scope.Fieldrules.default_queue = [];
            }
            const requiresCallCenterFields = ['AUTO', 'INBOUND', 'PREVIEW', 'POWER'].includes(newVal);
            if (requiresCallCenterFields) {
                scope.Fieldrules.pause_code = [(v) => (Array.isArray(v) && v.length > 0) || 'Pause Code is required'];
                scope.Fieldrules.dispo_statuses = [(v) => (Array.isArray(v) && v.length > 0) || 'Dispo Status is required'];
                scope.Fieldrules.ivr_name = [(v) => !!v || 'Inbound Caller ID is required'];
            } else {
                scope.Fieldrules.pause_code = [];
                scope.Fieldrules.dispo_statuses = [];
                scope.Fieldrules.ivr_name = [];
            }
            let required = ['VB', 'PREVIEW'];
            const noRequiredFields = new Set(['random', 'manual', 'agent']);
            if (noRequiredFields.has(this.addCampaign.payload.did_rotate_strategy)) {
                required = [];
            } else if (this.addCampaign.payload.did_rotate_strategy === "direct" || this.editCampaign.did_rotate_strategy === "direct") {
                required = ['VB', 'PREVIEW', 'POWER', 'AUTO', 'INBOUND'];
            }

            const requiresCallerId = required.includes(newVal);
            const notAllowedStrategies = new Set(['random', 'manual', 'agent', 'direct', '']);
            if (((newVal === 'POWER' || newVal === 'AUTO') && !notAllowedStrategies.has(this.addCampaign.payload.did_rotate_strategy))
                || (newVal === 'POWER' || newVal === 'AUTO') && !notAllowedStrategies.has(this.editCampaign.did_rotate_strategy)) {
                scope.Fieldrules.caller_id = [(v) => (Array.isArray(v) && v.length > 0) || 'Outbound Caller ID is required'];
            } else if (requiresCallerId) {
                scope.Fieldrules.caller_id = [(v) => !!v || 'Outbound Caller ID is required'];
            } else {
                scope.Fieldrules.caller_id = [];
            }

            const requiresTime = ['VB', 'WAB'].includes(newVal);
            if (requiresTime) {
                scope.Fieldrules.start_time = [(v) => {
                    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
                    if (!timeRegex.test(v)) {
                        return 'Start Time is required'
                    }
                }];
                scope.Fieldrules.end_time = [(v) => {
                    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
                    if (!timeRegex.test(v)) {
                        return 'End Time is required'
                    }
                }];
            } else {
                scope.Fieldrules.start_time = [];
                scope.Fieldrules.end_time = [];
            }
            const nonStrategies = new Set(['random', 'manual', 'agent', '']);

            if ((this.addCampaign.payload.did_rotate_strategy.trim().length > 0 || this.editCampaign.did_rotate_strategy.trim().length > 0)
                && (this.addCampaign.payload.did_rotate_strategy !== "direct" || this.editCampaign.did_rotate_strategy !== "direct")) {
                scope.multipleSelect.caller_id = ['AUTO', 'POWER'].includes(newVal);
            }

            if (nonStrategies.has(this.addCampaign.payload.did_rotate_strategy) || nonStrategies.has(this.editCampaign.did_rotate_strategy)) {
                scope.multipleSelect.caller_id = false;
            }

            if (newVal === "WAB") {
                scope.Fieldrules.wa_type = [(v) => !!v || 'Whats App Type is required']
                scope.Fieldrules.wa_template = [(v) => !!v || 'Whats App Template is required']
                scope.Fieldrules.wa_lang = [(v) => !!v || 'Whats App Lang is required']
            } else {
                scope.Fieldrules.wa_type = []
                scope.Fieldrules.wa_template = []
                scope.Fieldrules.wa_lang = []
            }

            const requiresDialRatio = ['AUTO', 'VB'].includes(newVal);
            if (requiresDialRatio) {
                scope.Fieldrules.dial_ratio = [(v) => !!v || 'Dial Ratio is required'];
                scope.Fieldrules.channel_count = [(v) => !!v || 'Channels is required'];
                scope.Fieldrules.hooper_level = [(v) => !!v || 'Buffer Level is required'];
            } else {
                scope.Fieldrules.dial_ratio = [];
                scope.Fieldrules.channel_count = [];
                scope.Fieldrules.hooper_level = [];
            }

            this.$refs.Createform.resetValidation();
            this.$refs.Updateform.resetValidation();
        }
        ,
        fulltemplate: function (value) {
            this.apivalue[0].forEach((xx) => {
                if (xx.industry == value) {
                    this.addCampaign.options.templates.push(xx.template_name);
                }
            });
        },
        domainmap: function (value) {
            const found = this.apivalue[0].find((element) => {
                if (
                    element.template_name == value &&
                    element.industry == this.addCampaign.payload.Industryvalue
                ) {
                    return element.type;
                }
            });
            this.addCampaign.payload.workflowvalues = found.type;
        },
        editdomainmap: function (value) {
            this.editCampaign.workflowvalues = "";
            const found = this.apivalue[0].find((element) => {
                if (
                    element.template_name == value &&
                    element.industry == this.editCampaign.Industryvalue
                ) {
                    return element.type;
                }
            });
            this.editCampaign.workflowvalues = found.type;
        },
        Industryvalues: function (value) {
            this.addCampaign.payload.workflowvalues = "";
            this.domainlist = [];

            this.addCampaign.options.templates = [];
            this.edittemplateoptions = [];

            this.apivalue[0].forEach((xx) => {
                if (xx.industry == value) {
                    this.domainlist.push(xx.type);
                }
            });

            this.fulltemplate(value);
        },
        editIndustryvalues: function (value) {
            this.editdomainlist.length = 0;
            this.addCampaign.options.templates = [];
            this.edittemplateoptions = [];
            this.apivalue[0].forEach((xx) => {
                if (xx.industry == value) {
                    this.editdomainlist.push(xx.type);
                }
            });
            this.editfulltemplate(value);
        },
        editfulltemplate: function (value) {
            this.apivalue[0].forEach((element) => {
                if (element.industry == value) {
                    this.edittemplateoptions.push(element.template_name);
                }
            });
        },
        workFlowvalues: function (value) {
            this.addCampaign.options.templates = [];
            this.apivalue[0].forEach((xx) => {
                if (
                    xx.type == value &&
                    xx.industry == this.addCampaign.payload.Industryvalue
                ) {
                    this.addCampaign.options.templates.push(xx.template_name);
                }
            });
        },
        editworkFlowvalues: function (value) {
            if (value.data) {
                this.apivalue[0].forEach((xx) => {
                    if (
                        xx.type == value.workflowvalues &&
                        xx.industry == value.Industryvalue
                    ) {
                        this.edittemplateoptions.push(xx.template_name);
                    }
                });
            } else {
                this.edittemplateoptions.length = 0;

                this.apivalue[0].forEach((xx) => {
                    if (
                        xx.type == value &&
                        xx.industry == this.editCampaign.Industryvalue
                    ) {
                        this.edittemplateoptions.push(xx.template_name);
                    }
                });
            }
        },

        onbufferChange: function (e, item) {
            if (this.editCampaign.timezone == true) {
                this.editCampaign.timezone = 'Y'
            } else {
                this.editCampaign.timezone = 'N'
            }
            this.editCampaign.id = item.id;
            this.editCampaign.campaign_process = item.process
            this.editCampaign.campaign_name = item.campaign_name;
            this.editCampaign.did_routing_numbers = item.did_routing_numbers === null ? "" : item.did_routing_numbers;
            this.editCampaign.did_rotate_strategy = item.did_rotate_strategy;
            this.editCampaign.dial_statuses = item.dial_statuses;
            this.editCampaign.caller_id = item.didnumber;
            this.editCampaign.ivr_name = item.inbound;
            this.editCampaign.default_queue = item.default_queue;
            this.editCampaign.call_type = item.call_type;
            this.editCampaign.pause_code = item.pause_code;
            this.editCampaign.dispo_statuses = item.dispo_statuses;
            this.editCampaign.active = item.active;
            this.editCampaign.dnc_check = item.dnc_check;
            this.editCampaign.masking = item.masking;
            this.editCampaign.call_recording = item.call_recording;
            this.editCampaign.dial_ratio = item.dial_ratio;
            this.editCampaign.channel_count = item.channel_count;
            this.editCampaign.template_name = item.template_name;
            this.editCampaign.dial_prefix = item.dial_prefix;
            this.editCampaign.script_name = item.script_name;
            this.editCampaign.Industryvalue = item.industry;
            this.editCampaign.workflowvalues = item.Domain;
            this.editCampaign.wa_type = item.wa_type
            this.editCampaign.wa_template = item.wa_template
            this.editCampaign.wa_lang = item.wa_lang
            this.editCampaign.wa_link = item.wa_link
            this.editCampaign.end_time = item.end_time
            this.editCampaign.start_time = item.start_time
            this.editCampaign.hooper_level = e;

            var scope = this;
            scope.$store
                .dispatch("updateAsterCampaign", scope.editCampaign)
                .then(() => {
                    scope.page.isAddCampaign = false;
                    scope.showSnackBar("Updated Successfully");
                    this.page.isEditCampaign = false;

                    scope.getDataFromApi();
                })
                .catch(function (error) {
                    scope.page.isAddCampaign = false;
                    scope.showSnackBar("error" + error);
                    this.page.isEditCampaign = false;
                });
            scope.getDataFromApi();
        },

        onratioChange: function (e, item) {
            this.editCampaign.id = item.id;
            this.editCampaign.campaign_process = item.process
            this.editCampaign.campaign_name = item.campaign_name;
            this.editCampaign.dial_statuses = item.dial_statuses;
            this.editCampaign.did_routing_numbers = item.did_routing_numbers === null ? "" : item.did_routing_numbers;
            this.editCampaign.did_rotate_strategy = item.did_rotate_strategy;
            this.editCampaign.caller_id = item.didnumber;
            this.editCampaign.ivr_name = item.inbound;
            this.editCampaign.default_queue = item.default_queue;
            this.editCampaign.call_type = item.call_type;
            this.editCampaign.pause_code = item.pause_code;
            this.editCampaign.dispo_statuses = item.dispo_statuses;
            this.editCampaign.active = item.active;
            this.editCampaign.dnc_check = item.dnc_check;
            this.editCampaign.masking = item.masking;
            this.editCampaign.call_recording = item.call_recording;
            this.editCampaign.channel_count = item.channel_count;
            this.editCampaign.template_name = item.template_name;
            this.editCampaign.dial_prefix = item.dial_prefix;
            this.editCampaign.hooper_level = item.hooper_level;
            this.editCampaign.Industryvalue = item.industry;
            this.editCampaign.workflowvalues = item.Domain;
            this.editCampaign.script_name = item.script_name;
            this.editCampaign.wa_type = item.wa_type
            this.editCampaign.wa_template = item.wa_template
            this.editCampaign.wa_lang = item.wa_lang
            this.editCampaign.wa_link = item.wa_link
            this.editCampaign.dial_ratio = e;

            var scope = this;
            scope.$store
                .dispatch("updateAsterCampaign", scope.editCampaign)
                .then(() => {
                    scope.page.isAddCampaign = false;
                    scope.showSnackBar("Updated Successfully");
                    this.page.isEditCampaign = false;
                })
                .catch(function (error) {
                    scope.page.isAddCampaign = false;
                    scope.showSnackBar("error" + error);
                    this.page.isEditCampaign = false;
                });
            scope.getDataFromApi();
        },
        refreshThisPage: function () {
            var scope = this;
            scope.pagination.page = 1;
            scope.page.loading = true;
            scope.filter.payload.campaign_name = "";
            scope.filter.payload.campaign_type = "";
            scope.filter.payload.campaign_status = "";
            scope.pagination.page = 1;
            scope.getDataFromApi();
        },
        filterClick: function () {
            this.page.isFilterOpen = false;
            this.page.isFilterOpen = true;
        },
        filterFieldData() {
            let scope = this;
            let data = { model: "campaigns", column: ["campaign_name"] };

            if (this.teams.length > 0) {
                data.team = this.teams.map(elm => elm.name)
            }
            this.$store
                .dispatch("GetModuleNameList", data)
                .then((response) => {
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    var datas = response.data;
                    this.filtervalue = datas[0].campaign_name;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        reset() {
            let scope = this;
            scope.filter.payload.campaign_name = "";
            scope.filter.payload.campaign_type = "";
            scope.filter.payload.campaign_status = "";
            scope.getDataFromApi();
            this.page.isFilterOpen = true;
        },
        /*fetchFilter() {
        //filter changed by abdul
        // Using ternary operator instead of if condition
        var scope = this;
        scope.tableLoading = true;
        let status = "";
        if (scope.filter.payload.campaign_status == "Active") {
        status = "Y";
        }
        if (scope.filter.payload.campaign_status == "Inactive") {
        status = "N";
        }
       
        var data = {
        campaign_name: scope.filter.payload.campaign_name,
        campaign_type: scope.filter.payload.campaign_type,
        campaign_status: status,
        };
        this.$store
        .dispatch("FilterAsterCampaigns", data)
        .then((response) => {
        scope.tableLoading = false;
        scope.page.loading = false;
        var datas = response.data;
        scope.campaignData = datas;
        scope.page.isFilterOpen = false;
        })
        .catch(function (error) {
        scope.page.isAddCampaign = false;
        scope.showSnackBar("error" + error);
        this.page.isEditCampaign = false;
        });
        },*/
        getDataFromApi() {
            var scope = this;
            scope.tableLoading = true;
            this.page.isFilterOpen = false;
            /*scope.filter.payload.campaign_name = "";
            scope.filter.payload.campaign_type = "";
            scope.filter.payload.campaign_status = "";*/
            //scope.filtervalue = [];

            let take = this.pagination.rowsPerPage;
            let skip;
            if (this.pagination.page === 1) {
                skip = 0;
            } else {
                skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
            }
            if (skip == -10) {
                skip = 0;
            }
            let status = "";
            if (scope.filter.payload.campaign_status == "Active") {
                status = "Y";
            }
            if (scope.filter.payload.campaign_status == "Inactive") {
                status = "N";
            }
            let query1 = {
                skip: skip,
                take: take,
                campaign_name: scope.filter.payload.campaign_name,
                campaign_type: scope.filter.payload.campaign_type,
                campaign_status: status,
            };

            if (this.teams.length > 0) {
                query1.team = this.teams.map(elm => elm.name)
            }
            scope.skip = skip;
            this.$store
                .dispatch("GetAsterCampaigns", query1)
                .then((response) => {
                    scope.multipleSelect.caller_id = false;
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    var datas = response.data;
                    this.pagecount = response.totalCount;
                    if (datas !== "" && datas !== undefined && datas !== null) {
                        datas.forEach((element) => {
                            scope.disabled.push({
                                id: element.id,
                                disable: true,
                            });
                        });
                    }

                    scope.campaignData = datas;
                })
                .catch(function (error) {
                    scope.page.isAddCampaign = false;
                    scope.showSnackBar("error" + error);
                    this.page.isEditCampaign = false;
                });
        },
        sidebar_enable_disable(data) {
            this.$root.$emit('sidebar_disable', data);
        },
        AddNewCampaign: function () {
            let scope = this
            scope.valid = false
            scope.addCampaign.options.primary_list_value = []
            let stats = {
                list_name: "",
                list_campaign: this.editCampaign.campaign_name,
                list_status: "Y",
            };

            scope.$store.dispatch("FilterAsterList", stats).then(async (response) => {
                let data = response.data;

                for (let i = 0; i < data.length; i++) {
                    scope.addCampaign.options.primary_list_value.push(data[i].list_id.toString());

                }

            });
            //var scope = this;
            this.page.isAddCampaign = true;
            // this.addCampaign.payload.template_name = "default";
            this.addCampaign.payload.hooper_level = "100";


        },
        closeEditRules: function () {
            this.page.isEditCampaign = false;
            this.$refs.Updateform.resetValidation();
        },
        closeAddPausecode: function () {
            var scope = this;
            scope.page.isAddCampaign = false;
            scope.addCampaign.payload = {
                campaign_name: "",
                dial_statuses: [],
                caller_id: "",
                default_queue: [],
                call_type: "",
                pause_code: [],
                ivr_name: "",
                dispo_statuses: [],
                active: { name: "ACTIVE", value: "Y" },
                hooper_level: "",
                dnc_check: false,
                masking: false,
                call_recording: false,
                dial_ratio: "",
                channel_count: "",
                template_name: "",
                dial_prefix: "",
                script_name: "",
                start_time: null,
                end_time: null,
                workflowvalues: "",
                Industryvalue: "",
                wa_type: "",
                wa_template: "",
                wa_lang: "",
                wa_link: "",
                did_rotate_strategy: "direct",
                did_routing_numbers: [],
                wrap_time:5
            };
            this.addCampaign.options.templates = [];
            this.domainlist = [];
            scope.$refs.Createform.resetValidation();
        },
        getIndustrydetails() {
            this.$store.dispatch("getIndustrydetail").then((response) => {
                let datas1 = response.data;
                datas1.forEach((value) => {
                    this.Industrylist.push(value.name);
                });
            });
        },
        closeVB() {
            this.page.isVoiceBlast = false
        },
        saveVB() {
            console.log(JSON.parse(localStorage.apiInfo).user_email, "email", this.voiceblast)

            this.$store.dispatch("vbcall", this.voiceblast).then(function (res) {
                if (res.statusCode == 200) {
                    this.showSnackBar("Updated Successfully");
                }
                this.page.isVoiceBlast = false


            }).catch(function (error) {

                this.showSnackBar("error" + error);

            });
            this.page.isVoiceBlast = false
        },
        addVbDetail(camp_name) {

            this.page.isVoiceBlast = true
            this.voiceblast.campaign_name = camp_name
            this.voiceblast.user = JSON.parse(localStorage.apiInfo).user_email
        },
        saveEditRules() {
            this.$refs.Updateform.validate();
            var scope = this;

            if (scope.editCampaign.call_type == "WAB") {
                scope.editCampaign.wa_lang = this.wafulldata.find((elm) => elm.id == scope.editCampaign.wa_lang).lang

            }
            if (scope.editCampaign.did_rotate_strategy == "direct" || scope.editCampaign.did_rotate_strategy == "agent") {
                scope.editCampaign.did_routing_numbers = ''
            } else {
                scope.editCampaign.did_routing_numbers = scope.editCampaign.did_routing_numbers.toString()
            }

            if (scope.editCampaign.timezone) {
                scope.editCampaign.timezone = 'Y'
            } else {
                scope.editCampaign.timezone = 'N'
            }
            // let Routingarr = []
            if ((this.editCampaign.call_type == 'AUTO' || this.editCampaign.call_type == 'POWER') && Array.isArray(this.editCampaign.caller_id) && this.editCampaign.caller_id.length > 0) {
                // Routingarr  = this.editCampaign.caller_id
                this.editCampaign.caller_id = ""
            }
            scope.page.isEditCampaign = !this.$refs.Updateform.validate();
            if (this.$refs.Updateform.validate()) {
                scope.$store
                    .dispatch("updateAsterCampaign", scope.editCampaign)
                    .then((response) => {
                        if (response.status == 200) {
                            if (this.editCampaign.did_rotate_strategy == 'manual' || this.editCampaign.did_rotate_strategy == 'random') {
                                let payload = {
                                    type: this.editCampaign.call_type,
                                    campaign: this.editCampaign.campaign_name,
                                    ref_number: scope.editCampaign.did_routing_numbers.split(','),
                                }
                                this.InsertCampaingRouting(payload)
                            }
                        }
                        scope.showSnackBar("Updated Successfully");
                        this.page.isEditCampaign = false;
                        scope.getDataFromApi();
                    })
                    .catch(function (error) {
                        scope.page.isAddCampaign = false;
                        this.$refs.Updateform.resetValidation();
                        scope.showSnackBar("error" + error);
                        this.page.isEditCampaign = false;
                    });
            }
            scope.getDataFromApi();
        },
        saveAddAstercampaign() {
            this.$refs.Createform.validate();
            var scope = this;
            if (scope.addCampaign.payload.call_type == "WAB") {
                scope.addCampaign.payload.wa_lang = this.wafulldata.find((elm) => elm.id == scope.addCampaign.payload.wa_lang).lang
            }
            if (scope.addCampaign.payload.timezone) {
                scope.addCampaign.payload.timezone = 'Y'
            } else {
                scope.addCampaign.payload.timezone = 'N'
            }
            if (scope.addCampaign.payload.campaign_process !== "") {
                scope.addCampaign.payload.campaign_process = scope.addCampaign.payload.campaign_process.toString()
            }
            if (scope.addCampaign.payload.did_routing_numbers.length !== 0) {
                scope.addCampaign.payload.did_routing_numbers = scope.addCampaign.payload.did_routing_numbers.toString()
            } else {
                scope.addCampaign.payload.did_routing_numbers = ""
            }

            // let Routingarr = []
            if ((this.addCampaign.payload.call_type == 'AUTO' || this.addCampaign.payload.call_type == 'POWER') && Array.isArray(this.addCampaign.payload.caller_id) && this.addCampaign.payload.caller_id.length > 0) {
                // Routingarr  = this.addCampaign.payload.caller_id
                this.addCampaign.payload.caller_id = ""
            }
            console.log(scope.addCampaign.payload, "scope.addCampaign.payload")
            if (this.$refs.Createform.validate()) {
                if (this.teams.length > 0) {
                    scope.addCampaign.payload.team = this.teams.map(elm => elm.name)
                }
                scope.$store
                    .dispatch("insertAsterCampaign", scope.addCampaign.payload)
                    .then((response) => {
                        console.log(response, "response")
                        if (response.status == 201) {
                            if (this.addCampaign.payload.did_rotate_strategy == 'manual' || this.addCampaign.payload.did_rotate_strategy == 'random') {
                                let payload = {
                                    type: this.addCampaign.payload.call_type,
                                    campaign: this.addCampaign.payload.campaign_name,
                                    ref_number: scope.addCampaign.payload.did_routing_numbers.split(','),
                                }
                                this.InsertCampaingRouting(payload)
                            }
                        }
                        scope.showSnackBar("Created Successfully");
                        scope.page.isAddCampaign = false;
                        scope.addCampaign.payload.campaign_name = "";
                        scope.addCampaign.payload.dial_statuses = [];
                        scope.addCampaign.payload.days = [];
                        scope.addCampaign.payload.caller_id = "";
                        scope.addCampaign.payload.primary_list = "";
                        scope.addCampaign.payload.campaign_process = [];
                        scope.addCampaign.payload.did_rotate_strategy = "direct";
                        scope.addCampaign.payload.did_routing_numbers = [];
                        scope.addCampaign.payload.default_queue = [];
                        scope.addCampaign.payload.call_type = "";
                        scope.addCampaign.payload.pause_code = [];
                        scope.addCampaign.payload.ivr_name = "";
                        scope.domainlist = [];
                        scope.addCampaign.options.templates = [];
                        scope.addCampaign.payload.dispo_statuses = [];
                        scope.addCampaign.payload.active = { name: "ACTIVE", value: "Y" };
                        scope.addCampaign.payload.hooper_level = "";
                        scope.addCampaign.payload.dnc_check = false;
                        scope.addCampaign.payload.masking = false;
                        scope.addCampaign.payload.call_recording = false;
                        scope.addCampaign.payload.dial_ratio = "";
                        scope.addCampaign.payload.channel_count = "";
                        scope.addCampaign.payload.template_name = "";
                        scope.addCampaign.payload.dial_prefix = "";
                        scope.addCampaign.payload.script_name = "";
                        scope.addCampaign.payload.start_time = null;
                        scope.addCampaign.payload.workflowvalues = "";
                        scope.addCampaign.payload.Industryvalue = "";
                        scope.addCampaign.payload.end_time = null;
                        scope.addCampaign.payload.wa_type = ""
                        scope.addCampaign.payload.wa_template = ""
                        scope.addCampaign.payload.wa_lang = ""
                        scope.addCampaign.payload.wa_link = ""
                        scope.addCampaign.payload.timezone = false
                        scope.getDataFromApi();
                        this.$refs.Createform.resetValidation();
                    })
                    .catch(function (error) {
                        scope.page.isAddCampaign = false;
                        console.log(error, "response 1")
                        scope.page.isAddCampaign = false;
                        scope.showSnackBar(error.response.data.message);
                        this.$refs.Createform.resetValidation();
                    });
            }
            scope.getDataFromApi();
        },
        InsertCampaingRouting(payload) {
            let scope = this
            scope.$store
                .dispatch("CreateCampaignRouting", payload).then((res) => [
                    console.log(res)
                ]).catch((err) => {
                    scope.showSnackBar("Err :", err);
                })
        },
        GetCampaignRouting(payload) {
            let scope = this
            scope.$store
                .dispatch("GetCampaignRouting", payload).then((res) => {

                    if (res.status == 200) {
                        if (res.data.data.length > 0) {
                            //this.editCampaign.caller_id = res.data.data.map(elm=> elm.ref_number.toString())
                        }
                    } else if (res.status == 204) {
                        // this.editCampaign.caller_id = []

                    }
                }).catch((err) => {
                    scope.showSnackBar("Err :", err);
                })
        },
        showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        deleteItem(id) {
            this.DeleteRowdata = true;
            this.delete_id = id;
        },
        editItem(item) {
            try {
                console.log(item)
                if (item.call_type == "VB" || item.call_type == "WAB") {
                    this.editCampaign.start_time = item.start_time.slice(0, 5);
                    this.editCampaign.end_time = item.end_time.slice(0, 5);
                    console.log(this.editCampaign.start_time, 'thisis time')
                } else {
                    this.editCampaign.start_time = item.start_time.split(".")[0];
                    this.editCampaign.end_time = item.end_time.split(".")[0];
                }
                this.page.isEditCampaign = true;

                this.editCampaign.did_routing_numbers = item.did_routing_numbers === null ? [] : item.did_routing_numbers.split(',');
                this.editCampaign.did_rotate_strategy = item.did_rotate_strategy;
                this.editCampaign.id = item.id;
                this.editCampaign.campaign_name = item.campaign_name;
                this.editCampaign.dial_statuses = item.dial_statuses;
                this.editCampaign.caller_id = item.caller_id;
                this.editCampaign.ivr_name = item.ivr_name === null ? item.ivr_name : item.ivr_name.toString();
                this.editCampaign.default_queue = item.default_queue;
                this.editCampaign.call_type = item.call_type;
                this.editCampaign.pause_code = item.pause_code;
                this.editCampaign.days = item.days == "" || item.days == null ? [] : item.days.split(',');
                this.editCampaign.dispo_statuses = item.dispo_statuses;
                this.editCampaign.active = item.active;
                this.editCampaign.hooper_level = item.hooper_level;
                this.editCampaign.script_name = item.script_name;
                this.editCampaign.primary_list = item.primary_list;
                this.editCampaign.campaign_process = item.process
                this.editCampaign.wrap_time = item.wrap_time === 5 ? 5 : item.wrap_time;
                this.editCampaign.workflowvalues = item.Domain;
                this.editCampaign.Industryvalue = item.industry;
                this.editIndustryvalues(this.editCampaign.Industryvalue);
                let value = {
                    workflowvalues: this.editCampaign.workflowvalues,
                    Industryvalue: this.editCampaign.Industryvalue,
                    data: true,
                };
                this.editworkFlowvalues(value);
                if (item.dnc_check == "Y") {
                    this.editCampaign.dnc_check = true;
                } else {
                    this.editCampaign.dnc_check = false;
                }

                if (item.call_recording == "Y") {
                    this.editCampaign.call_recording = true;
                } else {
                    this.editCampaign.call_recording = false;
                }
                if (item.masking == "true") {
                    this.editCampaign.masking = true;
                } else {
                    this.editCampaign.masking = false;
                }

                if (item.auto_dispo == "true") {
                    this.editCampaign.auto_dispo = true;
                } else {
                    this.editCampaign.auto_dispo = false;
                }

                if (item.auto_answer == "true") {
                    this.editCampaign.auto_answer = true;
                } else {
                    this.editCampaign.auto_answer = false;
                }
                this.editCampaign.dial_ratio = item.dial_ratio;
                this.editCampaign.channel_count = item.channel_count.toString();
                this.editCampaign.template_name = item.template_name;
                this.editCampaign.dial_prefix = item.dial_prefix;
                if (item.timezone == 'Y') {
                    this.editCampaign.timezone = true;
                } else {
                    this.editCampaign.timezone = false;
                }

                if (item.call_type == "WAB") {

                    this.editCampaign.wa_type = item.wa_type
                    this.Watype(item.wa_type)
                    this.editCampaign.wa_template = item.wa_template
                    this.tempdata(item.wa_template)
                    this.editCampaign.wa_lang = this.walang.find((elm) => elm.name == item.wa_lang).value
                    this.editCampaign.wa_link = item.wa_link
                }
                let stats = {
                    list_name: "",
                    list_campaign: this.editCampaign.campaign_name,
                    list_status: "Y",
                };
                this.addCampaign.options.primary_list_value = []
                this.$store.dispatch("FilterAsterList", stats).then(async (response) => {
                    if (response != "" && response.data.length > 0) {
                        let data = response.data;
                        for (let i = 0; i < data.length; i++) {
                            this.addCampaign.options.primary_list_value.push(data[i].list_id.toString());

                        }
                    }

                });
                this.FormValidation(item.call_type)
                if (item.call_type == 'AUTO' || item.call_type == 'POWER') {
                    let query = { campaign: item.campaign_name, type: item.call_type }
                    this.GetCampaignRouting(query)

                }
            } catch (err) {
                console.log(err, '666666666666666666666666666666666666666')
            }
        },
        savedelete() {
            let scope = this;
            scope.isProcessing = true;
            this.$store
                .dispatch("deleteAsterCampaign", this.delete_id)
                .then((response) => {
                    scope.isProcessing = false;
                    if (response.data.message == "success") {
                        scope.getDataFromApi();
                        scope.showSnackBar("Campaign Deleted Successfully");
                    } else {
                        scope.showSnackBar("Error! Unable Delete list");
                    }
                })
                .catch(() => {
                    scope.isProcessing = false;
                    scope.showSnackBar("Error! Unable Delete list");
                });
            this.closedelete();
            scope.getDataFromApi();
        },
        closedelete() {
            this.DeleteRowdata = false;
        },
        closeShow() {
            this.showRowData = false;
        },
        tempdata(data) {
            this.walang = this.wafulldata.filter((elm) => elm.name == data)
            this.walang = this.walang.map(elm => { return { name: elm.lang, value: elm.id } })
        },
        Watype(data) {
            this.Watemplatedata = this.wafulldata.filter((elm) => elm.format == data.toUpperCase())
            // console.log(this.Watemplatedata)
            // this.Watemplatedata = this.walang.map(elm => { return { name: elm.lang, value: elm.id } })
            // console.log(this.Watemplatedata)
        },
        Walink(data) {
            this.addCampaign.payload.wa_link = this.wafulldata.find((elm) => elm.id == data).link
        },
        UpdateWalink(data) {
            this.editCampaign.wa_link = this.wafulldata.find((elm) => elm.id == data).link
        },
        typechange(data, type) {
            let scope = this
            let Type = scope.editCampaign.call_type.trim().length > 0
                ? scope.editCampaign.call_type
                : scope.addCampaign.payload.call_type.trim().length > 0
                    ? scope.addCampaign.payload.call_type
                    : false;
            if (Type) {
                scope.FormValidation(Type)
            }
            if (type === "Type") {
                scope.FormValidation(data)
                if (scope.addCampaign.payload.call_type == "WAB" || scope.editCampaign.call_type == "VB") {
                    scope.addCampaign.payload.channel_count = 0
                    scope.editCampaign.channel_count = 0
                    scope.addCampaign.payload.caller_id = ""
                    scope.editCampaign.caller_id = ""
                } else if ((scope.editCampaign.call_type == 'AUTO' || scope.editCampaign.call_type == 'POWER') || (scope.addCampaign.payload.call_type == 'AUTO' || scope.addCampaign.payload.call_type == 'POWER')) {
                    scope.addCampaign.payload.caller_id = ""
                    scope.editCampaign.caller_id = ""
                } else {
                    scope.addCampaign.payload.caller_id = ""
                    scope.editCampaign.caller_id = ""
                }
            } else if (type === "Did_Rot_St") {
                scope.addCampaign.payload.did_routing_numbers = []
                scope.editCampaign.did_routing_numbers = []
            }
        }
    },
    computed: {
        ...mapGetters(["CallFlow", "filters", "status"]),
    },
    watch: {
        pagination: {
            handler() {
                this.demo += 1;
                if (this.pagination.page > 1 || this.skip >= 10) {
                    this.getDataFromApi();
                }
            },
            deep: true,
        },
        "filter.search"(val) {
            if (this.page.isFilterOpen && val != null) {
                let scope = this;

                let data = { model: "campaigns", column: ["campaign_name"] };
                data["search"] = val;

                if (this.teams.length > 0) {
                    data.team = this.teams.map(elm => elm.name)
                }
                this.$store
                    .dispatch("GetModuleNameList", data)
                    .then((response) => {
                        scope.tableLoading = false;
                        scope.page.loading = false;
                        var datas = response.data;
                        this.filtervalue = datas[0].campaign_name;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        "page.isAddCampaign"(data) {
            if (data) {
                this.addCampaign.payload.did_rotate_strategy = 'direct';
            } else {
                this.addCampaign.payload.did_rotate_strategy = '';
            }
            this.sidebar_enable_disable(data);
        },
        "page.isEditCampaign"(data) {
            this.sidebar_enable_disable(data);
        },
        "DeleteRowdata"(data) {
            this.sidebar_enable_disable(data);
        }
    },
    mounted() {
        let scope = this;
        scope.page.loading = false;

        this.getDataFromApi();
        this.filterFieldData();
        scope.$store.dispatch("GetPausecode").then((response) => {
            let data = response;
            console.log(response, "pppppppp")
            // let pausecode = [];

            for (let i = 0; i < data[0].count; i++) {
                // pausecode=[]
                console.log(data[0].data[i].status, "pppp")
                if (data[0].data[i].status == "Ac") {
                    scope.addCampaign.options.pause_code.push(data[0].data[i].pause_code);
                }
            }

            //scope.addCampaign.options.pause_code = pausecode;
        });


        scope.$store.dispatch("Disposition").then((response) => {
            let value = response;

            // let Disposition = [];
            for (let i = 0; i < value.data.count; i++) {
                if (value.data.data[i].active == "Y") {
                    // Disposition.push(value.data.data[i].dispo_id);
                    scope.addCampaign.options.filterdispo.push(
                        value.data.data[i].dispo_id
                    );
                }
            }

        });

        scope.$store.dispatch("GetDid").then((response) => {
            let value = response;
            for (let i = 0; i < value.data.count; i++) {
                if (value.data.data[i].status == 1) {
                    scope.addCampaign.options.didnumber.push(
                        value.data.data[i].did_number.toString()
                    );
                }
            }
        });

        scope.$store.dispatch("GetInbound").then((response) => {
            let values = response;
            for (let i = 0; i < values.data.count; i++) {
                if (values.data.data[i].status == "Active") {
                    scope.addCampaign.options.inbound.push(values.data.data[i].did_number.toString());

                }
                // }
            }
        });

        // scope.script_name=JSON.parse(localStorage.getItem('script_name'));
        scope.$store.dispatch("GetTemplate").then((response) => {
            scope.apivalue = response;
        });
        scope.$store.dispatch("queue").then((response) => {
            let data = response;
            let queue = [];

            for (let i = 0; i < data.length; i++) {
                queue.push(data[i]);
            }
            scope.addCampaign.options.add_queue = queue;
        });
        this.getIndustrydetails();
        setTimeout(
            function () {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:campaigns:create"
                    ) !== -1
                ) {
                    scope.page.addnew = [{ label: "Add Campaign" }];
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:campaigns:edit"
                    ) !== -1
                ) {
                    scope.page.canedit = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:campaigns:delete"
                    ) !== -1
                ) {
                    scope.page.candelete = true;
                }
            }.bind(scope),
            1
        );
        // this.page.addnew = [{ label: "Add Campaign" }];
        // scope.page.candelete = true;
        // scope.page.canedit = true;
        const project_config = JSON.parse(localStorage.projectConfig);
        this.crmdomain = project_config.projects[0].config[0].crm_domain;

        let query = {
            skip: 0,
            take: 0,
        };
        this.$store.dispatch("GetScript", query).then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            if (datas !== "" && datas !== undefined && datas !== null) {
                for (let i = 0; i < datas.length; i++) {
                    if (datas[i].status == "ACTIVE") {
                        scope.script_name.push(datas[i].script_name);
                    }
                }
            }
        });
        this.$store.dispatch("GetWaTemplate").then((response) => {
            if (response.status == 200) {

                // let arr = []
                // response.data.forEach((elm) => {
                //     if (elm.data.components.length > 0) {
                //         if (elm.data.components[0].hasOwnProperty("type") && elm.data.components[0].hasOwnProperty("format")) {
                //             if (elm.data.components[0].type == "HEADER" && elm.data.components[0].format == "TEXT") {
                //                 arr.push({ name: elm.data.name, id: elm.data.id, lang: elm.data.language, format: elm.data.components[0].format, link: "" })
                //             } else {
                //                 if (elm.data.components[0].hasOwnProperty("example")) {
                //                     arr.push({ name: elm.data.name, id: elm.data.id, lang: elm.data.language, format: elm.data.components[0].format, link: elm.data.components[0].example.header_handle[0] })
                //                 }
                //             }
                //         }
                //     }
                // })
                this.wafulldata = response.data
                // this.Watemplatedata = [...new Set(arr)]
            }
        })
    },
    beforeDestroy() {
        this.sidebar_enable_disable(false);
    },
};
</script>
<style>
.file-dummy {
    width: 100%;
    padding: 30px;
    border: 2px dashed;
    text-align: center;
    transition: #2196f333 0.3s ease-in-out;
    cursor: pointer;
    font-weight: bold;
}

.edit-input.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}

.modal-header--sticky {
    position: sticky;
    top: 0;
    background-color: inherit;
    z-index: 1055;
}

.modal-footer--sticky {
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    z-index: 1055;
}

.accent--text {
    color: #2196f3 !important;
    caret-color: #2196f3 !important;
}
</style>