<template>
  <div class="contentdiv">
    <v-card-actions class="modal-header--sticky headercol">
      <div class="main_frameheader">Lead Upload</div>
    </v-card-actions>
    <!-- -->
    <div class="main_frame">
      <v-expansion-panel v-for="(item, index) in ExpandpanelSchema" :key="index">
        <div style="display: flex;justify-content: space-between;width: 100%;">
          <div class="expand-headers">{{ index + 1 }}{{ ") " }}{{ item.header }}</div>
          <div>

            <div v-if="item.value === 'upload' && fileName === ''"
              style="padding-right: 14px;margin: 4px;font: 20px;font-size: 25px;color: #9d210b;">

              <i class="fas fa-close"></i>
            </div>
            <div v-else-if="item.value === 'upload' && fileName !== ''"
              style="padding-right: 14px;margin: 4px;font: 20px;font-size: 25px;color: #199d0b;">
              <i class="fa fa-check" aria-hidden="true"></i>

            </div>
            <div v-else-if="(item.fields.value === 'no')"
              style="padding-right: 14px;margin: 4px;font: 20px;font-size: 25px;color: #199d0b;">
              <i class="fa fa-check" aria-hidden="true"></i>

            </div>
            <div v-else-if="item.fields.value === 'yes' && item.childfields.value.length === 0"
              style="padding-right: 14px;margin: 4px;font: 20px;font-size: 25px;color: #9d210b;">

              <i class="fas fa-close"></i>
            </div>
            <div v-else-if="item.fields.value === 'yes' && item.childfields.value.length > 0"
              style="padding-right: 14px;margin: 4px;font: 20px;font-size: 25px;color: #199d0b;">
              <i class="fa fa-check" aria-hidden="true"></i>

            </div>

            <div v-else-if="item.value === 'header' && hasValidName()"
              style="padding-right: 14px;margin: 4px;font: 20px;font-size: 25px;color: #199d0b;">
              <i class="fa fa-check" aria-hidden="true"></i>

            </div>
            <div v-else-if="item.value === 'header' && !hasValidName()"
              style="padding-right: 14px;margin: 4px;font: 20px;font-size: 25px;color: #9d210b;">

              <i class="fas fa-close"></i>
            </div>
            <div v-else-if="((item.fields.value !== '' && item.fields.value.length !== 0))"
              style="padding-right: 14px;margin: 4px;font: 20px;font-size: 25px;color: #199d0b;">
              <i class="fa fa-check" aria-hidden="true"></i>

            </div>
            <div v-else-if="item.fields.value === '' || item.fields.value.length === 0"
              style="padding-right: 14px;margin: 4px;font: 20px;font-size: 25px;color: #9d210b;">

              <i class="fas fa-close"></i>
            </div>
          </div>

        </div>


        <v-expansion-panel-content v-if="item.active" active>

          <template v-slot:header>
            <div class="text_wrapper">
              {{ item.name }}
            </div>
          </template>
          <div class="cardheaders">
            <v-card>
              <v-card-text>

                <div class="text_wrapper">
                  <div v-if="item.value === 'header'">
                    <a>Note - Phone number is required field header must map with phone number column</a>
                  </div>

                  <v-container v-if="item.fields.field === 'select' && item.value !== 'header'" grid-list-md>
                    <v-select @change="getSelectedValue(item)" v-model="item.fields.value" :items="item.fields.options"
                      :label="item.fields.placeholder" item-text="lable" item-value="value"
                      :multiple="item.fields.multiple">
                    </v-select>
                  </v-container>

                  <v-container
                    v-else-if="csvheader.length > 0 && item.fields.field === 'select' && item.value === 'header'"
                    grid-list-md v-for="(csvheaderfield, csvheaderfieldindex) in csvheader " :key="csvheaderfieldindex">

                    <p>{{ csvheaderfield.key }}</p>
                    <div style="display: flex;flex-direction: row;justify-content: space-between;">
                      <v-select @change="actHeader(item, csvheaderfield, csvheaderfield.value, 'add')"
                        v-model="csvheaderfield.value" :value="csvheaderfield.key" :items="item.fields.options"
                        :label="item.fields.placeholder" item-text="model" item-value="model"
                        :multiple="item.fields.multiple">
                      </v-select>
                      <div @click="actHeader(item, csvheaderfield, csvheaderfield.value, 'remove')"
                        style="padding-right: 14px;margin: 4px;font: 20px;font-size: 25px;color: #9d210b;">

                        <i class="fas fa-close"></i>
                      </div>
                    </div>


                  </v-container>
                  <v-container v-else-if="item.fields.field === 'image'" grid-list-md>
                    <a @click="samplefiledownload()">Click Here to download sample file</a>

                    <div class="custom-file-upload">
                      <label for="file-upload" class="file-upload">
                        <span>Choose File</span>
                        <input id="file-upload" type="file" @change="onFileChange" ref="fileInput">
                      </label>
                      <span class="file-name">{{ fileName }}</span>
                    </div>
                  </v-container>

                  <v-container v-if="item.child === true && item.childfields.condition === item.fields.value">

                    <v-select v-if="item.childfields.field === 'select'" @change="getSelectedValue(item)"
                      v-model="item.childfields.value" :items="item.childfields.options"
                      :multiple="item.childfields.multiple" :label="item.childfields.placeholder" item-text="model"
                      item-value="model">
                    </v-select>
                  </v-container>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-expansion-panel-content>


      </v-expansion-panel>

    </div>
    <v-card-actions class="modal-footer--sticky headercol">
      <v-spacer></v-spacer>
      <v-btn color="light" flat @click="goBack">Cancel</v-btn>
      <v-btn color="primary" :disabled="validatebutton()" @click="save">Upload</v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    ExpandpanelSchema: {
      type: Array
    },
    GetAsterLeadField: { type: Array }
  },
  data() {
    return {
      csvheader: [],
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      fileName: '',
      uploadsetup: {
        label: "Please select file",
        color: "#2196f3",
      },
      panel: [0, 1],
    }
  },
  methods: {
    goBack() {

      this.$router.go(-1); // Assuming you are using Vue Router
    },
    samplefiledownload() {
      let header__ = []
      for (let i = 0; i < this.ExpandpanelSchema.length; i++) {
        const element = this.ExpandpanelSchema[i];
        if (element.value === "file_type") {
          for (let x = 0; x < this.GetAsterLeadField.length; x++) {
            header__.push(this.GetAsterLeadField[x].model);

          }

          console.log(header__, "header__header__header__", this.csvheader, this.GetAsterLeadField)
          let csvContent = "";
          if (element.fields.value === "csv") {
            csvContent += "data:text/csv;charset=utf-8,\uFEFF";
            csvContent += header__.join(",");
          }
          else if (element.fields.value === "txt") {
            csvContent += "data:text/txt;charset=utf-8,\uFEFF";
            csvContent += header__.join("\t");
          }
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "sample" + "." + element.fields.value);
          document.body.appendChild(link);
          link.click();
        }
      }


    },
    save() {
      this.$emit('save', this.ExpandpanelSchema)
    },
    hasValidName() {
      let isphone_present = false
      for (let i = 0; i < this.csvheader.length; i++) {
        if (this.csvheader[i].value === "") {
          return false;
        }
        if (this.csvheader[i].value === 'phone_number') {
          isphone_present = true
        }
      }

      if (isphone_present === false) {
        return false;
      } else if (this.csvheader.length > 0 && isphone_present) {
        return true;
      } else {
        return false;
      }

    },
    validatebutton() {
      for (let i = 0; i < this.ExpandpanelSchema.length; i++) {

        if (this.ExpandpanelSchema[i].value === "header" && !this.hasValidName()) {
          console.log(1.0, this.ExpandpanelSchema[i].name, "888888888888888")
          return true;
        }
        if (this.ExpandpanelSchema[i].value === "upload" && this.fileName === "") {
          console.log(1.1, this.ExpandpanelSchema[i].name, "888888888888888")
          return true;
        }
        if (this.ExpandpanelSchema[i].fields.value === "") {
          console.log(1, this.ExpandpanelSchema[i].name, "888888888888888")
          return true;
        }
        else if (this.ExpandpanelSchema[i].fields.value.length === 0) {
          console.log(2, this.ExpandpanelSchema[i].name, "888888888888888")
          return true;
        }
        else if (this.ExpandpanelSchema[i].child && this.ExpandpanelSchema[i].childfields.condition === this.ExpandpanelSchema[i].fields.value && this.ExpandpanelSchema[i].childfields.value.length === 0) {
          console.log(3, this.ExpandpanelSchema[i].name, "888888888888888")
          return true;
        }
        else if (this.ExpandpanelSchema[i].child && this.ExpandpanelSchema[i].childfields.condition === this.ExpandpanelSchema[i].fields.value && this.ExpandpanelSchema[i].childfields.value === "") {
          console.log(4, this.ExpandpanelSchema[i].name, "888888888888888")
          return true;
        }
      }
      return false

    },
    actHeader(item, csvheaderfield, value, type) {
    console.log(item, csvheaderfield, value, type, "item, csvheaderfield, value, type", this.csvheader);

    for (let i = 0; i < this.ExpandpanelSchema.length; i++) {
        const schema = this.ExpandpanelSchema[i];
        if (schema.value === "header") {
            schema.fields.value = this.csvheader;
        }
    }

    for (let i = 0; i < item.fields.options.length; i++) {
        const options = item.fields.options[i];
        if (options.model === csvheaderfield.value && type === "add") {
            options.disabled = true;
            for (let y = 0; y < this.csvheader.length; y++) {
                const csv_header = this.csvheader[y];
                if (options.model === csv_header.key) {
                    csv_header.model_type = options.model_type;
                }
            }
        } else if (options.model === csvheaderfield.value && type === "remove") {
            options.disabled = false;
            for (let y = 0; y < this.csvheader.length; y++) {
                const csv_header = this.csvheader[y];
                if (options.model === csv_header.key) {
                    csv_header.model_type = "";
                }
            }
            for (let j = 0; j < item.fields.value.length; j++) {
                let selectedvalue = item.fields.value[j].value;
                if (selectedvalue === options.model) {
                    let head = item.fields.value[j];
                    head.value = ""; // Clear the value
                }
            }
        }
    }

    let modelsA = item.fields.options.map(opt => opt.model);
    let valuesB = this.csvheader.map(header => header.value);
    let unmatchedModels = modelsA.filter(model => !valuesB.includes(model));
    console.log(unmatchedModels, "unmatchedModels");

    // Update the disabled property of unmatched models in item.fields.options
    item.fields.options = item.fields.options.map(opt => {
        if (unmatchedModels.includes(opt.model)) {
            return { ...opt, disabled: false };
        }
        return opt;
    });

    console.log(item.fields.options, "Updated options");
},
    getSelectedValue(item) {

      console.log(item, "eeeeeeee666666666666666eeeeeee")
      if (item.child && item.fields.value !== item.childfields.condition) {
        item.childfields.value = item.childfields.multiple ? [] : ""
      }
      if (item.value === "file_type") {
        for (let i = 0; i < this.ExpandpanelSchema.length; i++) {
          const schema = this.ExpandpanelSchema[i];
          if (schema.value === "header") {
            schema.fields.value = []
            this.csvheader = []
          }
          if (schema.value === "upload") {
            schema.fields.value = {}
            this.fileName = ""

          }
        }
      }
    },
    showSnackBar(message) {//nouse
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    findDuplicates(list) {
      let seen = {};
      let duplicates = [];

      list.forEach(function (item) {
        if (seen[item]) {
          let reassign = item.replace(/"/g, '')
          duplicates.push(reassign);
        } else {
          seen[item] = true;
        }
      });

      return duplicates;
    },
    onFileChange(event) {
      const file = event.target.files[0];
      for (let i = 0; i < this.ExpandpanelSchema.length; i++) {
        const schema_ = this.ExpandpanelSchema[i];
        if (schema_.value === "header") {
          for (let j = 0; j < schema_.fields.options.length; j++) {
            schema_.fields.options[j].disabled = false
          }
        }
      }
      if (file && file.size <= 10 * 1024 * 1024) {
        let type = file.type === "text/csv" ? "csv" : ""

        for (let i = 0; i < this.ExpandpanelSchema.length; i++) {
          const element = this.ExpandpanelSchema[i];
          if (element.value === "file_type" && element.fields.value === type) {

            if (file) {
              this.fileName = file.name;

              if (type === "csv") {
                this.csvheader = []
                this.$store
                  .dispatch("Getfiledata", file)
                  .then(async (res) => {
                    let checkduplicatecolumn = await this.findDuplicates(res)
                    if (checkduplicatecolumn.length > 0) {
                      this.fileName = ""
                      this.showSnackBar("Duplicate column in file : " + checkduplicatecolumn.toString())
                    } else {
                      for (let i = 0; i < res.length; i++) {
                        let element = res[i];

                        if (res.length - 1 === i) {
                          element = element.replace(/\s/g, '')
                        }
                        element = element.replace(/"/g, '')
                        let modelvalue = ""
                        let modeltype = ""
                        for (let i = 0; i < this.ExpandpanelSchema.length; i++) {
                          const schema = this.ExpandpanelSchema[i];
                          if (schema.value === "header") {
                            for (let j = 0; j < schema.fields.options.length; j++) {

                              if (schema.fields.options[j].model === element) {
                                modelvalue = element
                                modeltype = schema.fields.options[j].model_type
                                schema.fields.options[j].disabled = true
                              }

                            }

                          }
                        }

                        this.csvheader.push({
                          key: element,
                          value: modelvalue,
                          model_type: modeltype
                        });

                      }
                      for (let i = 0; i < this.ExpandpanelSchema.length; i++) {
                        const schema = this.ExpandpanelSchema[i];
                        if (schema.value === "header") {
                          schema.fields.value = this.csvheader
                        }
                        if (schema.value === "upload") {
                          schema.fields.value = file
                        }
                      }
                    }
                  })
                  .catch(() => { });

              }
              // // Handle file upload logic here
              // // For example, you can emit an event to the parent component
              // // this.$emit('file-selected', file);
            }
          }
        }
        if (this.fileName === "") {
          this.showSnackBar("Invalid file formate")
        }
      } else if (file && file.size >= 10 * 1024 * 1024) {
        this.showSnackBar("File size must less than 10Mb only")
      }
      event.target.value = null;
    }
  }
}
</script>

<style scoped>
.custom-file-upload {
  width: 100%;
  text-align: center;
}

.file-upload {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  border: 2px solid #3498db;
  color: #3498db;
  border-radius: 4px;
  padding: 8px 20px;
  font-size: 16px;
  transition: all 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.file-upload input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-name {
  margin-top: 5px;
  display: inline-block;
  width: calc(100% - 40px);
  /* Adjust if needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main_frameheader {
  font-size: 16px;
  color: #2196f3;
  font-weight: 1000;
  padding: 10px;
}

.expand-headers {
  font-size: 16px;
  color: #2196f3;
  font-weight: 700;
  padding: 10px;
  width: 100%;
}

.contentdiv {
  height: 100%;
}

.main_frame {
  padding: 10px 30px 30px 30px;
  height: 749px;
  overflow: auto;
}

.headercol {
  background-color: #fff !important;
  z-index: 3 !important;
}
</style>