<template >
    <div>
        <section-definition v-if="insert_form == false" :extraOptions="options" :title="page.title" :icon="page.icon"
            :supportdata="supportdatas" :industrydata="industrydata" @supportdata="supportdata($event)"
            @industrydata="industrydatas($event)" @editiconPage="bulkassign" @openFilter="isFilter"
            @downloadData="exportpopup" @plusPage="addtickets"
            @refreshPage="refreshThisPage"
            @dynamicFilter="dynamicFilter($event)"></section-definition>
        <div v-if="insert_form == false" class="main_view_ticket">
            <div class="ticket_nav_view">
                <div class="tab_view">
                    <div v-for="(item, i) in items" :key="i" @click="tab_select(item.catagory)" :class="item.value">
                        <div v-if="item.catagory === 'All'" class="tab_icon">
                            <i class="fa fa-globe" aria-hidden="true"></i>
                        </div>
                        <div v-if="item.catagory === 'Open'" class="tab_icon">
                            <i class="fa fa-envelope-open-o" aria-hidden="true"></i>
                        </div>
                        <div v-if="item.catagory === 'Closed'" class="tab_icon">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        </div>
                        <div v-if="item.catagory === 'Pending'" class="tab_icon">
                            <i class="fa fa-pause" aria-hidden="true"></i>
                        </div>
                        <div v-if="item.catagory === 'Resolve'" class="tab_icon">
                            <i class="fa fa-retweet" aria-hidden="true"></i>
                        </div>
                        <div v-if="item.catagory === 'Unresolve'" class="tab_icon">
                            <i class="fa fa-recycle" aria-hidden="true"></i>
                        </div>
                        <div v-if="item.catagory === 'On Hold'" class="tab_icon">
                            <i class="fa fa-play-circle" aria-hidden="true"></i>
                        </div>
                        <div v-if="item.catagory === 'Over Due'" class="tab_icon">
                            <i class="fa fa-list-alt" aria-hidden="true"></i>
                        </div>
                        <div v-if="item.catagory === 'Unassigned'" class="tab_icon">
                            <i class="fa fa-chain-broken" aria-hidden="true"></i>
                        </div>
                        <div class="tab_txt">{{ item.catagory }}</div>
                    </div>
                </div>
            </div>
            <loader v-if="page.loading"></loader>
            <div v-else class="ticket_body_view">
                <v-data-table :headers="headers" :items="Ticketsdata" class="elevation-1" hide-default-header
                    :headers-length="10" hide-actions>
                    <template v-slot:headers="props" id="table-scroll">
                        <tr style="height: 30px">
                            <th style="height: 32px; width: 50px">
                                <v-checkbox @change="select_all()"
                                    style="padding-top: 12px; padding-left: 2px; height: 50px" v-model="select_check"
                                    color="primary">
                                </v-checkbox>
                            </th>
                            <th style="max-width: 150px; text-align: center" v-for="head in props.headers"
                                :key="head.name">
                                {{ head.text }}
                            </th>
                            <th style="text-align: center">Action <v-icon small class="icon-padding"
                                    @click="openPopupHeader">mdi-settings</v-icon></th>
                        </tr>
                    </template>
                    <template v-slot:items="props">
                        <td>
                            <v-checkbox v-model="props.item.checker" primary @change="newValue(props.item)" hide-details
                                color="primary"></v-checkbox>
                        </td>
                        <td style="max-width: 150px; text-align: center" v-for="(property, index) in headers"
                            :key="index">
                            <p style="margin-top: 14px; overflow: hidden; white-space: nowrap;text-overflow: ellipsis;">
                                {{
                                props.item[property.value] == "" ||
                                props.item[property.value] == null ||
                                props.item[property.value] == undefined
                                ? "-"
                                : headers[index].value == property.value && headers[index].masking ?
                                ChartoMaskingChar(props.item[property.value]) :props.item[property.value]
                                }}
                            </p>
                        </td>
                        <td style="text-align: center" class="checkEmpty">
                            <template>
                                <v-icon v-show="page.canedit" small class="mr-4"
                                    @click="ticket_info(props.item)">info</v-icon>
                                <v-icon v-show="page.userassign" color="blue" @click="assignAgent(props.item)"
                                    title="Agent Assign" small class="mr-4">queue
                                </v-icon>
                                <v-icon color="red" @click="deletepopup(props.item)" title="Agent Assign" small
                                    class="mr-4">delete
                                </v-icon>
                            </template>
                        </td>
                    </template>
                </v-data-table>
                <div class="pagination-div theme--light" style="height: 50px">
                    <div flex id="row-selector">
                        <div id="page-control">
                            <span>{{ (currentPage - 1) * perPage + 1 }}-{{ endPage }} of
                                {{ totalRows }}</span>
                            <span class="action-icon">
                                <v-icon medium class="move-page" @click="moveNextPage(false)">chevron_left</v-icon>
                                <v-icon medium class="move-page" @click="moveNextPage(true)">chevron_right</v-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
                {{ snackbar.text }}
                <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
            </v-snackbar>
        </div>

        <tablefielddragable :headerEdit="headerEdit" :items="items" :headersfield="headersfield"
            @closePopupHeader="closePopupHeader" @savePopupHeader="savePopupHeader" />
        <exportfieldlist ref="export" :exportPopup="exportPopup" :items="headersfield" @export="exportfield" />

        <v-navigation-drawer fixed disable-resize-watcher right v-model="isFilterOpen">
            <v-toolbar small color="secondary" style="color: white">
                <v-toolbar-title>Filter</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon flat color="white" @click="isFilterOpen = false">
                    <i class="fas fa-close"></i>
                </v-btn>
            </v-toolbar>
            <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
                <v-container class="sidenavContainer">

                    <div v-for="field in dynamicFields" :key="field.model">
                        <!-- {{ field.fieldType }} -->
                        <div class="autocompleteDiv" v-if="field.fieldType == 'SelectList' && field.model !== 'status'">
                            <v-select :items="field.values" :label="field.label" v-model="field.value" item-text="name"
                                item-value="value" multiple></v-select>
                        </div>



                        <div class="autocompleteDiv"
                            v-if="field.fieldType == 'TextInput' && field.input_type == 'Text'">
                            <v-text-field v-model="field.value" :label="field.label" small></v-text-field>
                        </div>
                        <div class="autocompleteDiv"
                            v-if="field.fieldType == 'DatetimePicker' && field.input_type == 'DateTime'">
                            <label>{{ field.label }}</label>

                            <div class="datetimefilter">
                                <v-container class="pa-1">
                                    <v-menu v-model="page.menu.fromdate" :close-on-content-click="false"
                                        label="Created At" full-width max-width="290" small>
                                        <template v-slot:activator="{ on }">
                                            <label>From</label>
                                            <datetime class="theme-orange" color="primary"
                                                style="border-bottom: 1px solid rgb(145 145 145)" type="datetime"
                                                format="yyyy-MM-dd HH:mm:ss" v-model="appliedfilter.fromdate"
                                                :value="appliedfilter.fromdate" label="From Date" readonly v-on="on"
                                                @change="page.menu.fromdate = false"></datetime>
                                        </template>


                                    </v-menu>
                                </v-container>

                                <v-container class="pa-1">
                                    <v-menu v-model="page.menu.todate" :close-on-content-click="false" full-width
                                        max-width="290" small>

                                        <template v-slot:activator="{ on }">
                                            <label>To</label>
                                            <datetime class="theme-orange" color="primary"
                                                style="border-bottom: 1px solid rgb(145 145 145)" type="datetime"
                                                format="yyyy-MM-dd HH:mm:ss" v-model="appliedfilter.todate"
                                                :value="appliedfilter.todate" label="To Date" readonly v-on="on"
                                                @change="pagecontrol.menu.fromdate = false"></datetime>
                                        </template>



                                    </v-menu>
                                </v-container>

                                <!-- <v-container class="pa-1">
                                        <v-menu v-model="pagecontrol.menu.todate" :close-on-content-click="false" full-width
                                            max-width="290" small>

                                            <template v-slot:activator="{ on }">
                                                <label>To</label>
                                                <datetime class="theme-orange" color="primary"
                                                    style="border-bottom: 1px solid rgb(145 145 145)" type="datetime"
                                                    format="yyyy-MM-dd HH:mm:ss" v-model="appliedfilter.todate"
                                                    :value="appliedfilter.todate" label="To Date" readonly v-on="on"
                                                    @change="pagecontrol.menu.fromdate = false"></datetime>
                                            </template>



                                        </v-menu>
                                    </v-container> -->



                            </div>



                        </div>
                    </div>

                </v-container>
                <v-container row class="sidenavFooter">
                    <v-btn flat @click="reset()">Reset</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="fetchfilterdata()">Filter</v-btn>
                </v-container>
            </div>
        </v-navigation-drawer>

        <v-dialog persistent v-model="isShowAgentAssign" max-width="400px">
            <v-form ref="refassign">
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>Assign Ticket</v-toolbar-title>
                    </v-toolbar>
                    <v-container id="scroll-target" style="max-height: 350px" class="scroll-y">
                        <template>
                            <v-autocomplete v-model="assignedDetails.user" :items="agents" label="Agent" required
                                persistent-hint :allow-overflow="false"></v-autocomplete>
                        </template>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="closeAssign()">Cancel</v-btn>
                        <v-btn color="primary" @click="submitAssign()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog v-model="bulkassign_popup" max-width="400px">
            <v-form ref="refassign">
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>Bulk Assign</v-toolbar-title>
                    </v-toolbar>
                    <v-container id="scroll-target" style="max-height: 350px" class="scroll-y">
                        <template>
                            <v-autocomplete v-model="bulk_assign" :items="agents" label="Agent" required persistent-hint
                                :allow-overflow="false"></v-autocomplete>
                        </template>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="bulkassign_popup = false">Cancel</v-btn>
                        <v-btn color="primary" @click="submitBulkAssign()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog persistent v-model="delete_popup" max-width="500px">
            <v-form ref="refassign">
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>Delete Ticket</v-toolbar-title>
                    </v-toolbar>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-card-text>Are you sure to delete this Ticket? </v-card-text>
                        </v-layout>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="deleteCancelpopup()">Cancel</v-btn>
                        <v-btn color="primary" @click="deleteTicket()">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <template>
            <v-dialog persistent v-model="export_popup" max-width="400px" scrollable>
                <v-card>
                    <v-toolbar dark color="primary">
                        <div class="asdff">
                            <div>
                                <div style="font-size: large; font-weight: 600">
                                    Export Data
                                </div>
                            </div>
                            <div style="padding-top: 4px">
                                <v-icon color="light" flat style="font-size: 20px"
                                    @click="export_popup = false">fa-times</v-icon>
                            </div>
                        </div>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="refassign">
                            <v-container grid-list-md>
                                <div>
                                    <div class="exportflex">
                                        <div class="export" @click="exportAction('csv')">
                                            <v-icon class="hovericon" size="60">fa fa-file-code-o
                                            </v-icon>
                                            <div style="
    font-size: 20px;
    color: #2c3e50;
    font-weight: 800;
    padding: 5px;
    ">
                                                CSV
                                            </div>
                                        </div>
                                        <!-- <div class="export" @click="exportAction('txt')">
                                            <v-icon class="hovericon" size="60">fa-file-text </v-icon>
                                            <div style="
    font-size: 20px;
    color: #2c3e50;
    font-weight: 800;
    padding: 5px;
    ">
                                                TXT
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="modal-footer-sticky">
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <v-container fluid grid-list-xl page style="padding-top: 10px">
            <inserforms v-if="insert_form" :page_="true" model="TICKET" @customform="customform_insert($event)">
            </inserforms>
        </v-container>
        <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
    
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import inserforms from "./InsertAsterLead.vue";
import tablefielddragable from "@/components/tablefielddragable"
import exportfieldlist from "@/components/exportfieldlist"
import loader from "@/components/Widgets/loader";
import { MaskCharacter } from "@/utils/helpers";
export default {
    components: { inserforms, tablefielddragable, loader, exportfieldlist },
    data() {
        return {
            dates: ['2019-09-10', '2019-09-20'],
            templatedata: [],
            section_tab_change: "All",
            page: {
                title: "Tickets",
                icon: "fa fa-bullhorn fa-2x",
                canedit: false,
                userassign: false,
                loading: false,
                menu: {
                    fromdate: false,
                    todate: false,
                },
            },
            supportdatas: [],
            tab: null,
            items: [
                {
                    catagory: "All",
                    value: "tab_button",
                },
                {
                    catagory: "Open",
                    value: "tab_button",
                },
                {
                    catagory: "Pending",
                    value: "tab_button",
                },
                {
                    catagory: "Resolve",
                    value: "tab_button",
                },
                {
                    catagory: "Unresolve",
                    value: "tab_button",
                },
                {
                    catagory: "Closed",
                    value: "tab_button",
                },
                {
                    catagory: "On Hold",
                    value: "tab_button",
                },
                {
                    catagory: "Over Due",
                    value: "tab_button",
                },
                {
                    catagory: "Unassigned",
                    value: "tab_button",
                },
            ],
            headers: [],
            HeadersFieldProperty: [],
            industrydata: [],
            Ticketsdata: [],
            CloneTicketdata: [],
            agents: [],
            perPage: 8,
            currentPage: 1,
            totalRows: 0,
            endPage: 0,
            currentsupport: "",
            options: {
                filter: true,
                plus: false,
                editicon: false,
                uploadicon: false,
                downloadicon: false,
                selectedcurrent: "",
                refresh:true
            },
            current_tab: "",
            temp_index: [],
            bulk_edit_arr: [],
            select_check: false,
            isShowAgentAssign: false,
            assignedDetails: {},
            bulkassign_popup: false,
            bulk_assign: "",
            export_popup: false,
            exportType: ["CSV", "Txt(Tab-Delimited)"],
            insert_form: false,
            downloadData: [],
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            isFilterOpen: false,
            dynamicFields: [],
            mergepopup: false,
            user_email: JSON.parse(localStorage.apiInfo).user_email,
            delete_popup: false,
            delete_id: "",
            isfilterval: false,
            headerEdit: false,
            headersfield: [],
            isProcessing: false,
            appliedfilter: {
                fromdate: "",
                todate: "",

            },
            exportPopup: {
                title: "Export Columns"
            },
            Filename:"Tickets",
            masking:false
        };
    },
    watch: {
        'delete_popup': function (newVal) {
            this.sidebar_enable_disable(newVal)
        },

        "appliedfilter.fromdate"(newVal) {
            var from_date = moment(newVal).format('YYYY-MM-DD HH:mm:ss');
            this.$store.commit("ACTION_FROMDATE", from_date);
            this.$store.commit("FILTERFROMDATE", from_date);
            this.skip = 0;
            // this.getDataFromApi();
        },
        "appliedfilter.todate"(newVal) {
            var to_date = moment(newVal).format('YYYY-MM-DD HH:mm:ss');
            this.$store.commit("ACTION_TODATE", to_date);
            this.$store.commit("FILTER_TODATE", to_date);
            this.skip = 0;
            // this.getDataFromApi();
        },
        "headerEdit"(data){
            this.sidebar_enable_disable(data);
        }
    },
    computed: {
        ...mapGetters(["form","MaskingModules"]),
        dateRangeText() {
            return this.dates.join(' ~ ')
        }
    },
    methods: {
    refreshThisPage: function () {
        const scope = this;
        scope.tab_select("All")
    },
        exportfield({ field, type }) {
            const scope = this
            scope.filedownload(type, field)
        },
        sidebar_enable_disable(data) {
      this.$root.$emit('sidebar_disable', data);
    },
        exportAction(type) {
            const scope = this
            scope.export_popup = false
            scope.$refs.export.OpenPopup(type);
        },
        datetimeformate(data) {
            let datetime = new Date(data)
            let dd = datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate()
            let mm = datetime.getMonth() + 1 < 10 ? `0${datetime.getMonth() + 1}` : datetime.getMonth() + 1
            let hr = datetime.getHours() < 10 ? `0${datetime.getHours()}` : datetime.getHours()
            let min = datetime.getMinutes() < 10 ? `0${datetime.getMinutes()}` : datetime.getMinutes()
            return datetime.getFullYear() + `-${mm}-${dd} ${hr}:${min}`
        },
        openPopupHeader() {
            let scope = this
            scope.headerEdit = true
        },
        closePopupHeader() {
            let scope = this
            scope.headerEdit = false
        },
        DatetimeDifference(from, to) {
            var t1
            if (from != "") {
                t1 = new Date(from);
            } else {
                t1 = new Date();
            }
            var t2
            if (to != "") {
                t2 = new Date(to);
            } else {
                t2 = new Date();
            }

            var totalSeconds = Math.abs(t1.getTime() - t2.getTime()) / 1000;
            let hours = Math.floor(totalSeconds / 3600);
            totalSeconds %= 3600;
            let minutes = Math.floor(totalSeconds / 60);
            let seconds = totalSeconds % 60;
            minutes = String(minutes).padStart(2, "0");
            hours = String(hours).padStart(2, "0");
            seconds = String(Math.round(seconds)).padStart(2, "0");
            return hours + ":" + minutes + ":" + seconds
        },
        DateFormate(data) {
            let datetime = new Date(data)
            let yy = datetime.getFullYear()
            let dd = datetime.getDate()
            let mm = datetime.getMonth() + 1
            dd = String(dd).padStart(2, "0");
            mm = String(mm).padStart(2, "0");
            return `${yy}-${mm}-${dd}`
        },
        savePopupHeader(data) {
            let scope = this
            let clone_data = [...data]
            let payload = {
                fields: clone_data.map((elm) => {
                    delete elm.lable
                    delete elm.model
                    delete elm.item
                    return elm
                })
            }
            scope.closePopupHeader()
            scope.isProcessing = true
            scope.page.loading = true
            scope.$store
                .dispatch("UpdateTableHeader", payload)
                .then((response) => {
                    if (response.status === 200) {
                        scope.headersfield = []
                        scope.showSnackBar("Ticket Header Updated successfully");
                        scope.closePopupHeader()
                        scope.isProcessing = false
                        scope.supportdata(this.currentsupport);
                    } else {
                        scope.showSnackBar("Ticket Header Updated to failed!");
                    }
                })
                .catch(() => {
                    scope.showSnackBar("Ticket Header Updated to agent failed!");
                });

        },
        ticket_info(val) {
            console.log("val", val);
            // let obj = JSON.parse(localStorage.ticket_templet);
            let userdata = this.CloneTicketdata.find(
                (elm) => elm.ticket_id == val.ticket_id
            );
            let obj = {};
            obj.ticket_id = val.ticket_id;
            obj.phone_number = userdata.phone_number;
            obj.user = userdata.user;
            localStorage.setItem("ticket_templet", JSON.stringify(obj));

            this.$router.push({
                name: "crm/ticketDetail",
            });
        },
        newValue(row) {
            var scope = this;
            let id = row["ticket_id"];
            if (!scope.bulk_edit_arr.includes(id)) {
                scope.bulk_edit_arr.push(id);
            } else {
                const index = scope.bulk_edit_arr.indexOf(id);
                if (index > -1) {
                    scope.bulk_edit_arr.splice(index, 1);
                }
            }
            if (this.Ticketsdata.length == this.bulk_edit_arr.length) {
                scope.select_check = true;
            } else {
                scope.select_check = false;
            }
            if (this.bulk_edit_arr.length > 0) {
                scope.options.editicon = true;
            } else {
                scope.options.editicon = false;
            }
        },
        select_all() {
            var scope = this;
            let id = "";
            if (scope.select_check) {
                scope.bulk_edit_arr = [];
                for (let i = 0; i < scope.Ticketsdata.length; i++) {
                    scope.Ticketsdata[i].checker = true;
                    id = scope.Ticketsdata[i]["ticket_id"];
                    scope.bulk_edit_arr.push(id);
                }
                scope.options.editicon = true;
            } else {
                for (let j = 0; j < scope.Ticketsdata.length; j++) {
                    scope.Ticketsdata[j].checker = false;
                }
                scope.bulk_edit_arr = [];
                scope.options.editicon = false;
            }
        },
        tab_select(val) {
            // this.section_tab_change = val;
            let scope = this
            scope.currentPage = 1
            scope.isfilterval = false;
            for (let i = 0; i < scope.items.length; i++) {
                const element = scope.items[i];
                if (element.catagory === val) {
                    element.value = "tab_button_swap";
                } else {
                    element.value = "tab_button";
                }
            }
            scope.class_changer = "tab_button_swap";

            scope.currentsupport = val;
            scope.supportdata(scope.currentsupport);
        },


        moveNextPage(next) {
            const scope = this;
            if (next && scope.totalRows >= scope.currentPage * scope.perPage + 1) {
                scope.currentPage = scope.currentPage + 1;
                scope.supportdata(scope.currentsupport);
            } else if (!next && scope.currentPage !== 1) {
                scope.currentPage = scope.currentPage - 1;
                scope.supportdata(scope.currentsupport);
            }
        },
        assignAgent(ticketData) {
            const scope = this;
            scope.assignedDetails = { ...ticketData };
            // scope.assignedDetails.template_name = scope.currentsupport;
            scope.isShowAgentAssign = true;
        },
        closeAssign() {
            const scope = this;
            scope.assignedDetails = {};
            scope.isShowAgentAssign = false;
            // scope.$refs.refassign.resetValidation();
        },
        submitAssign() {
            const scope = this;
            const assignDetails = {
                ticket_id: scope.assignedDetails["ticket_id"],
                assign: scope.assignedDetails["user"],
                user: scope.user_email
            };
            scope.$store
                .dispatch("AssignTicket", assignDetails)
                .then(() => {
                        scope.showSnackBar("Ticket assigned to agent successfully");
                        scope.assignedDetails = {};
                        scope.isShowAgentAssign = false;
                        scope.$refs.refassign.resetValidation();
                        scope.supportdata(this.currentsupport);

                })
                .catch(() => {
                    scope.showSnackBar("Ticket assigned to agent failed!");
                });
        },
        bulkassign() {
            this.bulkassign_popup = true;
        },
        submitBulkAssign() {
            var scope = this;
            var edit_data = {
                assign: scope.bulk_assign,
                bulk_id: scope.bulk_edit_arr,
                user: scope.user_email
            };
            scope.$store
                .dispatch("AssignTicket", edit_data)
                .then(() => {
                    scope.bulkassign_popup = false;
                    scope.bulk_assign = "";
                    scope.bulk_edit_arr = [];
                    scope.select_check = false;
                    scope.options.editicon = false;
                    scope.showSnackBar("Ticket Assign to agent Successfully");
                })
                .catch(function (error) {
                    scope.options.editicon = false;
                    scope.showSnackBar("error" + error);
                });
            scope.supportdata(scope.currentsupport);
        },
        exportpopup() {
            this.export_popup = true;
        },
        filedownload(value, field) {
            let scope = this
            const payload = {
                status: "",
                skip: scope.perPage * (scope.currentPage - 1),
                take: scope.perPage,
                from_date: this.appliedfilter.fromdate == "" ? "" : this.datetimeformate(this.appliedfilter.fromdate),
                to_date: this.appliedfilter.todate == "" ? "" : this.datetimeformate(this.appliedfilter.todate),
                download: true,
                payload:{}
            };

            if (scope.isfilterval == true) {
                let filterData = []
                scope.dynamicFields.forEach((data) => {
                    if (data.value != "") {
                        filterData.push({ name: data.model, value: data.value });
                    }

                });
                payload.payload = { dynamicfilter: filterData }
            } else {
                if (
                    scope.currentsupport != "All" &&
                    scope.currentsupport != ""
                ) {

                    if (scope.currentsupport == "Unassigned") {
                        payload.status = "unassign"
                    } else if (scope.currentsupport == "Unresolve") {
                        payload.status = "pending,hold,overdue,in_progress,unassign"
                    } else if (scope.currentsupport == "On Hold") {
                        payload.status = "hold"
                    } else {
                        payload.status = scope.currentsupport.replace(" ", "_").toLowerCase()
                    }

                }
            }

            scope.$store
                .dispatch("GetAsterSupportData", payload)
                .then((response) => {
                    let datas = response.data[0].others;



                    if (value == "csv") {
                        let csv = "";
                        for (let row = 0; row < datas.length; row++) {
                            if(scope.masking){
                                scope.MaskingModules.MaskingModules[scope.Filename].forEach((elm)=>{
                                    datas[row][elm] =  scope.ChartoMaskingChar(datas[row][elm])
                                })
                            }
                            if (datas[row].status == "closed" || datas[row].status == "resolve") {
                                datas[row].duration = scope.DatetimeDifference(datas[row].created_at, datas[row].modified_at)
                            } else {
                                datas[row].duration = scope.DatetimeDifference(datas[row].created_at, "")
                            }
                            let keysAmount = field.length;
                            let keysCounter = 0;

                            if (row === 0) {
                                for (let key in field) {
                                    key = field[key]
                                    csv += key + (keysCounter + 1 < keysAmount ? "," : "\r\n");

                                    keysCounter++;
                                }
                                keysCounter = 0;
                                for (let key in field) {
                                    key = field[key]
                                    if(typeof(datas[row][key]) === "string" && datas[row][key].includes(",")){
                                         datas[row][key] = `"${datas[row][key]}"`
                                    }
                                    csv +=
                                        datas[row][key] +
                                        (keysCounter + 1 < keysAmount ? "," : "\r\n");
                                    keysCounter++;
                                }
                            } else {
                                for (let key in field) {
                                    key = field[key]
                                    if(typeof(datas[row][key]) === "string" && datas[row][key].includes(",")){
                                         datas[row][key] = `"${datas[row][key]}"`
                                    }
                                    csv +=
                                        datas[row][key] +
                                        (keysCounter + 1 < keysAmount ? "," : "\r\n");
                                    keysCounter++;
                                }
                            }

                            keysCounter = 0;
                        }

                        let link = document.createElement("a");
                        link.id = "download-csv";
                        link.setAttribute(
                            "href",
                            "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
                        );
                        link.setAttribute("download", `${this.currentsupport}.csv`);
                        document.body.appendChild(link);
                        link.click();
                        this.export_popup = false;
                    }
                })
                .catch(function (error) {
                    scope.showSnackBar("error1" + error);
                });


        },
        showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        industrydatas(val) {
            this.supportdatas = [];
            this.templatedata.forEach((element) => {
                if (val == element.industry) {

                    this.supportdatas.push(element.template_name);
                } else if (val == "All") {
                    this.supportdatas.push(element.template_name);
                }
            });
        },
        supportdata(val) {
            let scope = this;
            if (scope.isfilterval == true) {
                scope.fetchFilter()
            } else {
                scope.currentsupport = val;
                this.$store
                    .dispatch("GetAsterSupportField")
                    .then((response) => {
                        var datas = response.fields;
                        let AsterTicketFields = datas;
                        let dynamicFields = [];
                        scope.headers = []
                        let headers_field = []
                        for (let i = 0; i < response.clonefields.length; i++) {
                            const elm = response.clonefields[i];
                            if (elm.model !== 'ticket_id') {
                                headers_field.push({ id: elm.id, lable: elm.label, model: elm.model, table_position: elm.table_position, table_show: elm.table_show, item: false })
                            }

                        }
                        this.headersfield = headers_field
                        let maskingvalues = {
                            name:scope.Filename,
                            value:[]
                        }
                        for (let i = 0; AsterTicketFields.length > i; ++i) {
                            AsterTicketFields[i].label = AsterTicketFields[i].label.replace("_", " ");
                            if (AsterTicketFields[i].show_on_view === true) {
                                if (AsterTicketFields[i].model === "status") {
                                    scope.HeadersFieldProperty.push(AsterTicketFields[i])
                                }

                                // let key = {
                                //     text:
                                //         AsterTicketFields[i].model == "user"
                                //             ? "Assgin"
                                //             : AsterTicketFields[i].label,
                                //     value:
                                //         AsterTicketFields[i].model == "user"
                                //             ? "user"
                                //             : AsterTicketFields[i].model,
                                //     align: "center",
                                //     sortable: false,
                                // };
                                // if (AsterTicketFields[i].model === "ticket_id") {
                                //     scope.headers.splice(0, 0, key);
                                // } else if (AsterTicketFields[i].model === "user") {
                                //     scope.headers.splice(1, 0, key);
                                // } else if (AsterTicketFields[i].model === "due_date") {
                                //     scope.headers.splice(4, 0, key);
                                // } else if (AsterTicketFields[i].model === "status") {
                                //     scope.headers.splice(3, 0, key);
                                // } else if (AsterTicketFields[i].model === "subject") {
                                //     scope.headers.splice(2, 0, key);
                                // }
                                // table_show

                                // this.headersfield.sort(function(a, b){return a.table_position - b.table_position})


                            }

                            if (AsterTicketFields[i].table_show == true) {
                                let key = {
                                    text: AsterTicketFields[i].model == "user"
                                        ? "Assgin"
                                        : AsterTicketFields[i].label,
                                    value: AsterTicketFields[i].model == "user"
                                        ? "user"
                                        : AsterTicketFields[i].model,
                                    align: "center",
                                    sortable: false,
                                    position: AsterTicketFields[i].table_position,
                                    masking: scope.masking && AsterTicketFields[i].type === "phone_number" 
                                };
                                // console.log(AsterTicketFields[i].table_position,AsterTicketFields[i].model,scope.headers,"sursunday")
                                // scope.headers.splice(AsterTicketFields[i].table_position-1, 0, key);

                                if (AsterTicketFields[i].model !== 'ticket_id') {
                                    scope.headers.push(key)
                                }
                            }

                            if (AsterTicketFields[i].model === 'ticket_id') {
                                scope.headers.push({
                                    text: AsterTicketFields[i].label,
                                    value: AsterTicketFields[i].model,
                                    align: "center",
                                    sortable: false,
                                    position: AsterTicketFields[i].table_position,
                                    masking: scope.masking && AsterTicketFields[i].type === "phone_number" 
                                })
                            }
                            if(scope.masking && AsterTicketFields[i].type === "phone_number"){
                                maskingvalues.value.push(AsterTicketFields[i].model)
                            }
                            if (AsterTicketFields[i].filter === true) {
                                if (AsterTicketFields[i].model == "tags") {
                                    scope.$store
                                        .dispatch("TicketTags")
                                        .then((response) => {
                                            let tag = []
                                            if (response.statusCode === 200) {
                                                tag = response.data.map(e => { return { name: e.name, value: e.name } })
                                            }
                                            AsterTicketFields[i].values = tag
                                        })
                                }

                                if (AsterTicketFields[i].model == "user") {
                                    AsterTicketFields[i].label = 'Assign'
                                    AsterTicketFields[i].fieldType = "SelectList"
                                    AsterTicketFields[i].values = scope.agents.filter((e) => {
                                        return { name: e, value: e }
                                    })
                                }
                                if (AsterTicketFields[i].model == "channel") {
                                    const payload = {
                                        take: 1000,
                                        skip: 0,
                                        status: "unassign"
                                    }
                                    scope.$store
                                        .dispatch("GetAsterSupportData", payload)
                                        .then((response) => {
                                            let datas = response.data[0].others;
                                            var channelArray = [];

                                            for (let i = 0; i < datas.length; i++) {
                                                channelArray.push(datas[i]["channel"]);
                                            }

                                            AsterTicketFields[i].label = 'Channel'
                                            AsterTicketFields[i].fieldType = "SelectList"
                                            AsterTicketFields[i].values = channelArray.filter((e) => {
                                                return { name: e, value: e }

                                            })
                                        });
                                }
                                AsterTicketFields[i].value = ""
                                dynamicFields.push(AsterTicketFields[i]);
                            }

                        }
                        this.$store.dispatch("MaskingModuleDataPush", maskingvalues)
                        scope.headers.sort(function (a, b) { return a.position - b.position })
                        scope.dynamicFields = dynamicFields




                        if (val !== "") {
                            const payload = {
                                status: "",
                                skip: scope.perPage * (scope.currentPage - 1),
                                take: scope.perPage,
                            };
                            if (
                                scope.currentsupport != "All" &&
                                scope.currentsupport != ""
                            ) {

                                if (scope.currentsupport == "Unassigned") {
                                    payload.status = "unassign"
                                } else if (scope.currentsupport == "Unresolve") {
                                    payload.status = "pending,hold,overdue,in_progress,unassign"
                                } else if (scope.currentsupport == "On Hold") {
                                    payload.status = "hold"
                                } else {
                                    payload.status = val.replace(" ", "_").toLowerCase()
                                }

                            }
                            scope.$store
                                .dispatch("GetAsterSupportData", payload)
                                .then((response) => {
                                    scope.page.loading = false
                                    let datas = response.data[0].others;
                                    scope.CloneTicketdata = [...datas];
                                    let temparr = [];
                                    datas.forEach((element) => {

                                        if (element.status == "closed" || element.status == "resolve") {
                                            element.duration = scope.DatetimeDifference(element.created_at, element.modified_at)
                                        } else {
                                            element.duration = scope.DatetimeDifference(element.created_at, "")
                                        }

                                        element.due_date = scope.DateFormate(element.due_date)
                                        element.checker = false;
                                        temparr.push(element);
                                    });
                                    scope.Ticketsdata = temparr;
                                    scope.select_check = false;

                                    if (response.count !== undefined) {
                                        scope.totalRows = response.count;
                                    } else {
                                        scope.totalRows = 0;
                                    }
                                    scope.endPage = scope.currentPage * scope.perPage;
                                    scope.endPage =
                                        scope.endPage < scope.totalRows
                                            ? scope.endPage
                                            : scope.totalRows;


                                })
                                .catch(function (error) {
                                    scope.showSnackBar("error1" + error);
                                });
                        }
                    })
                    .catch(function (error) {
                        scope.showSnackBar("error" + error);
                    });
            }
        },
        addtickets() {
            let scope = this;
            scope.insert_form = true;
        },
        customform_insert(val) {
            if (val === 201) {
                this.insert_form = false;
                this.supportdata(this.currentsupport);
                this.showSnackBar("Ticket Succesfully created");
            } else if (val === false) {
                this.insert_form = false;
                this.supportdata(this.currentsupport);
            }
        },
        reset() {
            let scope = this;
            scope.currentPage = 1
            this.appliedfilter.fromdate = "";
            this.appliedfilter.todate = "";
            scope.dynamicFields.forEach((data) => {
                data.value = ""
            });
            scope.tab_select("All")
            this.isFilterOpen = true;

        },
        isFilter() {
            let scope = this;
            scope.isFilterOpen = true;
        },
        fetchfilterdata() {
            let scope = this
            scope.currentPage = 1
            scope.fetchFilter()
        },
        fetchFilter() {
            var scope = this;
            scope.isFilterOpen = false,
                scope.isfilterval = true;
            let filterData = [];

            scope.dynamicFields.forEach((data) => {
                if (data.value != "") {
                    // filterData[data.model] = data.value
                    filterData.push({ name: data.model, value: data.value });

                }

            });
            const payload = {
                status: "",
                from_date: this.appliedfilter.fromdate == "" ? "" : this.datetimeformate(this.appliedfilter.fromdate),
                to_date: this.appliedfilter.todate == "" ? "" : this.datetimeformate(this.appliedfilter.todate),
                skip: scope.perPage * (scope.currentPage - 1),
                take: scope.perPage,
                payload: { dynamicfilter: filterData },

            };
            scope.$store
                .dispatch("GetAsterSupportData", payload)
                .then((response) => {
                    let datas = response.data[0].others;


                    scope.CloneTicketdata = [...datas];

                    let temparr = [];
                    datas.forEach((element) => {
                        let tempobj = {};
                        scope.headers.forEach((elm) => {
                            if (
                                element[elm.value] === undefined &&
                                elm.value !== "actions"
                            ) {
                                tempobj[elm.value] = "";
                            }
                            if (
                                elm.value !== "actions" &&
                                element[elm.value] !== undefined
                            ) {
                                if (elm.value == "assgin") {
                                    elm.value = "user";
                                }

                                tempobj[elm.value] = element[elm.value];
                            }

                            if (elm.value == "status") {
                                let get_status = scope.HeadersFieldProperty.find(e => e.model == "status").values
                                tempobj[elm.value] = get_status.find(e => e.value == element.status).name
                            }
                        });
                        tempobj.checker = false;
                        temparr.push(tempobj);
                    });

                    scope.Ticketsdata = temparr;
                    scope.select_check = false;

                    if (response.count !== undefined) {
                        scope.totalRows = response.count;
                    } else {
                        scope.totalRows = 0;
                    }
                    scope.endPage = scope.currentPage * scope.perPage;
                    scope.endPage =
                        scope.endPage < scope.totalRows
                            ? scope.endPage
                            : scope.totalRows;

                    for (let i = 0; i < scope.items.length; i++) {
                        const element = scope.items[i];
                        if (element.catagory === 'All') {
                            element.value = "tab_button_swap";
                        } else {
                            element.value = "tab_button";
                        }
                    }
                    scope.class_changer = "tab_button_swap";
                })
                .catch(function (error) {
                    scope.showSnackBar("error1" + error);
                });
        },

        deleteTicket() {
            let scope = this
            let query = {
                user: scope.user_email,
                ticket_id: scope.delete_id
            }
            scope.$store
                .dispatch("DeleteTicket", query)
                .then((response) => {
                    if (response.status === 200) {
                        scope.showSnackBar("Ticket Deleted Successfully!");
                        scope.supportdata(scope.currentsupport);
                        scope.delete_popup = false
                        scope.delete_id = ""
                    } else if (response.status === 204) {
                        scope.delete_popup = false
                        scope.delete_id = ""
                        scope.showSnackBar("Ticket Unable To Deleted!");
                    } else {
                        scope.delete_popup = false
                        scope.delete_id = ""
                        scope.showSnackBar("Ticket Delete failed!");
                    }

                })
                .catch(function (error) {
                    scope.showSnackBar("Ticket Delete failed!", error);
                });
        },
        deletepopup(data) {
            let scope = this
            scope.delete_id = data.ticket_id
            scope.delete_popup = true

        },
        deleteCancelpopup() {
            let scope = this
            scope.delete_popup = false
            scope.delete_id = ""
        },
        ChartoMaskingChar(data){
            let scope = this
            if(!scope.$store.getters.MaskType.includes(typeof(data))){
                return data
            }
         return MaskCharacter(data)
        }
    },
    mounted() {
        var scope = this;
        scope.page.loading = true
        scope.tab_select("All")
        // scope.supportdata("All");

        scope.agents = JSON.parse(localStorage.getItem("agent_emails")).filter(e => e != this.user_email);

        setTimeout(
            function () {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:tickets:userassign"
                    ) !== -1
                ) {
                    scope.page.userassign = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:tickets:edit"
                    ) !== -1
                ) {
                    scope.page.canedit = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:tickets:create"
                    ) !== -1
                ) {
                    scope.options.plus = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:tickets:download"
                    ) !== -1
                ) {
                    scope.options.downloadicon = true;
                }
                if (
                scope.$store.getters.UserPermissions.indexOf(
                    "configurations:masking:invisible"
                ) === -1
                ) {
                scope.masking = true;
                }
            }.bind(scope),
            1
        );
    },
};
</script>
<style>
.tab_view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    padding: 10px 15px 10px 20px;
}

.tab_button {
    background-color: #cacaca;
    text-align: center;
    display: flex;
    flex-direction: row;
    width: 11%;
    cursor: pointer;
    padding: 5px;
    font-size: 15px;
    border-radius: 8px;
    border: 1px solid #cacaca;
}

.tab_icon {
    width: 20%;
    text-align: right;
}

.tab_txt {
    width: 80%;
    text-align: center;
    padding-right: 15px;
}

.main_view_ticket {
    display: flex;
    flex-direction: column;
}

.ticket_body_view {
    padding: 10px 20px 10px 20px;
}

.tab_button_swap {
    background-color: rgb(33, 150, 243);
    color: #fafafa;
    text-align: center;
    display: flex;
    flex-direction: row;
    width: 11%;
    cursor: pointer;
    padding: 5px;
    font-size: 15px;
    border-radius: 8px;
    border: 1px solid #cacaca;
}

.icon_section {
    align-content: center;
    padding: 0px 0px 0px 33px;
}

.exportflex {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 20px;
}

.export {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 40%;
    height: 130px;
    text-align: center;
    padding: 20px;
    background-color: #cacaca;
    color: #ffffff;
    border-radius: 10px;
}

.hovericon {
    color: #2c3e50;
    cursor: pointer;
}

.asdff {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.icon-padding {
    padding-left: 10px;
}

.datetimefilter {
    padding: 5px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
}
</style>