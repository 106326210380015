<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :exportOptions="page.exportOptions"
      @openFilter="openMissedCallFilter"
      @refreshPage="refreshMissedCallPage"
      @info="openInfoPage"
      :isMaterialIconType="true"
      @exportData="exportMissedCallReport"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="missedcallreport"
        class="elevation-1"
        :pagination.sync="pagination"
        :total-items="missedcalltotal"
        :rows-per-page-items="[5, 10, 25]"
      >
        <template v-slot:items="props">
          <td class="checkEmpty p0" v-for="(header, index) in headers" :key="index">
            {{ header.masking ? ChartoMaskingChar(props.item[header.value]) : props.item[header.value] }}
          </td>
        </template>
      </v-data-table>
    </v-container>
    <div>
      <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="1000">
          <pdfexport
            v-if="dialog"
            :header="pdf.header"
            :pdfdata="pdf_data"
            @closeModel="closeModel"
            title="Dropped Call Report"
          ></pdfexport>
        </v-dialog>
      </v-layout>
    </div>
    <v-navigation-drawer
      fixed disable-resize-watcher
      right
      v-model="page.isFilterOpen"
      class="filterNavigation"
    >
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <DropedCallFilter
            v-if="page.isFilterOpen"
            @getDataFrom="getDataFrom"
            :appliedfilter="appliedfilter"
          ></DropedCallFilter>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="refreshMissedCallPage()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="FilterData">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import DropedCallFilter from "./Components/DropedCallFilter";
import { mapGetters } from "vuex";
import pdfexport from "./Components/pdfexport";
import moment from "moment";
import { MaskCharacter } from "@/utils/helpers";
export default {
  components: {
    loader,
    DropedCallFilter,
    pdfexport,
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    this.page.loading = false;
    this.dateformate();
    // this.$store.dispatch("reportuserlist");
    scope.getDataFromApi()
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:missedcall:export"
          ) !== -1
        ) {
          scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
        } else {
          scope.page.exportOptions = [];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:masking:invisible"
          ) === -1
        ) {
          scope.masking = true;
          scope.updateheader()
        }
      }.bind(scope),
      1
    );
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Dropped Calls Report",
        icon: "call_missed",
        loading: true,
        materialicon: true,
        isFilterOpen: false,
        isinfoOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true, info: false },
      },
      dialog: false,
      pagination: {
        rowsPerPage: 10,
      },
      headers: [
        {
          text: "Date Time",
          align: "left",
          sortable: false,
          class: "p0",
          value: "calldate",
          masking:false
        },
        {
          text: "UniqueId",
          align: "left",
          sortable: false,
          class: "p0",
          value: "uniqueid",
          masking:false
        },
        {
          text: "Queue",
          align: "left",
          sortable: false,
          class: "p0",
          value: "queue",
          masking:false
        },
        {
          text: "Agent",
          align: "left",
          sortable: false,
          class: "p0",
          value: "agent",
          masking:false
        },
        {
          text: "Station",
          align: "left",
          sortable: false,
          class: "p0",
          value: "extension",
          masking:false
        },
        {
          text: "Phone Number",
          align: "left",
          sortable: false,
          class: "p0",
          value: "phone_number",
          masking:false
        },
        {
          text: "Team",
          align: "left",
          sortable: false,
          class: "p0",
          value: "team",
          masking:false
        },
        {
          text: "Duration",
          align: "left",
          sortable: false,
          class: "p0",
          value: "duration",
          masking:false
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
          class: "p0",
          value: "status",
          masking:false
        }
      ],
      pdf: {
        header: [
          {
            text: "Date Time",
            align: "left",
            sortable: true,
            value: "call_datetime",
          },
          {
            text: "UniqueId",
            align: "left",
            sortable: false,
            value: "uniqueid",
          },
          {
            text: "Queue",
            align: "left",
            sortable: false,
            value: "queue",
          },
          {
            text: "Agent",
            align: "left",
            sortable: false,
            value: "agent",
          },
          {
            text: "Extension",
            align: "left",
            sortable: false,
            class: "p0",
            value: "extension",
          },
          {
            text: "Phone Number",
            align: "left",
            sortable: false,
            value: "phone",
          },
          {
            text: "Duration",
            align: "left",
            sortable: false,
            value: "duration",
          },
          {
            text: "Status",
            align: "left",
            sortable: false,
            value: "status",
          },
        ],
      },
      missedcallreport: [],
      missedcalltotal: 0,
      payload: {
        from_date: "",
        to_date: "",
        agent: "",
        queue: "",
        extension: "",
        phone_number: "",
        detail: "calldetails",
        export: "exportcalldetails",
      },
      total: "",
      call_date: "",
      report: "",
      exports: [],
      excel_data: [],
      txt_data: [],
      pdf_data: [],
      from_date: "",
      to_date: "",
      appliedfilter: {
        phonenumber: "",
        agent: "",
        queue: "",
        fromdate: "",
        todate: "",
        extension: "",
        uniqueid: "",
      },
      page_flag: false,
      Filename:"DropedCallReport",
      masking:false,
      skip:0
    };
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["dropedcallreport","MaskingModules"]),
  },
  methods: {
    FilterData(){
      this.page.isFilterOpen = false
      this.getDataFromApi()
    },
    closeModel: function () {
      this.dialog = false;
    },
    openMissedCallFilter: function () {
      this.page.isFilterOpen = true;
    },
    openInfoPage: function () {
      this.page.isinfoOpen = true;
    },
    refreshMissedCallPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.appliedfilter.phonenumber = "";
      scope.appliedfilter.agent = "";
      scope.appliedfilter.queue = "";
      scope.appliedfilter.extension = "";
      scope.appliedfilter.Uniqueid = "";
      scope.dateformate();
      scope.refreshvalue();
      scope.getDataFromApi();
    },
    exportMissedCallReport: function (option) {
      if (option == "CSV" || option == "Txt(Tab-Delimited)") {
        this.export(option);
      } else {
        this.dialog = true;
        this.export(option);
        this.pdf_flag = true;
      }
    },
    dateformate() {
      this.page.loading = false;
      this.to_date = new Date();
      this.to_date = moment(this.to_date).format("YYYY-MM-DD");
      var from_date1 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date1.setTime(from_date1.getTime() - dateOffset);
      this.from_date = moment(from_date1).format("YYYY-MM-DD");
      this.$store.commit("FILTERFROMDATE", this.from_date);
      this.$store.commit("FILTER_TODATE", this.to_date);
    },
    resetvalue() {
      var to_date = new Date();
      to_date = moment(to_date).format();
      var from_date = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date.setTime(from_date.getTime() - dateOffset);
      from_date = moment(from_date).format();
      this.$store.dispatch("fromdate", from_date);
      this.$store.dispatch("todate", to_date);
      this.appliedfilter.phonenumber = "";
      this.appliedfilter.agent = "";
      this.appliedfilter.queue = "";
      this.appliedfilter.extension = "";
      this.appliedfilter.fromdate = "";
      this.appliedfilter.todate = "";
      this.appliedfilter.uniqueid = "";
      this.page.isFilterOpen = true;
    },
    getDataFromApi() {
      var scope = this;
      this.payload.to_date = this.to_date;
      this.payload.from_date = this.from_date;
      var { page, rowsPerPage } = this.pagination;
      //scope.$store.dispatch("reportuserlist").then((response) => {
      //let dataa = response.toString(",");
      scope.payload.agent = JSON.parse(localStorage.getItem("agent_emails"));
      if (rowsPerPage == -1) {
        this.payload.take = this.total;
      } else {
        this.payload.take = rowsPerPage;
      }
      if (page != "" || page != undefined || page != null) {
        this.skip = page;
      } else {
        this.skip = 1;
      }
      this.payload.skip = (this.skip - 1) * this.payload.take;
      return new Promise((resolve) => {
        scope.$store
          .dispatch("dropedcallreports", this.payload)
          .then((response) => {
            scope.page.loading = false;
            // if (scope.page_flag) {
            //   this.pagination.page = 1;
            //   scope.page_flag = false;
            // }
            scope.missedcallreport = response.data;
            scope.total = response.total;
            scope.missedcalltotal = scope.total;
            resolve(scope.total);
            if (response.status === 401) {
              scope.$store.dispatch("checkingtoken");
            }
          });
      });
      //});
    },
    getDataFrom() {
      this.skip = 0;
      this.getDataFromApi();
      this.page_flag = true;
    },

    export(option) {
      this.excel_data = [];
      this.pdf_data = [];
      var add_details = {
        from_date: this.payload.from_date,
        to_date: this.payload.to_date,
        misseddetail: "exportdropedcalldetails",
      };
      this.$store.dispatch("exportdatafetch", add_details).then((response) => {
        this.exports = response;
        for (var i = 0; i < this.exports.length; i++) {
          if(this.masking){
            this.MaskingModules.MaskingModules[this.Filename].forEach((elm)=>{
              response[i][elm] =  this.ChartoMaskingChar(response[i][elm])
            })
          }
          if (response[i].phone_number) {
            var mobile_number = response[i].phone_number;
            var reg = new RegExp("[&/\\#,+()$~%.'\":*?<>{}]", "gi");
            var phone_number = mobile_number.replace(reg, "");
            var phone = phone_number.replace(" ", "");
          }
          if (response[i].calldate) {
            var date = new Date(response[i].calldate);
            var options = {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            };
            var timeString = date.toLocaleString("en-US", options);
            this.call_date = response[i].calldate.substr(0, 11) + timeString;
          }
          this.excel_data.push({
            Date: this.call_date,
            Uniqueid: response[i].uniqueid,
            Queue: response[i].queue,
            Agent: response[i].agent,
            agentName:response[i].agent_name,
            team:response[i].team,
            Station: response[i].extension,
            Phone: phone,
            Duration: response[i].duration,
            Status: response[i].status,
          });
          this.txt_data.push({
            Date: this.call_date,
            Uniqueid: response[i].uniqueid,
            Queue: response[i].queue,
            Agent: response[i].agent,
            agentName:response[i].agent_name,
            team:response[i].team,
            Station: response[i].extension,
            Phone: phone,
            Duration: response[i].duration,
            Status: response[i].status,
          });
          this.pdf_data.push([
            this.call_date,
            response[i].uniqueid,
            response[i].queue,
            response[i].agent,
            response[i].extension,
            phone,
            response[i].duration,
            response[i].status,
          ]);
        }
        if (option == "CSV") {
          let csvContent = '';
          csvContent +=
            "Date Time,Unique Id,Queue,Agent,Agent Name,Team,Station,Phone Number,Duration,Status\r\n";
          this.excel_data.forEach(function (rows) {
            let data = Object.values(rows);
            let data1 = [];
            data.forEach((elm) => {
              if (typeof elm === "string") {
                data1.push(elm.replace(/'|"|#|\n/g, " "));
              } else {
                data1.push(elm);
              }
            });
            for (let i = 0; i < data1.length; i++) {
              if (
                data1[i] != null &&
                data1[i] != undefined &&
                data1[i] != "" && typeof data1[i] == "string"
              ) {
                if (data1[i].includes(",")) {
                  data1[i] = data1[i].replaceAll(",", ";");
                }
              }
            }
            let row = data1.join(",");
            csvContent += row + "\r\n";
          });
         var date11 = new Date();
          date11 = moment(date11).format("YYYY-MM-DD hh-mm");
          const encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "DroppedCallReport_" + date11 + ".csv");
          document.body.appendChild(link); // Required for FF
          link.click();
        }
        if (option == "Txt(Tab-Delimited)") {
          let txtContent = "";
          let txt_key = [
            "Date Time",
            "Unique Id",
            "Queue",
            "Agent","Agent Name","Team",
            "Station",
            "Phone Number",
            "Duration",
            "Status",
          ];
          let txt_tab = txt_key.join("\t");
          txtContent += `${txt_tab} \r\n`;
          this.txt_data.forEach(function (rows) {
            let data = Object.values(rows);
            let row = data.join("\t");
            txtContent += row + "\r\n";
          });
          var date2 = new Date();
          date2 = moment(date2).format("YYYY-MM-DD hh-mm");
          var encodedUri1 =
            "data:text/plain;charset=utf-8,\uFEFF" +
            encodeURIComponent(txtContent);

          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute("download", "DropedCallReport" + date2 + ".txt");
          document.body.appendChild(link1);
          link1.click();
        }
      });
    },

    refreshvalue() {
      this.$store.commit("FILTER_PHONE", { phone: "" });
      this.$store.commit("FILTER_AGENT", { agent: "" });
      this.$store.commit("FILTER_QUEUE", { queue: "" });
      this.$store.commit("FILTER_EXTENSION", { extension: "" });
      this.$store.commit("FILTER_UNIQUEID", { uniqueid: "" });
    },
    ChartoMaskingChar(data){
      let scope = this
      if(!scope.$store.getters.MaskType.includes(typeof(data))){
          return data
      }
      return MaskCharacter(data)
    },
    updateheader(){
      let scope = this
      scope.headers.forEach((elm)=>{
        elm.masking = scope.masking && elm.value == scope.MaskingModules.MaskingModules[scope.Filename].find(tlm => tlm == elm.value)
      })
    },
  },

  beforeDestroy() {
    this.$store.commit("FILTER_PHONE", { phone: "" });
    this.$store.commit("FILTER_AGENT", { agent: "" });
    this.$store.commit("FILTER_QUEUE", { queue: "" });
    this.$store.commit("FILTER_EXTENSION", { extension: "" });
    this.$store.commit("FILTER_UNIQUEID", { uniqueid: "" });
  },
};
</script>
<style>
.test {
  display: flex;
}
/* .move {
  
} */
.drop {
  font-weight: bold;
  font-size: 13px !important;
  padding-left: 10px !important ;
}
.drop1 {
  font-size: 13px !important;
  padding-left: 10px !important;
}
.agent {
  font-weight: bold;
  font-size: 13px !important;
  padding-left: 10px !important ;
}
.agent1 {
  font-size: 13px !important;
  padding-left: 10px !important;
}
</style>
