
<template>
    <div>
        <div class="main-header">
            <div class="title-icon">
                <div class="icon_div">
                    <v-avatar class="box-glow">
                        <i class="fas fa-door-open " style="font-size: 26px"></i>
                    </v-avatar>
                </div>
                <div class="heading">
                    <v-toolbar-title class="primary--text">{{
                        page.title
                        }}</v-toolbar-title>
                </div>
            </div>
            <div>
                <div class="newGroupName">
                    <div class="selectUsergroup">
                        <v-select v-model="groups.selected_value" :items="groups.group_names" label="User Group"
                            persistent-hint @change="changeActive(groups.selected_value)"></v-select>
                    </div>
                    <div v-if="page.cancreate" class="iconController">
                        <v-icon color="green" @click="groups.new.active = true" class="add_circle">add_circle</v-icon>
                    </div>
                    <div class="iconController">
                        <v-icon :class="['fas', [page.refresh]]" class="refreshthispage" @click="refreshThisPage">
                        </v-icon>
                    </div>
                </div>
            </div>
        </div>

        <loader v-if="controls.rest.processing"></loader>
        <div v-else class="grid_container">
            <v-dialog persistent v-model="groups.new.active" max-width="500px">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-card-title>
                            <span class="headline">Add Group Name</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-card-text>
                                <v-text-field v-model="groups.new.name" class="full-width" label="New Group" required
                                    @input="isNewGroupNameEntered = groups.new.name.trim().length > 0">
                                </v-text-field>
                            </v-card-text>
                        </v-layout>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="addGroupListReset()">Cancel</v-btn>
                        <v-btn color="primary" :disabled="!isNewGroupNameEntered" @click="createGroup()">Save</v-btn>

                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-layout row>
                <v-flex xs12>
                    <v-card>
                        <div dark color="primary" show-arrows class="extraheight-tabs">
                            <div class="overall_headers">
                                <div class="per_headers">
                                    <div class="tab-checkbox" style="color: white">
                                        <v-checkbox v-model="checkbox" @change="checkall()" color="white" dark
                                            class="select-checkbox" hide-details></v-checkbox>
                                    </div>
                                    <div class="permissionData" href="#permissionstab">
                                        Permissions
                                    </div>
                                </div>
                                <div class="iconHeader" :style="iconHeaderWidth">
                                    <v-icon class="deleteIcon" @click="deleteGroupListInit(groups.active)"
                                        v-if="isDeleteDisplay && page.candelete">delete</v-icon>
                                    <v-icon v-if="page.canedit" class="saveIcon" @click="saveGroupInfo">save</v-icon>
                                </div>
                            </div>
                            <v-tabs-items>
                                <v-tab-item value="permissionstab">
                                    <loader v-if="controls.groupSection.rest.processing"></loader>

                                    <div v-else>
                                        <v-layout wrap row>
                                            <div class="text-group">
                                                <v-text-field readonly v-model="groups.active.group_name" label="Group">
                                                </v-text-field>
                                            </div>
                                        </v-layout>
                                        <div class="maincontainer_flexcls">
                                            <div class="groupsView">
                                                <div class="modulesList"
                                                    v-for="(modules, moduleKey, moduleIndex) in permissions.list"
                                                    :key="`module-${moduleIndex}`">
                                                    <div class="btnGroup">
                                                        <a href="#view_group_data">
                                                            <v-btn class="btnGroup" :color="modules.value.checkColor"
                                                                @click="viewGroupList(modules.module)"> {{
                                                                (modules.module== 'realtime') ? "Real Time" :
                                                                (modules.module == 'leadmanagement') ? "Leads" :
                                                                (modules.module == 'formbuilder') ? "Form Builder" :
                                                                (modules.module == 'custommodule') ? "Custom Module" :
                                                                modules.module}}</v-btn>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="subcontainer" v-if="isViewGroupList" id="view_group_data">
                                                <view_group :list="list" :gname="gname" :ischeckall="ischeckall"
                                                    :subselect="subselect" :ischecked="isChecked"
                                                    :updatePermission="updatePermission"></view_group>
                                            </div>
                                        </div>
                                    </div>
                                </v-tab-item>
                            </v-tabs-items>
                        </div>
                    </v-card>
                </v-flex>
            </v-layout>
        </div>
        <v-dialog persistent v-model="groups.delete.active" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Delete Group Info</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text> Are you sure you to delete the group name : "{{ groups.active.group_name }}"?</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="deleteGroupListReset(false)">Cancel</v-btn>
                    <v-btn color="primary" @click="deleteGroupListConfirm()">Yes, Delete !</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="groups.save.active" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Save Group info</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Are you sure to save this group information?</v-card-text>
                        <v-card-text>Group Name: {{ groups.active.group_name }}</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="cancelGroupInfo(false)">Cancel</v-btn>
                    <v-btn color="primary" @click="updateGroup">Yes, Save !</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="controls.snackbar.show" :bottom="true" :right="true" :timeout="controls.snackbar.timeout">
            {{ controls.snackbar.text }}
            <v-btn color="pink" flat @click="controls.snackbar.show = false">Close</v-btn>
        </v-snackbar>
        <v-dialog v-model="controls.rest.processing" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";
import view_group from "@/components/usergroup/usergroup";


export default {
    components: {
        loader,
        view_group,
    },
    computed: {
        ...mapGetters([]),
    },
    mounted() {
        let scope = this;

        scope.getGroupList();




        scope.getPermissionsList();

        scope.page.loading = false;
        setTimeout(
            function () {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "configurations:usergroup:create"
                    ) !== -1
                ) {
                    scope.page.cancreate = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "configurations:usergroup:edit"
                    ) !== -1
                ) {
                    scope.page.canedit = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "configurations:usergroup:delete"
                    ) !== -1
                ) {
                    scope.page.candelete = true;
                }
            }.bind(scope),
            1
        );
    },
    watch: {
        //         "groups.selected_value":{
        // deep:true,
        // handler:()=>{
        // console.log("testttttttt",scope.groups);
        // let activeAct = localStorage.getItem("groupsActive");
        // if(this.counterr === 0){
        // this.groups.active = JSON.parse(activeAct);
        // }
        // this.counterr =1;
        // }
        //         },
        groupNameArr() {
            const data = this.permissions.list;

            if (this.groupNameArr.length > 0) {
                this.groupNameArr.forEach((element) => {


                    if (data[element.split(":")[0]] != undefined) {
                        data[element.split(":")[0]].value.checkColor = "primary";
                    }
                });
            } else {
                for (let key in data) {
                    data[key].value.checkColor = "default";
                }
            }
        },
    },

    created() { },
    data() {
        return {
            isNewGroupNameEntered: false,
            val: false,
            counterr: 0,
            attri: true,
            checkbox: true,

            styleObject: {
                height: "80px",
                "margin-top": "-8px",
            },
            isDeleteDisplay: false,
            iconHeaderWidth: { widht: "30px" },
            page: {
                title: "User Groups",
                icon: "fa-user",
                loading: false,
                breadcrumbs: [],
                exportOptions: [],
                options: { filter: false, refresh: false },
                addnew: [],
                cancreate: false,
                candelete: false,
                canedit: false,
                refresh: "fa-refresh",
            },
            controls: {
                snackbar: {
                    show: false,
                    timeout: 6000,
                    text: "",
                },
                rest: {
                    processing: false,
                },
                groupSection: {
                    rest: {
                        processing: false,
                        fetching: false,
                    },
                },
            },
            groups: {
                isNewGroupNameEntered:false,
                selected_value: "",
                group_data: [],
                list: [],
                group_names: [],
                new: {
                    active: false,
                    name: "",
                },
                active: {},
                save: {
                    active: false,

                },
                delete: {
                    active: false,
                    object: {},
                },
            },
            groups_titles: [],
            permissions: {
                list: [],
                active: [],
                entries_permission: [],
            },
            list: {},
            isViewGroupList: false,

            gname: "",
            groupNameArr: [],
            undefinedvalue: false,
            active_flag:false
        };
    },

    methods: {
        clear() {
            this.list = {};

        },
        saveGroupInfo() {
            let scope = this
            if(scope.permissions.active.length == 0){
                scope.controls.snackbar.text = "Atleast Choose Select One";
                scope.controls.snackbar.show = true;
                setTimeout(() => {
                    scope.controls.snackbar.show = false;
                    scope.controls.snackbar.text = "";
                }, scope.controls.snackbar.timeout);
                return;
            }
            this.groups.save.active = true
        },
        cancelGroupInfo(val) {
            this.groups.save.active = val
        },
        modulesOptions: function (moduleList, modules) {
            const moduleListval = modules.value;
            this.groups_titles.push("Select All");
            for (let key in moduleListval) {
                if (moduleList == key) {
                    for (let i = 0; i < moduleListval[key].value.length; i++) {
                        this.groups_titles.push(moduleListval[key].value[i].title);
                    }
                }
            }
        },
        viewGroupList(Gname) {
            this.gname = Gname;

            let datalist = {};
            for (let n in this.permissions.list) {
                if (Gname == n) {
                    datalist = Object.assign({}, this.permissions.list[n].value);
                }
            }

            this.list = datalist;
            delete this.list.checkColor;
            delete this.list.checked;
            this.isViewGroupList = true;
            datalist = {};
        },

        changeActive: function () {
            this.attri = false;
            this.isViewGroupList = false;

            this.groupNameArr = [];
            const groupData = this.groups.group_data;
            const selectedData = this.groups.selected_value;

            for (let i = 0; i < groupData.length; i++) {
                if (groupData[i].group_name == selectedData) {
                    // console.log("groupData[i]",groupData[i]);
                    this.groups.active = Object.assign({}, groupData[i]);
                    this.viewGroup();
                }
            }
            this.isDeleteDisplay = true;
            this.iconHeaderWidth.width = "70px";
            this.list = {};

        },
        checkall: async function () {
            const data = this.permissions.list;
            if (this.permissions.active.length > 0 && this.checkbox == false) {
                this.permissions.active = [...this.permissions.entries_permission];

                //for unselected checkbox with default color
                this.permissions.active.forEach((element) => {


                    if (data[element.split(":")[0]] != undefined) {
                        data[element.split(":")[0]].value.checkColor = "default";
                    }
                });
                //end

                this.permissions.active = [];
                this.checkbox = false;
            } else {
                this.checkbox = true;

                this.permissions.active = [...this.permissions.entries_permission];

                //for selected checkbox with primary color
                this.permissions.active.forEach((element) => {

                    if (data[element.split(":")[0]] != undefined) {
                        data[element.split(":")[0]].value.checkColor = "primary";
                    }
                });
                //end
            }
            return { data: this.permissions.active, bool: this.checkbox }
        },

        subselect: function (submodule) {
            let countdata = 0;

            const data = this.permissions.list;

            submodule.value.map((elem) => {
                this.permissions.active.find((elm) => elm == elem.permission);
                if (
                    this.permissions.active.find((elm) => elm == elem.permission) ==
                    undefined
                ) {
                    this.undefinedvalue = true;

                    this.permissions.active.push(elem.permission);
                } else {
                    countdata++;
                }
            });
            this.groupNameArr = this.permissions.active;

            if (submodule.value.length == countdata) {
                this.undefinedvalue = false;
                submodule.value.map((elem) => {
                    data[elem.permission.split(":")[0]].value.checkColor = "default";

                    this.groupNameArr.splice(
                        this.groupNameArr.findIndex((t) => t == elem.permission),
                        1
                    );
                    let index = this.permissions.active.findIndex((t) => t == elem.permission);
                    if (index !== -1) {
                        this.permissions.active.splice(
                            this.permissions.active.findIndex((t) => t == elem.permission),
                            1
                        );
                    }
                });

            }

            if (
                this.permissions.active.length !==
                this.permissions.entries_permission.length
            ) {
                this.checkbox = false;
            } else {
                this.checkbox = true;
            }
        },
        ischeckall: function (submodule) {
            let datacount = 0;

            submodule.value.map((elem) => {

                if (
                    this.permissions.active.find((elm) => elm == elem.permission) !==
                    undefined
                ) {
                    datacount++;

                }
            });

            if (submodule.value.length == datacount) {
                this.val = true;
                return true;
            } else {
                this.val = false;
                return false;
            }
        },
        isChecked: function (val) {
            return this.permissions.active.indexOf(val) !== -1;
        },
        updatePermission: function (val) {
            const data = this.permissions.list;
            let index1 = this.permissions.active.indexOf(val);
            if (index1 !== -1) {
                data[val.split(":")[0]].value.checkColor = "default";
                this.undefinedvalue = false;
                this.groupNameArr.splice(index1, 1);

                this.permissions.active.find((elm) => {
                    if (elm === val) {
                        let index = this.permissions.active.indexOf(val);
                        this.permissions.active.splice(index, 1);
                    }

                });


            } else {
                this.undefinedvalue = true;

                this.permissions.active.push(val);
            }
            this.groupNameArr = this.permissions.active;

            this.selectallcheck();
        },
        getGroupList: function () {
            let scope = this;
            scope.$store
                .dispatch("groupLists")
                .then((response) => {
                    scope.groups.list = response;
                    scope.groups.group_data = response;
                    this.isViewGroupList = false;
                    this.groupNameArr = [];
                    this.isDeleteDisplay = true;
                    this.iconHeaderWidth.width = "70px";
                    this.groups.group_names = [];
                    for (let i = 0; i <= this.groups.group_data.length; i++) {

                        scope.groups.group_names.push(scope.groups.group_data[i].group_name);
                        if(this.active_flag == false && i == 0){

                            scope.groups.selected_value = this.groups.group_data[0].group_name;
                            scope.groups.active = Object.assign({}, this.groups.group_data[0]);
                            this.changeActive()
                            this.active_flag = true
                        }
                        this.list = {};

                    }

                    if (!scope.groups.active.group_id) {

                        scope.groups.active = Object.assign({}, response[0]);
                    }
                    scope.viewGroup();
                })
                .catch(() => {
                    scope.groups.list = [];
                    // scope.groups.active = {};
                    scope.permissions.active = [];
                });
        },
        getPermissionsList: function () {
            let scope = this;
            this.$store.dispatch("getPermissions").then((response) => {
                for (let val1 in response.obj) {
                    response.obj[val1].value.checkColor = "white";

                    response.obj[val1].value.checked = false;
                    for (let val in response.obj[val1].value) {
                        if (val != "checkColor" && val != "checked") {
                            response.obj[val1].value[val].value.forEach((element) => {
                                element.checked = false;
                            });
                        }
                    }
                }

                scope.permissions.list = response.obj;

                scope.permissions.entries_permission = response.permission_data.map(
                    (elm) => {
                        return elm.permission_value;
                    }
                );
                this.selectallcheck();
            });
        },
        selectallcheck: function () {
            if (
                this.permissions.active.length ==
                this.permissions.entries_permission.length
            ) {
                this.checkbox = true;
            } else {
                this.checkbox = false;
            }
        },
        viewGroup: function () {

            let scope = this;



            let data = scope.groups.active.group_id;
            this.list = {};

            scope.groupNameArr = [];
            scope.controls.groupSection.rest.processing = true;
            this.$store
                .dispatch("getGroupPermissions", data)
                .then((response) => {
                    scope.permissions.active = response;
                    scope.groupNameArr = response;

                    scope.controls.groupSection.rest.processing = false;
                    this.selectallcheck();
                })
                .catch(() => {
                    scope.permissions.active = [];
                    scope.controls.groupSection.rest.processing = false;
                    this.selectallcheck();
                });
        },
        createGroup: async function () {
            let scope = this;
            let values = await scope.checkall()
            
            if(!values.bool){
                values = await scope.checkall()
            }

            let data = {
                group_name: scope.groups.new.name,
                permissions: values.data,
            };
            scope.controls.rest.processing = true;
            this.$store
                .dispatch("createGroupList", data)
                .then((response) => {
                    let permissionInfo = JSON.parse(localStorage.getItem('permissionInfo'));
                    permissionInfo.group_id.push(response.group_id)
                    localStorage.setItem('permissionInfo',JSON.stringify(permissionInfo) )
                    scope.groups.active = response;
                    scope.groups.new.name = "";
                    scope.controls.rest.processing = false;
                    scope.controls.snackbar.text = "Group Created Successfully";
                    scope.controls.snackbar.show = true;
                    scope.groups.new.active = false;
                    setTimeout(() => {
                        scope.controls.snackbar.show = false;
                        scope.controls.snackbar.text = "";
                    }, scope.controls.snackbar.timeout);
                    scope.getGroupList();
                    this.groups.selected_value = response.group_name
                    this.viewGroup();
                })
                .catch(() => {
                    scope.controls.rest.processing = false;
                    scope.controls.snackbar.text = "Error Creating Group";
                    scope.controls.snackbar.show = true;
                    setTimeout(() => {
                        scope.controls.snackbar.show = false;
                        scope.controls.snackbar.text = "";
                    }, scope.controls.snackbar.timeout);
                    scope.permissions.active = [];
                    scope.getGroupList();
                });
        },
        updateGroup: function () {

            let scope = this;
            // scope.getGroupList();
            let data = {
                args: scope.groups.active.group_id,
                params: {
                    group_name: scope.groups.active.group_name,
                    permissions: scope.permissions.active,
                },
            };
            scope.controls.rest.processing = true;
            this.$store
                .dispatch("editGroupList", data)
                .then(() => {


                    scope.controls.rest.processing = false;
                    scope.controls.snackbar.text = "Group Updated Successfully";
                    scope.controls.snackbar.show = true;
                    scope.groups.save.active = false

                    setTimeout(() => {
                        scope.controls.snackbar.show = false;
                        scope.controls.snackbar.text = "";
                    }, scope.controls.snackbar.timeout);
                    this.changeActive()
                    // scope.getGroupList();

                })
                .catch(() => {
                    scope.controls.rest.processing = false;
                    scope.controls.snackbar.text = "Error Updating Group And Permissions";
                    scope.controls.snackbar.show = true;
                    setTimeout(() => {
                        scope.controls.snackbar.show = false;
                        scope.controls.snackbar.text = "";
                    }, scope.controls.snackbar.timeout);
                    scope.permissions.active = [];
                    scope.getGroupList();
                });
        },
        deleteGroupListInit: function (item) {
            let scope = this;
            scope.groups.delete.object = Object.assign({}, item);
            scope.groups.delete.active = true;
        },
        addGroupListReset: function () {
            const scope = this;
            scope.groups.new.active = false;
            scope.groups.new.name = "";
        },

        deleteGroupListReset: function (fetch) {
            let scope = this;

            scope.groups.delete.active = false;
            if (fetch) {
                scope.permissions.active = [];
                scope.groups.active = {};
                scope.groups.delete.object = {};
                scope.getGroupList();
            }
        },
        deleteGroupListConfirm: function () {
            let scope = this;
            let data = {
                args: scope.groups.delete.object.group_id,
            };
            scope.isDeleteDisplay = false;
            scope.iconHeaderWidth.width = "30px";
            scope.controls.rest.processing = true;
            this.$store
                .dispatch("deleteGroupList", data)
                .then(() => {
                    scope.controls.rest.processing = false;
                    scope.controls.snackbar.text = "Group Deleted Successfully";
                    scope.controls.snackbar.show = true;
                    this.groups.group_names.splice(
                        this.groups.group_names.indexOf(
                            scope.groups.delete.object.group_name
                        ),
                        1
                    );
                    setTimeout(() => {
                        scope.controls.snackbar.show = false;
                        scope.controls.snackbar.text = "";
                    }, scope.controls.snackbar.timeout);
                    scope.deleteGroupListReset(true);
                    this.active_flag = false
                    scope.groups.selected_value = "";
                })
                .catch(() => {
                    scope.controls.rest.processing = false;
                    scope.controls.snackbar.text = "Error Deleting Group And Permissions";
                    scope.controls.snackbar.show = true;
                    setTimeout(() => {
                        scope.controls.snackbar.show = false;
                        scope.controls.snackbar.text = "";
                    }, scope.controls.snackbar.timeout);

                    scope.deleteGroupListReset(true);
                });
        },
        refreshThisPage() {
            const scope = this;
            this.checkbox = false;
            this.active_flag = false
            scope.groups.selected_value = " ";
            scope.getGroupList();
            this.getPermissionsList();
            this.checkbox = false;
            this.isDeleteDisplay = false;
            this.iconHeaderWidth.width = "30px";

        },
    },
};
</script>
<style>
.extraheight-tabs .v-window__container {
    height: 32em;
    padding: 4px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 5px;
}

.event-checkboxes {
    display: block;
    /* flex-direction: row;
 flex-wrap: wrap; */
}

.event-checkboxes>div {
    width: 33%;
    padding: 7px 3px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.event-checkboxes>div>input {
    visibility: hidden;
    margin-right: 0px;
}

.event-checkboxes>div>label {
    padding: 7px 16px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

.event-checkboxes>div>label:hover {
    background: #ccc;
}

.event-checkboxes>div>label.active,
.event-checkboxes>div>label.active:hover {
    background: #2196f3;
    color: #fff;
    font-weight: bold;
}

.module-toolbar {
    display: flex;
    padding: 5px 10px;
    background: #ccc;
    border-radius: 3px 3x 0px 0px;
    align-items: center;
    justify-content: flex-start;
}

.event-modules {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 2px;
    margin: 5px;
    text-transform: capitalize;
}

input[type="checkbox"] {
    margin-right: 10px;
}
</style>
<style scoped>
.scroll,
.extraheight-tabs .v-window__container {
    overflow: auto;
    height: 20em;
    padding: 10px;
}

.full-width {
    width: 100%;
    padding-top: 20px;
}

.extra-pad {
    padding: 0;
    margin-bottom: 2px;
}

.grouplist.selected {
    border-radius: 5px;
    color: #fff;
}

.grouptitle.bold {
    font-weight: bold;
    color: #fff;
}

.groupcount.highlight {
    color: #fff !important;
}

.per_headers {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.permissionData {
    color: #ffffff;
    font-weight: bold;
    padding-top: 20px;
    font-size: 17px;
}

.overall_headers {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: -4px 0 10px 5px;
    align-items: right;
    min-width: 890px;
    padding-right: 10px;
}

.iconHeader {
    display: flex;
    flex-direction: row;
    padding-top: 18px;
    /* width: 70px; */
    /* padding:5px; */
    justify-content: space-between;
}

.groupsView {
    width: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    padding: 15px;
    /* padding-left:65px; */
    border: 1px solid #cacaca;
    border-radius: 10px;
}

.v-expansion-panel__header {
    background-color: #27a0be !important;
}

.btnGroup {
    min-width: 200px;
}

.maincontainer_flexcls {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: auto;
    padding: 15px;
}

.subcontainer {
    margin-top: 20px;
    width: auto;
    height: auto;
    border: 1px solid #cacaca;
    border-radius: 10px;
}

.newGroupName {
    width: 450px;
    display: flex;
    padding: 10px 14.7px 10px 10px;
    flex-direction: row;
}

.newGroupName div {
    margin-left: 10px;
}

.add_circle {
    margin-top: 23px;
    font-size: 25px;
}

.box-glow {
    color: #ffffff;
    border-radius: 0;
    margin-left: 16px;
}

.title-icon {
    display: flex;

    flex-direction: row;
}

.title-icon div {
    margin-right: 10px;
    margin-bottom: -50px;
}

.main-header {
    margin-top: 11px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.extraheight-tabs .v-tabs__container {
    height: 50px;
}

.grid_container {
    padding: 0px 25px 0px 30px;
}

.heading {
    margin: 10px 0 0 0;
}

.text-group {
    width: 400px;
    padding: 5px 5px 0px 35px;
}

.modulesList {
    width: 100px;
}

.selectUsergroup {
    width: 100%;
}

.refreshthispage {
    margin-top: 26px;
    font-size: 17px;
    color: #2196f3;
}

.v-tabs__container {
    height: 60px;
}

.extraheight-tabs {
    background-color: #2196f3;
    height: 63px;
}

.saveIcon {
    color: #ffffff;
    font-size: 27px;
}

.deleteIcon {
    color: #e64343;
    font-size: 27px;
}

.all_checkbox {
    color: #ffffff;
}

.v-window__container {
    background-color: #ffffff;
}

.iconController {
    width: 50px;
}
</style>

