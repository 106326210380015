<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs" :addNewData="page.addnew" :exportOptions="page.exportOptions"
      @openFilter="filterClick" @refreshPage="refreshThisPage" @addNew="AddNewQueue" :style="styleObject">
    </section-definition>
    <loader v-if="page.loading"></loader>

    <!-- data content -->
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="QueueData" class="elevation-1 fixed-layout" :pagination.sync="pagination"
        :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.name }}</td>
          <!-- <td class="checkEmpty p0">{{ props.item.account_code }}</td> -->
          <td class="checkEmpty p0">{{ props.item.strategy }}</td>
          <td class="checkEmpty p0">{{ props.item.timeout }}</td>
          <td class="text-xs-center checkEmpty p0">
            <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">edit</v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item)">delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>

    <!-- delete model -->
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Delete Queue</v-toolbar-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text v-show="queuedeletemsg !== ''">{{ queuedeletemsg }}</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions class="modal-footer--sticky">
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- create model -->
    <template>
      <v-dialog persistent :value="page.isAddQueue" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Create Queue</span>
            </v-card-title>
          </v-toolbar>

          <v-card-text style="height: 500px">
            <v-form ref="adduserform" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field v-model="NewQueue.name" label="Queue Name" required
                      :rules="[(v) => !!v || 'Queue Name is required', (v) => /^\S+$/.test(v) || 'Queue Name should not contain spaces', (v) => /^[a-zA-Z0-9_]+$/.test(v) || 'Queue Name should not contain special characters']" :prefix="tenantName"
                      class="custom-prefix"></v-text-field>
                    <div v-if="error_name == 'Queue Name is already exist'" class="alert-danger">
                      Queues name already exists!
                    </div>
                  </v-flex>
                  <!-- <v-flex xs12>
                    <v-text-field v-model="NewQueue.account_code" label="Account Code*"
                      onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                      required :rules="[(v) => !!v || 'Account code is number']"></v-text-field>
                  </v-flex> -->
                  <v-flex xs12>
                    <v-select v-model="NewQueue.announce_holdtime" :items="permision" label="Announce"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="NewQueue.timeout" label="Wait TimeOut" required
                      :rules="[(v) => !!v || 'Wait TimeOut is required']"
                      onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="NewQueue.musiconhold" label="Music On Hold"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="NewQueue.strategy" :items="Strategy" label="Ring Strategy" required
                      :rules="[(v) => !!v || 'Ring Strategy is required']"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="NewQueue.retry" :items="retry" label="Retry" required
                      :rules="[(v) => !!v || 'Retry is required']"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="NewQueue.timeoutrestart" :items="permision" label="Timeout Restart"
                      :rules="[(v) => !!v || 'Timeout Restart is required']"></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddQueue()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveAddQueue()">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <!-- update model -->
    <template>
      <v-dialog persistent :value="page.isEditQueue" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Update Queue</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text style="height: 500px">
            <v-form ref="edituserform" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field v-model="editQueue.name" label="Queues" required readonly
                      :rules="[(v) => !!v || 'Queues is required']"></v-text-field>
                    <div v-if="error_edit_name == 'Queues name already exist'" class="alert-danger">
                      Queues name already exists!
                    </div>
                  </v-flex>
                  <!-- <v-flex xs12>
                    <v-text-field v-model="editQueue.account_code" label="Account Code*"
                      onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                      required :rules="[(v) => !!v || 'Account Code is number']"></v-text-field>
                  </v-flex> -->
                  <v-flex xs12>
                    <v-select v-model="editQueue.announce_holdtime" :items="permision" label="Announce"></v-select>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field v-model="editQueue.timeout" label="Wait TimeOut"
                      onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="editQueue.musiconhold" required
                      :rules="[(v) => !!v || 'music on hold is required']" label="Music On Hold"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="editQueue.strategy" :items="Strategy" label="Ring Strategy" required
                      :rules="[(v) => !!v || 'Ring Strategy is required']"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="editQueue.retry" :items="retry" label="Retry"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="editQueue.timeoutrestart" :items="permision" label="Timeout Restart"></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditQueue()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveEditQueue()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <!-- filter model -->
    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <!-- <v-select v-model="filter.payload.queue_name" :items="filtervalue" label="Queues" small></v-select> -->
            <v-autocomplete v-model="filter.payload.queue_name" :items="filtervalue" label="Queues"
              small></v-autocomplete>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="fetchFilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";

import { mapGetters } from "vuex";

export default {
  components: {
    loader,
  },

  data() {
    return {
      valid: true,
      tenantName: '',
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Queue Management",
        icon: "fa-user fa-2x",
        loading: false,
        isAddQueue: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditQueue: false,
        candelete: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "Queue Name",
          align: "left",
          class: "p0",
          sortable: false,
          value: "queue_name",
        },

        {
          text: "Ringing Strategy",
          align: "left",
          class: "p0",
          sortable: false,
          value: "ringing_strategy",
        },
        {
          text: "WaitTimeOut",
          align: "left",
          class: "p0",
          sortable: false,
          value: "timeout",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],

      QueueData: [],

      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      filter: {
        payload: {
          queue_name: "",
        },
      },
      isProcessing: false,
      editQueue: {
        name: "",
        announce_holdtime: "",
        timeout: "",
        retry: "",
        musiconhold: "",
        strategy: "",
        timeoutrestart: "",
        account_code: "100",
      },
      NewQueue: {
        name: "",
        timeout: "15",
        retry: "1",
        strategy: "Random",
        musiconhold: "Default",
        timeoutrestart: "yes",
        account_code: "100",
        announce_holdtime: "no",
      },
      queuedata: [],
      error_name: "",
      error_edit_name: "",
      permision: ["yes", "no"],
      Strategy: ["Random", "RingAll", "FewestCalls", "Leastrecent"],
      retry: ["0", "1", "2", "3", "4", "5"],
      TimeDuring: ["1", "2", "3"],
      queuedeletemsg: ""
    };
  },

  methods: {
    refreshThisPage: function () {
      var scope = this;

      scope.page.loading = true;
      scope.filter.payload.queue_name = "";
      scope.getDataFromApi();
    },
    reset() {
      this.filter.payload.queue_name = "";
      this.getDataFromApi();
      this.page.isFilterOpen = true;
    },
    filterClick: function () {
      this.page.isFilterOpen = true;
    },
    fetchFilter: function () {
      var scope = this;

      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }

      let query = {
        skip: skip,
        take: take,
      };
      let newVal = scope.filter.payload.queue_name;
      if (newVal === undefined) {
        newVal = "";
      }

      this.$store.commit("filter_Queue", { name: newVal });

      this.$store
        .dispatch("queueManagement", query)
        .then((response) => {
          this.pagecount = response.data.count;
          if (response.status === 204) {
            scope.QueueData = [];
            scope.tableLoading = false;
          } else {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data.data;

            scope.QueueData = datas;
          }
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
      scope.page.loading = false;
    },
    getDataFromApi() {
      var scope = this;

      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }

      let query = {
        skip: skip,
        take: take,
      };


      this.$store.commit("filter_Queue", { name: "" });
      this.$store
        .dispatch("queueManagement", query)
        .then((response) => {
          if (response.status === 200) {
            response.data.data.forEach((data) => {
              this.filtervalue.push(data.name);
            });
            this.pagecount = response.data.count;
          }
          if (response.status === 204) {
            scope.QueueData = [];
            scope.tableLoading = false;
          } else {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data.data;

            scope.QueueData = datas;
          }
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
      scope.page.loading = false;
    },
    AddNewQueue: function () {
      let scope = this
      this.page.isAddQueue = true;
      scope.filter.payload.queue_name = "";
      scope.NewQueue.timeout = "15"
      scope.NewQueue.retry = "1"
      scope.NewQueue.strategy = "Random"
      scope.NewQueue.musiconhold = "Default"
      scope.NewQueue.timeoutrestart = "yes"
      scope.NewQueue.announce_holdtime = "no"
    },
    closeEditQueue: function () {
      this.page.isEditQueue = false;
    },
    closeAddQueue: function () {
      this.page.isAddQueue = false;
      this.$refs.adduserform.reset();
    },
    saveEditQueue() {
      var scope = this;
      console.log(this.editQueue, " this.editQueue");
      this.$store
        .dispatch("edit", this.editQueue)
        .then(() => {
          scope.error_edit_name = "";
          this.$refs.edituserform.reset();
          scope.showSnackBar("Updated Successfully");
          scope.page.isEditQueue = false;
          // scope.filter.payload.queue_name=""
          this.getDataFromApi();
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
    },
    saveAddQueue() {
      var scope = this;
      const queueName = `${scope.tenantName}${scope.NewQueue.name}`;
      console.log("queue name is", queueName)
      scope.queuedata.push(Object.assign({}, scope.NewQueue));

      scope.totalCount = scope.queuedata.length;
      scope.$store.dispatch("NewQueue", { ...scope.NewQueue, name: queueName })
        .then(() => {
          scope.error_name = "";
          scope.filter.payload.queue_name = "";
          scope.$refs.adduserform.reset();
          scope.page.isAddQueue = false;
          scope.showSnackBar("Created Successfully");
          this.getDataFromApi();
        })
        .catch(function (error) {
          if (error.data.statusCode == 400) {
            scope.showSnackBar(error.data.message);
          }
          else {
            scope.showSnackBar("error" + error.data.statusText);
          }

        });
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(data) {
      let scope = this;
      let count = { campaigncount: 0, usercount: 0 }
      console.log(count.campaigncount, count.usercount, "count.campaigncount > 0 && count.usercount > 01")
      this.$store.dispatch("CheckDataCampaign", `queue=${data.name}`).then((res) => {
        if (res.status == 200) {
          count.campaigncount = res.data.totalCount
          this.$store.dispatch("getUserQueueTenant", data.name).then((res) => {
            if (res.status == 200) {
              scope.queuedeletemsg = "Deleting this Queue will affect associated campaigns and User. Proceed with deletion?"
              this.DeleteRowdata = true;
              this.delete_id = data.id;
              this.deleted_data = data
            } else if (res.status == 204) {
              scope.queuedeletemsg = "Deleting this Queue will affect associated campaigns.Proceed with deletion?"
              this.DeleteRowdata = true;
              this.delete_id = data.id;
              this.deleted_data = data
            }
          }).catch(function (error) {
            scope.showSnackBar("error" + error);
          });
        } else if (res.status == 204) {
          this.$store.dispatch("getUserQueueTenant", data.name).then((res) => {
            if (res.status == 200) {
              scope.queuedeletemsg = "This queue also affected User to Remove?"
              this.DeleteRowdata = true;
              this.delete_id = data.id;
              this.deleted_data = data
            } else if (res.status == 204) {
              scope.queuedeletemsg = "Are your Sure to delete this queue?"
              this.DeleteRowdata = true;
              this.delete_id = data.id;
              this.deleted_data = data
            }
          }).catch(function (error) {
            scope.showSnackBar("error" + error);
          });
        }
      }).catch(function (error) {
        scope.showSnackBar("error" + error);
      });

    },
    editItem(item) {
      let scope = this;

      scope.editQueue.id = item.id;
      scope.editQueue.name = item.name;
      scope.editQueue.announce_holdtime = item.announce_holdtime;
      scope.editQueue.timeout = item.timeout;
      scope.editQueue.retry = item.retry.toString();
      scope.editQueue.strategy = item.strategy;
      scope.editQueue.timeoutrestart = item.timeoutrestart;
      scope.editQueue.account_code = item.account_code;
      scope.editQueue.musiconhold = item.musiconhold;
      scope.page.isEditQueue = true;
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;

      let payload = { id: this.delete_id };
      this.$store.dispatch("Deleterow", payload).then((res) => {
        if (res.status == 200) {
          this.showSnackBar("Deleted Successfully");
          scope.isProcessing = false;
          let field = {
            "fields": [
              {
                "name": "ingroup",
                "value": this.deleted_data.name
              },
              {
                "name": "default_queue",
                "value": this.deleted_data.name
              }
            ]
          }
          this.$store.dispatch("CampaignRemove", field)


          this.getDataFromApi();
        } else {
          this.showSnackBar("Err: " + res);
          scope.isProcessing = false;
          this.getDataFromApi();
        }

      });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },

  mounted() {
    let scope = this;
    this.page.loading = false;
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:subdisposition:create"
      ) !== -1
    ) {
      this.page.addnew = [{ label: "Add Queue" }];
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:subdisposition:delete"
      ) !== -1
    ) {
      scope.page.candelete = true;
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:subdisposition:edit"
      ) !== -1
    ) {
      scope.page.canedit = true;
    }
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    const tenantId = localStorage.getItem('TenantCode');
    if (tenantId) {
      const parts = tenantId.split('_');
      if (parts.length > 1) {
        this.tenantName = `${parts[1]}_`;
      }
      console.log("tenantname is", this.tenantName)
    }
    this.getDataFromApi();
  },
  watch: {
    pagination: {
      // handler() {
      // this.getDataFromApi();
      // },
      deep: true,
    },
  },
};
</script>
<style>
.v-text-field.custom-prefix .v-input__prefix {
  font-size: 0.35rem;
}

.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}

.alert-danger {
  color: red;
}
</style>