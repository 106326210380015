<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs" :exportOptions="page.exportOptions" @openFilter="abdnFilter"
      @refreshPage="refreshabdnPage" :isMaterialIconType="true" @exportData="exportabandonedsummary"
      :style="styleObject"></section-definition>

    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="Abandoneddata" class="elevation-1" :pagination.sync="pagination"
        :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[5, 10, 25]">
        <template v-slot:items="props">
          <td class="checkEmpty ml-1" v-for="(property, index) in Object.keys(props.item)" :key="index">
            {{ props.item[property] }}
          </td>
        </template>
      </v-data-table>
    </v-container>

    <!-- filter model -->
    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div>
            <v-layout row wrap>
              <!-- From date -->
              <v-flex>
                <v-menu ref="frmdate" v-model="fromdate" :close-on-content-click="false" :nudge-right="40"
                  :return-value.sync="from_date" lazy transition="scale-transition" offset-y full-width
                  min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="from_date" label="From Date" prepend-icon="event" readonly v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker color="primary" v-model="from_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="fromdate = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.frmdate.save(from_date)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>

              <!-- To date -->
              <v-flex>
                <v-menu ref="todate" v-model="todate" :close-on-content-click="false" :nudge-right="40"
                  :return-value.sync="to_date" lazy transition="scale-transition" offset-y full-width min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="to_date" label="To Date" prepend-icon="event" readonly v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker color="primary" v-model="to_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="todate = false">Cancel</v-btn>
                    <v-btn flat color="primary" @click="$refs.todate.save(to_date)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="getDataFromApi('reset')">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="datefilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    loader,
  },
  mounted() {
    let scope = this;
    this.dateformate();
    this.getDataFromApi();

    this.page.loading = false;
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:abandonedsummary:export"
          ) !== -1
        ) {
          scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
        } else {
          scope.page.exportOptions = [];
        }
      }.bind(scope),
      1
    );
  },
  data() {
    return {
      fromdate: false,
      todate: false,
      Abandoneddata: [],
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      tableLoading: false,
      page: {
        title: "Abandoned Summary",
        icon: "call_missed ",
        loading: true,
        materialicon: true,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true, info: false },
      },
      dialog: false,
      pagination: {
        rowsPerPage: 10,
      },
      exportheader: [],
      headers: [],
      pagecount: 0,
      payload: {
        from_date: "",
        to_date: "",
      },
      total: "",
      call_date: "",
      report: "",
      exports: [],
      excel_data: [],
      txt_data: [],
      from_date: "",
      to_date: "",
      page_flag: false,
      fieldata: [],
    };
  },
  computed: {
    ...mapGetters(["GetAbandonedsummary"]),
  },
  watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.payload.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  methods: {
    closeModel: function () {
      this.dialog = false;
    },
    abdnFilter: function () {
      this.page.isFilterOpen = true;
    },
    refreshabdnPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.payload.from_date = "";
      scope.payload.to_date = "";
      scope.dateformate();
      scope.getDataFromApi();
    },
    exportabandonedsummary: function (option) {
      if (option == "CSV" || option == "Txt(Tab-Delimited)") {
        this.export(option);
      }
    },
    dateformate() {
      this.page.loading = false;
      this.to_date = new Date();
      this.to_date = moment(this.to_date).format("YYYY-MM-DD");
      var from_date1 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 4;
      from_date1.setTime(from_date1.getTime() - dateOffset);
      this.from_date = moment(from_date1).format("YYYY-MM-DD");
    },
    titledata(arr) {
      let temparr = []
      const toTitleCase = (str) =>
        str.replace(
          /(^\w|\s\w)(\S*)/g,
          (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
        );
      arr.forEach((elm) => {
        let sperate = elm.replaceAll("_", " ");
        temparr.push(toTitleCase(sperate))
      });
      this.fieldata = temparr
    },
    getDataFromApi(action) {
      var scope = this;
      if (action === 'reset') {
        this.refreshabdnPage()
      }else{
      scope.tableLoading = true;
      const toTitleCase = (str) =>
        str.replace(
          /(^\w|\s\w)(\S*)/g,
          (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
        );

      this.payload.from_date = this.from_date;
      this.payload.to_date = this.to_date;

      scope.$store
        .dispatch("FilterAbandonedsummary", this.payload)
        .then((response) => {
          scope.page.loading = false;
          scope.tableLoading = false;
          if (response.data.fielddata.length != this.headers.length) {
            response.data.fielddata.forEach((elm) => {
              let sperate = elm.replaceAll("_", " ");
              this.exportheader.push(sperate)
              this.headers.push({
                text: toTitleCase(sperate),
                align: "left",
                sortable: false,
                class: "p0",
                value: toTitleCase(sperate),
              });
            });
          }
          scope.titledata(response.data.fielddata)
          scope.Abandoneddata = response.data.data;
          if (response.status === 401) {
            scope.$store.dispatch("checkingtoken");
          }
        });}
    },
    datefilter() {
      var scope = this;
      // scope.dateformate();
      this.page.isFilterOpen = false;
      scope.tableLoading = true;
      this.payload.from_date = this.from_date;
      this.payload.to_date = this.to_date;

      scope.$store
        .dispatch("FilterAbandonedsummary", this.payload)
        .then((response) => {
          scope.page.loading = false;
          scope.tableLoading = false;
          scope.titledata(response.data.fielddata)
          scope.Abandoneddata = response.data.data;
          if (response.status === 401) {
            scope.$store.dispatch("checkingtoken");
          }
          scope.page.isFilterOpen = false
        });

    },
    export(option) {
      var scope = this;
      this.excel_data = [];
      this.txt_data = []
      let exportAction = 'GetAbandonedsummary';
      if (this.payload.from_date != "" || this.payload.to_date != "") {
        exportAction = 'FilterAbandonedsummary';
      }
      scope.$store.dispatch(exportAction, this.payload).then((response) => {
        if (response.data.data.length > 0) {
          this.exports = response.data.data
        } else {
          this.exports = []
          this.fieldata = this.exportheader
        }

        for (var i = 0; i < this.exports.length; i++) {
          this.excel_data.push(this.exports[i]);
          this.txt_data.push(this.exports[i]);
        }
        if (option == "CSV") {
          let csvContent = '';
          for (var k = 0; k < this.fieldata.length; k++) {
            if (k == this.fieldata.length - 1) {
              csvContent +=
                `${this.fieldata[k]}\r\n`
            } else {
              csvContent +=
                `${this.fieldata[k]},`
            }

          }
          this.excel_data.forEach(function (rows) {
            let data = Object.values(rows);
            let data1 = [];
            data.forEach((elm) => {
              if (typeof elm === "string") {
                data1.push(elm.replace(/'|"|#|\n/g, " "));
              } else {
                data1.push(elm);
              }
            });
            for (let i = 0; i < data1.length; i++) {
              if (
                data1[i] != null &&
                data1[i] != undefined &&
                data1[i] != "" && typeof data1[i] == "string"
              ) {
                if (data1[i].includes(",")) {
                  data1[i] = data1[i].replaceAll(",", ";");
                }
              }
            }
            let row = data1.join(",");
            csvContent += row + "\r\n";
          });
          var date = new Date();
          date = moment(date).format("YYYY-MM-DD hh-mm");
          const encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "GetAbandonedsummary" + date + ".csv");
          document.body.appendChild(link); // Required for FF
          link.click();
        }
        if (option == "Txt(Tab-Delimited)") {
          let txtContent = "";
          let txt_key = this.fieldata
          let txt_tab = txt_key.join("\t");
          txtContent += `${txt_tab} \r\n`;
          this.txt_data.forEach(function (rows) {
            let data = Object.values(rows);
            let row = data.join("\t");
            txtContent += row + "\r\n";
          });
          var date2 = new Date();
          date2 = moment(date2).format("YYYY-MM-DD hh-mm");
          var encodedUri1 =
            "data:text/plain;charset=utf-8,\uFEFF" +
            encodeURIComponent(txtContent);

          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute("download", "GetAbandonedsummary" + date2 + ".txt");
          document.body.appendChild(link1);
          link1.click();
        }
      })
    },
  },
};

</script>
<style>
.test {
  display: flex;
}

/* .move {
  
} */
.drop {
  font-weight: bold;
  font-size: 13px !important;
  padding-left: 10px !important;
}

.ml5 {
  margin-left: 20%;
}

.drop1 {
  font-size: 13px !important;
  padding-left: 10px !important;
}

.agent {
  font-weight: bold;
  font-size: 13px !important;
  padding-left: 10px !important;
}

.agent1 {
  font-size: 13px !important;
  padding-left: 10px !important;
}
</style>